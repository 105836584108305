import React, {useContext, useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import {
    CardContent, Card, Typography, CardMedia, Grid, CircularProgress, Stack
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import AuthContext from "../../api-authorization/AuthContext";
import {useForm} from "react-hook-form";
import {enqueueSnackbar} from "notistack";
import productService from "../../products/ProductService";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import {RESOURCE_TYPE} from "../../enums";
import NavigationContext from "../../layout/NavigationContext";
import Checkout from "./Checkout";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function AddProduct({handleNext,companyData,resourceType}) {
    const { histNavigate, back } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser,user} = useContext(AuthContext);
    const {  handleSubmit, control, formState, setValue } = useForm();
    const navigate = useNavigate();
    const [isBusy, setIsBusy] = React.useState(true);
    const [showCheckout, setShowCheckout] = React.useState(false);
    const [registeredOfficeAdded, setRegisteredOfficeAdded] = React.useState(null);
    const [postBoxAdded, setPostBoxAdded] = React.useState(null);

    const [tabValue, setTabValue] = React.useState(0);

    const [products, setProducts] = React.useState([]);
    const [cart, setCart] = useState([]);
    const [productTabHeaders, setProductTabHeaders] = useState([]);

    const handleChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };
    

    async function fetchProducts() {
        const result = await productService.listProducts(antiForgeryToken, resourceType, companyData.id);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setProducts(data.products);
                setProductTabHeaders(data.products.map(product => product.tab)
                    .filter((value, index, self) => self.indexOf(value) === index));
                setIsBusy(false);
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
    }

    useEffect( () => {
        fetchProducts();
    }, []);

    function addToCart(value, registeredOffice, postBox){
        if(registeredOffice == true){
            setRegisteredOfficeAdded(value);
        }
        if(postBox == true) {
            setPostBoxAdded(value)
        }
        setCart(cart => [...cart, value]);
    }

    function cartContains(value){
        return cart.includes(value);
    }

    function disableRegisteredOffice(filteredProduct) {
        let disableRegisteredOffice = false;
        if(filteredProduct.registeredOffice === true){
            if(registeredOfficeAdded != null && registeredOfficeAdded != filteredProduct.id){
                disableRegisteredOffice =  true;
            }
            else if((resourceType == RESOURCE_TYPE.COMPANY) && companyData.hasActiveRegisteredOfficeProduct) {
                disableRegisteredOffice = true;
            }
        }
        return disableRegisteredOffice;
    }

    function disablePostBox(filteredProduct) {
        let disablePostBox = false;
        if(filteredProduct.postBox === true){
            if(postBoxAdded != null && postBoxAdded != filteredProduct.id){
                disablePostBox =  true;
            }
            else if((resourceType == RESOURCE_TYPE.POST_BOX) && companyData.hasActivePostBoxProduct) {
                disablePostBox = true;
            }
        }
        return disablePostBox;
    }

    function removeFromCart(value, registeredOffice, postBox){
        if(registeredOffice){
            setRegisteredOfficeAdded(null);
        }
        if(postBox) {
            setPostBoxAdded(null);
        }
        setCart(cart.filter(item => item !== value));
    }

    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress/>
            </Box>
        );
    }
    else if(showCheckout){
        content = (
            <Box sx={{ width: "100%" }}>
                <Checkout antiForgeryToken={antiForgeryToken} setShowCheckout={setShowCheckout} resourceType={resourceType} resourceData={companyData} cart={cart} products={products} refreshUser={refreshUser} user={user} postPurchaseAction={handleNext}/>
            </Box>
        );
    }
    else {
        let tabsHeaderContent = productTabHeaders.map((tab,index) => (
            <Tab key={`${tab}_tab`} label={tab} id={`simple-tab-${index}`} aria-controls={`simple-tabpanel-${index}`}/>
        ));

        let tabsContent = productTabHeaders.map((tab,index) => (
            <TabPanel key={`${tab}_panel`} value={tabValue} index={index}>
                {
                    products.filter(product => product.tab === tab)
                        .map(filteredProduct => (
                                <Grid container spacing={2} key={`${filteredProduct.id}_grid`} sx={{mt: 4}}>
                                    <Grid item xs={16} sm={12} md={10} key={`${filteredProduct.id}_card`}>
                                        <Card sx={{ display: 'flex', backgroundColor: "#FBFBFB", boxShadow: 0, borderColor: "#EBEBEB", borderWidth: 1, borderStyle:"solid" }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 200, height:200 }}
                                                image={ filteredProduct.images.toString() }
                                                alt=""
                                            />
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width:"100%" }}>
                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                    <Typography component="div" variant="h5">
                                                        {filteredProduct.name}
                                                    </Typography>
                                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                                        {filteredProduct.description}
                                                    </Typography>
                                                    <Typography variant="productPriceDisplay" color="text.secondary" component="div" sx={{ textAlign: 'right' }}>
                                                        {filteredProduct.priceDisplay}
                                                    </Typography>
                                                    <Typography variant="productVatDisplay" color="text.secondary" component="div" sx={{ textAlign: 'right' }}>
                                                        + VAT {filteredProduct.interval ? `| PER ${filteredProduct.interval}` : ""}
                                                    </Typography>
                                                    {filteredProduct.freeTrialDays != null && filteredProduct.freeTrialDays > 0 && (
                                                        <Typography variant="productVatDisplay" color="text.secondary" component="div" sx={{ textAlign: 'right' }}>
                                                            {filteredProduct.freeTrialDays} days free trial
                                                        </Typography>
                                                    )}
                                                </CardContent>

                                            </Box>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={2} key={`${filteredProduct.id}_button`}
                                          container
                                          direction="row"
                                          justifyContent="center"
                                          alignItems="center"
                                    >
                                        <Button variant="contained" color="secondary" onClick={() =>
                                            cartContains(filteredProduct.id) ? removeFromCart(filteredProduct.id, filteredProduct.registeredOffice, filteredProduct.postBox) :addToCart(filteredProduct.id, filteredProduct.registeredOffice, filteredProduct.postBox)
                                        }
                                                disabled={disableRegisteredOffice(filteredProduct) || disablePostBox(filteredProduct)}
                                        >
                                            {cartContains(filteredProduct.id) ? "Remove" : "Add"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                        )
                }
            </TabPanel>
        ));

        let headerDetails = ``;
        if(resourceType == RESOURCE_TYPE.COMPANY){
            headerDetails = `company - ${companyData.name}`;
        }
        else if(resourceType == RESOURCE_TYPE.POST_BOX){
            headerDetails = `post box - ${companyData.postBoxName}`;
        }

        content = (
            <Box sx={{ width: "100%" }}>
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                    Add a product to {headerDetails}
                </Typography>
                <Box>
                    <Box>
                        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                            {tabsHeaderContent}
                        </Tabs>
                    </Box>
                    <Box  sx={{minHeight:400}}>
                        {tabsContent}
                    </Box>
                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary" type="submit" onClick={(e) => {
                            setTabValue(tabValue+1);
                        }}
                                disabled={tabValue >= productTabHeaders.length - 1}
                        >
                            Next
                        </Button>
                        <Button variant="contained" color="secondary" type="submit"
                                onClick={(e) => {
                                    setShowCheckout(true);
                                }}
                                disabled={cart.length==0}>
                            Checkout
                        </Button>
                    </Stack>
                </Box>
            </Box>
        );
    }

    return (
        content
    );

}