import * as React from 'react';
import {useForm, Controller, useWatch} from 'react-hook-form';
import {useContext, useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Checkbox, Grid, MenuItem, Stack} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import companyService from "../../companies/CompanyService";
import inputService from "../../util/InputService";
import ListItemText from "@mui/material/ListItemText";
import {COMPANY_MAIL_SETTINGS_INSTRUCTIONS_TYPE, RESOURCE_TYPE} from "../../enums";
import {mailSettingsMenuDefaults, mailSettingsMenuOptions} from "../../companies/MailSettingsMenuOptions";
import Divider from "@mui/material/Divider";
import AuthContext from "../../api-authorization/AuthContext";
import NavigationContext from "../../layout/NavigationContext";
import TransitionAlert from "../../layout/TransitionAlert";

const daysOfTheWeek = [
    {value: "1", name: "Monday", monthlyName: "First Monday"},
    {value: "2", name: "Tuesday", monthlyName: "First Tuesday"},
    {value: "4", name: "Wednesday", monthlyName: "First Wednesday"},
    {value: "8", name: "Thursday", monthlyName: "First Thursday"},
    {value: "16", name: "Friday", monthlyName: "First Friday"},
    {value: "32", name: "Saturday", monthlyName: "First Saturday"},
    {value: "64", name: "Sunday", monthlyName: "First Sunday"}
];

export default function UpdateMailSettings({handleNext,companyData,resourceType}) {
    const { freshHistNavigate } = useContext(NavigationContext);
    const navigate = useNavigate();
    const { antiForgeryToken, refreshUser,user} = useContext(AuthContext);
    const { handleSubmit, control, formState, setValue, getValues } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isBusy, setBusy] = useState(true);
    const [companyMailSettings, setCompanyMailSettings] = useState();
    const [selectedMailTypeCategory, setSelectedMailTypeCategory] = useState(1);
    const [selectedMailForwardingDays, setSelectedMailForwardingDays] =
        React.useState({
            letterHandlingInstructions: [],
            specialHandlingInstructions: [],
            parcelHandlingInstructions: [],
            printedHandlingInstructions: []
        });

    const [selectedMailForwardingFrequency, setSelectedMailForwardingFrequency] =
        React.useState({
            letterHandlingInstructions: 0,
            specialHandlingInstructions: 0,
            parcelHandlingInstructions: 0,
            printedHandlingInstructions: 0
        });

    const [selectedMailHandlingType, setSelectedMailHandlingType] =
        React.useState({
            letterHandlingInstructions: 0,
            specialHandlingInstructions: 0,
            parcelHandlingInstructions: 0,
            printedHandlingInstructions: 0
        });

    const instructions = [
        { typeValue: COMPANY_MAIL_SETTINGS_INSTRUCTIONS_TYPE.LETTER, type: "letterHandlingInstructions" },
        { typeValue: COMPANY_MAIL_SETTINGS_INSTRUCTIONS_TYPE.SPECIAL, type: "specialHandlingInstructions" },
        { typeValue: COMPANY_MAIL_SETTINGS_INSTRUCTIONS_TYPE.PARCEL, type: "parcelHandlingInstructions" },
        { typeValue: COMPANY_MAIL_SETTINGS_INSTRUCTIONS_TYPE.PRINTED, type: "printedHandlingInstructions" },
    ];
    
    const mailForwarding = useWatch({
        control,
        name: 'mailForwarding',
        defaultValue: companyMailSettings?.mailForwarding
    });
    

    const handleFrequencyChange = (instructionType) => (event) => {
        setSelectedMailForwardingDays(prevState => ({
            ...prevState,
            [instructionType]: []
        }));
        setValue(`${instructionType}.mailForwardingFrequency`, event.target.value); // set Frequency to new value 
        setSelectedMailForwardingFrequency(prevState => ({
            ...prevState,
            [instructionType]: event.target.value
        }));
    }
    
    const handleMailHandlingTypeChange = (instructionType) => (event) => {
        setValue(`${instructionType}.mailHandlingType`, event.target.value); // set Handling Type to new value
        setSelectedMailHandlingType(prevState => ({
            ...prevState,
            [instructionType]: event.target.value
        }));
        setValue(`${instructionType}.mailForwardingInstructions`, mailSettingsMenuDefaults.find(item => item.mailHandlingType == event.target.value).mailForwardingInstructionDefaultValue);
        setValue(`${instructionType}.mailHoldType`, mailSettingsMenuDefaults.find(item => item.mailHandlingType == event.target.value).mailHoldTypeDefaultValue);
        setValue(`${instructionType}.mailForwardingFrequency`, mailSettingsMenuDefaults.find(item => item.mailHandlingType == event.target.value).mailForwardingFrequencyDefaultValue);
        
        setSelectedMailForwardingDays(prevState => ({
            ...prevState,
            [instructionType]: []
        }));

        setSelectedMailForwardingFrequency(prevState => ({
            ...prevState,
            [instructionType]: mailSettingsMenuDefaults.find(item => item.mailHandlingType == event.target.value).mailForwardingFrequencyDefaultValue
        }));
        
        setValue(`${instructionType}.mailClass`, mailSettingsMenuDefaults.find(item => item.mailHandlingType == event.target.value).mailClassDefaultValue);
    }
    

    useEffect( () => {
        async function fetchData() {
            const result = await companyService.getCompanyMailSettings(antiForgeryToken, companyData.id);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setCompanyMailSettings(data);
                    setValue('mailForwarding', data.mailForwarding);
                    setSelectedMailForwardingDays({
                        letterHandlingInstructions: data.letterHandlingInstructions.mailForwardingDays.map(String),
                        specialHandlingInstructions: data.specialHandlingInstructions.mailForwardingDays.map(String),
                        parcelHandlingInstructions: data.parcelHandlingInstructions.mailForwardingDays.map(String),
                        printedHandlingInstructions: data.printedHandlingInstructions.mailForwardingDays.map(String)
                    });
                    setSelectedMailForwardingFrequency({
                        letterHandlingInstructions: data.letterHandlingInstructions.mailForwardingFrequency,
                        specialHandlingInstructions: data.specialHandlingInstructions.mailForwardingFrequency,
                        parcelHandlingInstructions: data.parcelHandlingInstructions.mailForwardingFrequency,
                        printedHandlingInstructions: data.printedHandlingInstructions.mailForwardingFrequency
                    });
                    
                    setSelectedMailHandlingType({
                        letterHandlingInstructions: data.letterHandlingInstructions.mailHandlingType,
                        specialHandlingInstructions: data.specialHandlingInstructions.mailHandlingType,
                        parcelHandlingInstructions: data.parcelHandlingInstructions.mailHandlingType,
                        printedHandlingInstructions: data.printedHandlingInstructions.mailHandlingType
                    });
                    
                    setBusy(false);
                } else {
                    enqueueSnackbar(data.description, {variant:'error'});
                }
            }
        }
        fetchData();
    }, [companyData.id]);


    const handleFormSubmit  = async (formData) => {
        setDisableSubmit(true);
        formData.companyId = companyData.id;
        formData.mailForwardingAddress.id = companyMailSettings.mailForwardingAddress.id;
        if(formData.mailNotificationsPerson === 0){
            formData.mailNotificationsPerson = null;
        }
        formData = inputService.trimFields(formData);
        formData.letterHandlingInstructions.mailForwardingDays = selectedMailForwardingDays['letterHandlingInstructions'];
        formData.specialHandlingInstructions.mailForwardingDays = selectedMailForwardingDays['specialHandlingInstructions'];
        formData.parcelHandlingInstructions.mailForwardingDays = selectedMailForwardingDays['parcelHandlingInstructions'];
        formData.printedHandlingInstructions.mailForwardingDays = selectedMailForwardingDays['printedHandlingInstructions'];

        const result = await companyService.updateMailSettings(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully updated mail settings", {variant:'success'});
                freshHistNavigate("/", "Home");
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setDisableSubmit(false);
    };

    const handleMailForwardingDaysChange = (instructionType) => (event) => {
        const {
            target: { value },
        } = event;

        setSelectedMailForwardingDays(prevState => ({
            ...prevState,
            [instructionType]: typeof value === 'string' ? value.split(',') : value
        }));
    };

    let headerDetails = ``;
    if(resourceType == RESOURCE_TYPE.COMPANY){
        headerDetails = `Confirm the Mail Forwarding for - ${companyData.name}`;
    }
    else if(resourceType == RESOURCE_TYPE.POST_BOX){
        headerDetails = `Confirm the Mail Forwarding for - ${companyData.postBoxName}`;
    }
    

    let content;
    if (isBusy) {
        content = <p>Loading...</p>;
    } else {
        content = (
            <Box sx={{ maxWidth: 1200 }}>
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                    {headerDetails}
                </Typography>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8} md={6} >
                            <Controller
                                name="mailForwarding"
                                control={control}
                                defaultValue={companyMailSettings.mailForwarding}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        select
                                        label="Mail Forwarding"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.mailForwarding}
                                        helperText={formState.errors.mailForwarding ? formState.errors.mailForwarding.message : ''}
                                    >
                                        <MenuItem key="0" value={false}>No</MenuItem>
                                        <MenuItem key="1" value={true}>Yes</MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} >
                            <Controller
                                name="lastUpdatedByEmail"
                                control={control}
                                defaultValue={companyMailSettings.lastUpdatedByEmail}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Last Updated By"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="mailScanning"
                                control={control}
                                defaultValue={companyMailSettings.mailScanning}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        select
                                        label="Mail Scanning"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.mailScanning}
                                        helperText={formState.errors.mailScanning ? formState.errors.mailScanning.message : ''}
                                    >
                                        <MenuItem key="0" value={false}>No</MenuItem>
                                        <MenuItem key="1" value={true}>Yes</MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="mailNotificationsPersonEmail"
                                control={control}
                                defaultValue={companyMailSettings.mailNotificationsPersonEmail}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Mail Notifications"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="mailNotes"
                                control={control}
                                defaultValue={companyMailSettings.mailNotes}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        multiline
                                        rows={5}
                                        label="Notes"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.mailNotes}
                                        helperText={formState.errors.mailNotes ? formState.errors.mailNotes.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="legacyNotes"
                                control={control}
                                defaultValue={companyMailSettings.legacyNotes}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        multiline
                                        rows={5}
                                        label="Legacy Notes"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.legacyNotes}
                                        helperText={formState.errors.legacyNotes ? formState.errors.legacyNotes.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="h6" noWrap component="div" align={"left"} sx={{ mt: 2, mb:2 }}>
                        Handling Instructions
                    </Typography>
                    <Typography noWrap component="div" align={"left"} sx={{ mt: 2, mb:2 }}>
                        These settings are unique for each mail item type. Please select the mail item type above to see the individual settings.
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                            <TextField
                                id="mailItemType"
                                select
                                label="Mail Item Type"
                                value={selectedMailTypeCategory}
                                onChange={(e) => {
                                    setSelectedMailTypeCategory(e.target.value);
                                }}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                            >
                                <MenuItem key={1} value={1}>Letter</MenuItem>
                                <MenuItem key={2} value={2}>Special</MenuItem>
                                <MenuItem key={3} value={3}>Parcel</MenuItem>
                                <MenuItem key={4} value={4}>Printed</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                    <Divider sx={{mt: 2.5, mb: 2.5, borderBottomWidth: 1, borderColor:"black"}}/>
                    <Grid container spacing={2}>
                        {instructions.map((instruction, index) => (
                            <>
                            <Grid item xs={12} sm={8} md={6} hidden={selectedMailTypeCategory !== instruction.typeValue}>
                                <Controller
                                    name={`${instruction.type}.mailHoldType`}
                                    control={control}
                                    defaultValue={companyMailSettings[instruction.type].mailHoldType}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            label="Mail Hold Type"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            error={!!formState.errors.mailHold}
                                            helperText={formState.errors.mailHold ? formState.errors.mailHold.message : ''}
                                            disabled={mailSettingsMenuOptions.find(item => item.type === instruction.typeValue).mailHoldDisabled[selectedMailHandlingType[instruction.type]]}
                                        >
                                            {mailSettingsMenuOptions.find(item => item.type === instruction.typeValue).mailHoldMenuItems[selectedMailHandlingType[instruction.type]]}
                                        </TextField>
                                    )}
                                />
                                <Controller
                                    name={`${instruction.type}.additionalInstructions`}
                                    control={control}
                                    defaultValue={companyMailSettings[instruction.type].additionalInstructions}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            multiline
                                            rows={5}
                                            label="Additional Instructions"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            error={!!formState.errors.additionalInstructions}
                                            helperText={formState.errors.additionalInstructions ? formState.errors.additionalInstructions.message : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} hidden={selectedMailTypeCategory !== instruction.typeValue}>
                                <Controller
                                    name={`${instruction.type}.mailHandlingType`}
                                    control={control}
                                    defaultValue={companyMailSettings[instruction.type].mailHandlingType}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            label="Mail Handling Type"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            onChange={handleMailHandlingTypeChange(instruction.type)}
                                            disabled={!mailForwarding || mailSettingsMenuOptions.find(item => item.type === instruction.typeValue).mailHandlingDisabled}
                                        >
                                            {mailSettingsMenuOptions.find(item => item.type === instruction.typeValue).mailHandlingMenuItems}
                                        </TextField>
                                    )}
                                />
                                <Controller
                                    name={`${instruction.type}.mailForwardingInstructions`}
                                    control={control}
                                    defaultValue={companyMailSettings[instruction.type].mailForwardingInstructions}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            label="Mail Forwarding Type"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            disabled={!mailForwarding || mailSettingsMenuOptions.find(item => item.type === instruction.typeValue).mailForwardingInstructionsDisabled[selectedMailHandlingType[instruction.type]]}
                                        >
                                            {mailSettingsMenuOptions.find(item => item.type === instruction.typeValue).mailForwardingInstructionsMenuItems[selectedMailHandlingType[instruction.type]]}
                                        </TextField>
                                    )}
                                />
                                <Controller
                                    name={`${instruction.type}.mailClass`}
                                    control={control}
                                    defaultValue={companyMailSettings[instruction.type].mailClass}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            label="Mail Class Type"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            disabled={!mailForwarding || mailSettingsMenuOptions.find(item => item.type === instruction.typeValue).mailClassDisabled[selectedMailHandlingType[instruction.type]]}
                                            error={!!formState.errors.mailClass}
                                            helperText={formState.errors.mailCollection ? formState.errors.mailclass.message : ''}
                                        >
                                            {mailSettingsMenuOptions.find(item => item.type === instruction.typeValue).mailClassMenuItems[selectedMailHandlingType[instruction.type]]}
                                        </TextField>
                                    )}
                                />
                                <Controller
                                    name={`${instruction.type}.mailForwardingFrequency`}
                                    control={control}
                                    defaultValue={companyMailSettings[instruction.type].mailForwardingFrequency}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            label="Forwarding Freq."
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            onChange={handleFrequencyChange(instruction.type)}
                                            disabled={!mailForwarding || mailSettingsMenuOptions.find(item => item.type === instruction.typeValue).mailForwardingFrequencyDisabled[selectedMailHandlingType[instruction.type]]}
                                        >
                                            {mailSettingsMenuOptions.find(item => item.type === instruction.typeValue).mailForwardingFrequencyMenuItems[selectedMailHandlingType[instruction.type]]}
                                        </TextField>
                                    )}
                                />
                                {selectedMailForwardingFrequency[instruction.type] === 3 ? (
                                    <TextField
                                        select
                                        value={selectedMailForwardingDays[instruction.type]}
                                        onChange={handleMailForwardingDaysChange(instruction.type)}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        disabled={(selectedMailForwardingFrequency[instruction.type] === 0 || selectedMailForwardingFrequency[instruction.type] === 1) || !mailForwarding
                                                || mailSettingsMenuOptions.find(item => item.type === instruction.typeValue).mailForwardingFrequencyDisabled[selectedMailHandlingType[instruction.type]]}
                                        SelectProps={{
                                            renderValue: (selected) => (
                                                selected
                                                    .sort((a, b) => a - b)  // make sure the days are sorted by their value
                                                    .map(value =>
                                                        daysOfTheWeek.find(day => day.value === value.toString()).monthlyName
                                                    )
                                                    .join(', ')
                                            )
                                        }}
                                        label="Mail Forwarding Day"
                                    >
                                        {daysOfTheWeek.map((day) => (
                                            <MenuItem key={day.value} value={day.value}>
                                                <ListItemText primary={day.monthlyName} />
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                ) : selectedMailForwardingFrequency[instruction.type] === 4 ? (
                                    <TextField
                                        select
                                        value={selectedMailForwardingDays[instruction.type]}
                                        onChange={handleMailForwardingDaysChange(instruction.type)}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        disabled={(selectedMailForwardingFrequency[instruction.type] === 0 || selectedMailForwardingFrequency[instruction.type] === 1) || !mailForwarding
                                                    || mailSettingsMenuOptions.find(item => item.type === instruction.typeValue).mailForwardingFrequencyDisabled[selectedMailHandlingType[instruction.type]]}
                                        label="Mail Forwarding Day"
                                    >
                                        {Array.from({length: 31}, (_, i) => i + 1).map((day) => (
                                            <MenuItem key={day} value={`${day}`}>
                                                <ListItemText primary={day} />
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                ) : (
                                    <TextField
                                        select
                                        value={selectedMailForwardingDays[instruction.type]}
                                        onChange={handleMailForwardingDaysChange(instruction.type)}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        disabled={(selectedMailForwardingFrequency[instruction.type] === 0 || selectedMailForwardingFrequency[instruction.type] === 1) || !mailForwarding
                                                    || mailSettingsMenuOptions.find(item => item.type === instruction.typeValue).mailForwardingFrequencyDisabled[selectedMailHandlingType[instruction.type]]}
                                        SelectProps={{
                                            multiple: true,
                                            renderValue: (selected) => (
                                                selected
                                                    .sort((a, b) => a - b)  // make sure the days are sorted by their value
                                                    .map(value =>
                                                        daysOfTheWeek.find(day => day.value === value.toString()).name
                                                    )
                                                    .join(', ')
                                            )
                                        }}
                                        label="Mail Forwarding Day"
                                    >
                                        {daysOfTheWeek.map((day) => (
                                            <MenuItem key={day.value} value={day.value}>
                                                <Checkbox checked={selectedMailForwardingDays[instruction.type].indexOf(day.value) > -1} />
                                                <ListItemText primary={day.name} />
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            </Grid>
                            </>
                        ))}
                    </Grid>

                    <Typography variant="h6" noWrap component="div" align={"left"} sx={{ mt: 2, mb:2 }}>
                        Forwarding address details
                    </Typography>
                    <TransitionAlert message={"NOTE:   To update the forwarding address, please go to the Primary Contact for this company"}/>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="mailForwardingAddress.addressLine1"
                                control={control}
                                defaultValue={companyMailSettings.mailForwardingAddress.addressLine1}
                                disabled={true}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Address Line 1"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="mailForwardingAddress.addressLine2"
                                control={control}
                                defaultValue={companyMailSettings.mailForwardingAddress.addressLine2}
                                disabled={true}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Address Line 2"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="mailForwardingAddress.locality"
                                control={control}
                                defaultValue={companyMailSettings.mailForwardingAddress.locality}
                                disabled={true}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Town / City"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="mailForwardingAddress.country"
                                control={control}
                                defaultValue={companyMailSettings.mailForwardingAddress.country}
                                disabled={true}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Country"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="mailForwardingAddress.postalCode"
                                control={control}
                                defaultValue={companyMailSettings.mailForwardingAddress.postalCode}
                                disabled={true}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Post Code"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                            Save & Exit
                        </Button>
                    </Stack>
                </form>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}