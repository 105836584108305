import React, {useContext} from 'react'
import {Pagination, Stack} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AuthContext from "../api-authorization/AuthContext";
import mailService from "./MailService";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro} from "@mui/x-data-grid-pro";
import Button from "@mui/material/Button";
import {generateMailLabelXml} from "../labels/GenerateMailLabelXml";
import labelService from "../labels/LabelService";

export default function ListMailCheckouts() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser} = useContext(AuthContext);
    const [isLoading, setIsLoading] = React.useState(true);
    const [mailCheckouts, setMailCheckouts] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    const navigate = useNavigate();


    const fetchData = async () => {
        setPaginationModel((prevModel) => ({...prevModel, page: 0}));
        const result = await mailService.listMailCheckouts(antiForgeryToken);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            setMailCheckouts(data.mailCheckoutItems);
            setRowCountState(data.mailCheckoutItems.length);
            setIsLoading(false);
        } else {
            enqueueSnackbar(data.description, {variant:'error'});
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };

    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleString('en-GB');
    };

    const openCoverSheet = async (mailCheckoutId) => {
        const result = await mailService.getCheckoutCoverSheet(antiForgeryToken, mailCheckoutId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            var newWindow = window.open("", "_blank");
            newWindow.document.write(data);
        } else {
            enqueueSnackbar(data.description, {variant:'error'});
        }
    };

    const printLabels = async (mailCheckoutId) => {
        const result = await labelService.getCheckoutLabels(antiForgeryToken, mailCheckoutId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            let printers = window.dymo.label.framework.getPrinters();
            let connectedPrinter = printers.find(printer => printer.isConnected === true);
            if(!connectedPrinter){
                enqueueSnackbar("An error occured: No printers found", {variant:'error'});
            }
            else {
                Object.keys(data.labels).forEach(key => {
                    const xml = generateMailLabelXml(data.labels[key]);
                    let label = window.dymo.label.framework.openLabelXml(xml);
                    label.print(connectedPrinter.name);
                });
            }

        } else {
            enqueueSnackbar(data.description, {variant:'error'});
        }
    };

    return (
        <Box sx={{height: 900}}>
            <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}}>
                Mail Check-out History
            </Typography>
            <br />
            <DataGridPro
                rows={mailCheckouts.slice(
                    paginationModel.page * paginationModel.pageSize,
                    paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                )}
                columns={[
                    { field: 'dateProcessed', headerName: 'Check-out Date', flex: 1, valueFormatter: dateFormatter},
                    { field: 'checkedOutBy', headerName: 'Checked-out By', flex: 1 },
                    { field: 'description', headerName: 'Description', flex: 2 },
                    { field: 'printLabels', headerName: '', flex: 1,
                        renderCell: (params) => (
                            <Button variant="contained" color="secondary" onClick={() => printLabels(params.row.id)}>
                                Print Labels
                            </Button>
                        )
                    },
                    { field: 'coverSheet', headerName: '', flex: 1,
                        renderCell: (params) => (
                            <Button variant="contained" color="secondary" onClick={() => openCoverSheet(params.row.id)}>
                                Cover Sheet
                            </Button>
                        )
                    }
                ]}
                rowCount={paginationModel.pageSize}
                pageSize={paginationModel.pageSize}
                hideFooter={true}
                page={paginationModel.page}
                onPageChange={handlePageChange}
                loading={isLoading}
                autoHeight
                sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                        WebkitTextStroke: '0.75px'
                    },
                }}
            />
            <Box display="flex" justifyContent="flex-end">
                <Pagination
                    count={Math.ceil(rowCountState / paginationModel.pageSize)}
                    page={paginationModel.page + 1}
                    onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                />
            </Box>
            <Stack direction="row" spacing={2} marginTop={2}>
                <Button variant="contained" color="secondary" onClick={(e) => {
                    back();
                }}>
                    Back
                </Button>
            </Stack>
        </Box>
    );
}
