import React, {useContext, useEffect, useState} from 'react'
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {CircularProgress, InputAdornment, MenuItem, Stack} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AuthContext from "../api-authorization/AuthContext";
import mailService from "./MailService";
import clientService from "../clients/ClientService";
import Button from "@mui/material/Button";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {generateMailLabelXml} from "../labels/GenerateMailLabelXml";
import labelService from "../labels/LabelService";

export default function CheckoutMail() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser} = useContext(AuthContext);
    const [clientData, setClientData] = useState();
    const [filterDescription, setFilterDescription] = useState('');
    const [isClientDataLoading, setIsClientDataLoading] = useState(true);
    const [isCheckingOut, setIsCheckingOut] = useState(false);

    const [predefinedReport, setPredefinedReport] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [clientId, setClientId] = useState('');
    const [batchNames, setBatchNames] = useState([]);
    const [selectedBatchName, setselectedBatchName] = useState('');
    
    const [isLoading, setIsLoading] = React.useState(true);
    const [mail, setMail] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
    });
    const navigate = useNavigate();
    
    useEffect(() => {
        async function fetchClientData() {
            const result = await clientService.listClients(antiForgeryToken, "");
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            } else {
                let data = await result.json();
                if (result.ok) {
                    setClientData(data.clients);
                } else {
                    enqueueSnackbar(data.description, {variant:'error'});
                }
            }
            setIsClientDataLoading(false);
        }
        fetchClientData();
    }, [])

    const fetchData = async (initialPageLoad = true) => {
        setPaginationModel((prevModel) => ({...prevModel, page: 0}));
        setIsLoading(true);
        let searchRequest = {
            searchTerm: searchTerm == '' ? null : searchTerm,
            clientId: clientId == '' ? null : clientId,
            scanBatchTitle: selectedBatchName == '' ? null : selectedBatchName,
            predefinedReport: predefinedReport
        };
        const result = await mailService.listCheckoutMail(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            setFilterDescription(data.filterDescription);
            setMail(data.mail);
            setRowCountState(data.mail.length);
            if(initialPageLoad){
                setBatchNames([
                    ...new Set(
                        data.mail
                            .map(item => item.scanBatchTitle)
                            .filter(batchName => batchName)
                    )
                ]);   
            }
            setIsLoading(false);
        } else {
            enqueueSnackbar(data.description, {variant:'error'});
            setIsLoading(false);
        }
    };

    const openCoverSheet = async (mailCheckoutId) => {
        const result = await mailService.getCheckoutCoverSheet(antiForgeryToken, mailCheckoutId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            var newWindow = window.open("", "_blank");
            newWindow.document.write(data);
        } else {
            enqueueSnackbar(data.description, {variant:'error'});
        }
    };

    const printLabels = async (mailCheckoutId) => {
        const result = await labelService.getCheckoutLabels(antiForgeryToken, mailCheckoutId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            let printers = window.dymo.label.framework.getPrinters();
            let connectedPrinter = printers.find(printer => printer.isConnected === true);
            if(!connectedPrinter){
                enqueueSnackbar("An error occured: No printers found", {variant:'error'});
                Object.keys(data.labels).forEach(key => {
                    const xml = generateMailLabelXml(data.labels[key]);
                    console.log(xml);
                });
            }
            else {
                Object.keys(data.labels).forEach(key => {
                    const xml = generateMailLabelXml(data.labels[key]);
                    let label = window.dymo.label.framework.openLabelXml(xml);
                    label.print(connectedPrinter.name);
                });
            }

        } else {
            enqueueSnackbar(data.description, {variant:'error'});
        }
    };
    
    const resetSearchFilters = async () => {
        await setSearchTerm('');
        await setClientId('');
        await setselectedBatchName('');
        await setPredefinedReport(null);
    }

    const checkoutMail = async () => {
        setIsCheckingOut(true);
        let formData = {
            mailIds: selectedRows,
            description: filterDescription
        };
        const result = await mailService.checkoutMail(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            enqueueSnackbar("Successfully checked out mail items", {variant:'success'});
            printLabels(data);
            openCoverSheet(data);
            fetchData(false);
        } else {
            enqueueSnackbar(data.description, {variant:'error'});
        }
        setIsCheckingOut(false);
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };

    const handleSearch = (event) => {
        event.preventDefault();
        fetchData(false);
    };
    
    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleString('en-GB');
    };
    let content;
    if (isClientDataLoading || isCheckingOut) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <Box sx={{height: 900}}>
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}}>
                    Check-out Mail
                </Typography>
                <form onSubmit={handleSearch}>
                    <TextField
                        id="search-bar"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <IconButton type="submit">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        label="Company search"
                        variant="outlined"
                        size="small"
                        style={{ width: '100%' }}
                    />
                    <Stack direction="row" spacing={2} marginTop={2} sx={{width: "80%"}}>
                        <TextField
                            select
                            label="Pre-defined Report"
                            value={predefinedReport}
                            onChange={(e) => {
                                setPredefinedReport(e.target.value);
                                if(e.target.value == null) {
                                    setPredefinedReport(null);
                                }
                            }}
                            variant="outlined"
                            margin="normal"
                            size="small"
                            fullWidth
                        >
                            <MenuItem key="0" value={null}>&nbsp;</MenuItem>
                            <MenuItem key="2" value={2}>Parcels Checklist</MenuItem>
                            <MenuItem key="3" value={3}>Today's Mail Forwarding</MenuItem>
                        </TextField>
                        <TextField
                            id="clientId"
                            select
                            label="Client"
                            value={clientId}
                            onChange={(e) => {
                                setClientId(e.target.value);
                            }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            size="small"
                        >
                            <MenuItem key="0" value={''}>&nbsp;</MenuItem>
                            {clientData.map((client) => (
                                <MenuItem key={client.id} value={client.id}>
                                    {client.companyName}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            id="selectedBatchName"
                            select
                            label="Batch"
                            value={selectedBatchName}
                            onChange={(e) => {
                                setselectedBatchName(e.target.value);
                            }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            size="small"
                        >
                            <MenuItem key="0" value={''}>&nbsp;</MenuItem>
                            {batchNames.map((batchName, index) => (
                                <MenuItem key={index} value={batchName}>
                                    {batchName}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button style={{height:"80%", width: "20%"}}  variant="contained" color="secondary" type="submit">
                            Search
                        </Button>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            resetSearchFilters();
                        }}>
                            Reset
                        </Button>
                    </Stack>

                </form>
                <br />
                <DataGridPro
                    rows={mail}
                    columns={[
                        { field: 'receivedDate', headerName: 'Received Date', flex: 1, valueFormatter: dateFormatter},
                        { field: 'companyName', headerName: 'Company Name', flex: 1 },
                        { field: 'personName', headerName: 'Person Name', flex: 1 },
                        { field: 'mailType', headerName: 'Mail Type', flex: 1 },
                        { field: 'clientName', headerName: 'Client', flex: 1 },
                        { field: 'scanBatchTitle', headerName: 'Batch', flex: 1 },
                        { field: 'estimatedForwardingDate', headerName: 'Forwarding Date', flex: 1 }
                    ]}
                    pageSize={mail.length}
                    hideFooter={true}
                    page={paginationModel.page}
                    onPageChange={handlePageChange}
                    loading={isLoading}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange = {(newSelection) => {
                        setSelectedRows(newSelection);
                    }}
                    selectionModel={selectedRows}
                    autoHeight
                    sx={{
                        '.MuiDataGrid-columnHeaderTitle': {
                            WebkitTextStroke: '0.75px'
                        },
                    }}
                />
                <Stack direction="row" spacing={2} marginTop={2}>
                    <Button variant="contained" color="secondary" onClick={(e) => {checkoutMail()}} disabled={selectedRows.length <= 0}>
                        Check-out
                    </Button>
                    <Button variant="contained" color="secondary" onClick={(e) => {
                        e.preventDefault();
                        histNavigate(`/Mail/ListMailCheckouts`,'Mail Checkouts');
                    }}>
                        Check-out History
                    </Button>
                </Stack>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}
