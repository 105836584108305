import React, {useContext, useState} from 'react'
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {InputAdornment, Pagination, Stack} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {enqueueSnackbar} from "notistack";
import {COMPANY_TABS, COMPANY_TYPE} from "../enums";
import companyService from "../companies/CompanyService";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro} from "@mui/x-data-grid-pro";

export default function ListAccountNonUkCompanies({accountId, antiForgeryToken, refreshUser}) {
    const { histNavigate, back } = useContext(NavigationContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [postBoxes, setPostBoxes] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    const navigate = useNavigate();

    const fetchData = async () => {
        setPaginationModel((prevModel) => ({...prevModel, page: 0}));
        let searchRequest = {
            appScope: 3,
            searchTerm: searchTerm,
            accountId: accountId
        };
        const result = await companyService.listNonUkCompanies(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            setPostBoxes(data.nonUkCompanies);
            setRowCountState(data.nonUkCompanies.length);
            setIsLoading(false);
        } else {
            enqueueSnackbar(data.description, {variant:'error'});
        }
    };


    React.useEffect(() => {
        fetchData();
    }, []);
    

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };

    const handleSearch = (event) => {
        event.preventDefault();
        fetchData();
    };

    const handleRowClick = (params) => {
        histNavigate(`/Company/Update/${params.id}/${COMPANY_TABS.DETAILS}`, 'Update Company');
    };

    const dateFormatter = (params) => {
        if(params.value === null || params.value === undefined) return "None";
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleDateString('en-GB');
    };

    return (
        <Box style={{ height: 400 }}>
            <form onSubmit={handleSearch}>
                <TextField
                    id="search-bar"
                    className="text"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    label="Search Non-UK Companies"
                    variant="outlined"
                    size="small"
                    style={{ width: '100%' }}
                />
            </form>
            <br />
            <DataGridPro
                rows={postBoxes.slice(
                    paginationModel.page * paginationModel.pageSize,
                    paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                )}
                columns={[
                    { field: 'name', headerName: 'Name', flex: 1 },
                    { field: 'tradingNames', headerName: 'Trading Names', flex: 1 },
                    { field: 'thirdPartyId', headerName: 'Third Party Id', flex: 1 },
                ]}
                rowCount={paginationModel.pageSize}
                pageSize={paginationModel.pageSize}
                hideFooter={true}
                page={paginationModel.page}
                onPageChange={handlePageChange}
                loading={isLoading}
                onRowClick={handleRowClick}
                getRowClassName={() => 'rowCursorPointer'}
                autoHeight
                sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                        WebkitTextStroke: '0.75px'
                    },
                }}
            />
            <Box display="flex" justifyContent="flex-end">
                <Pagination
                    count={Math.ceil(rowCountState / paginationModel.pageSize)}
                    page={paginationModel.page + 1}
                    onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                />
            </Box>
            <Stack direction="row" spacing={2} marginTop={2}>

                <Button variant="contained" color="secondary"   onClick={(e) => {
                    e.preventDefault();
                    histNavigate(`/Company/Add/${accountId}/${COMPANY_TYPE.NON_UK_COMPANY}`,'Add Company');
                }}>
                    Add Non-UK company
                </Button>
            </Stack>
        </Box>
    );
}
