import * as React from 'react';
import { useForm } from 'react-hook-form';
import {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {Box, CircularProgress, Stack} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import CreateUserForm from "../users/CreateUserForm";
import inputService from "../util/InputService";
import AuthContext from "../api-authorization/AuthContext";
import userService from "../users/UserService";
import {ROLES, USER_ROLES} from "../enums";
import NavigationContext from "../layout/NavigationContext";

export default function CreateTenantUser() {
    const { histNavigate,back } = useContext(NavigationContext);
    const {  handleSubmit, control, formState, watch } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const navigate = useNavigate();
    const { user, antiForgeryToken, refreshUser} = useContext(AuthContext);

    useEffect(() => {
        if (user.loggedIn == false || user.role !== ROLES.TENANT_ADMIN) {
            refreshUser();
            navigate('/login');
        }
    }, [user, navigate]);
    
    const handleFormSubmit  = async (formData) => {
        setBusy(true);
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        formData.clientId = user.clientId;
        const result = await userService.createUser(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                if(data.error){
                    enqueueSnackbar(data.error.description, {variant:'error'});
                }
                else{
                    enqueueSnackbar("Successfully created tenant user", {variant:'success'});
                }
                histNavigate(`/Tenant/UpdateTenantUser/${data.id}`,'Update User');
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setDisableSubmit(false);
        setBusy(false);
    };


    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <Box sx={{ maxWidth: 1200 }}>
                <Typography variant="h5" noWrap align={"left"} sx={{mb: 2}}>
                    User - Add Admin User
                </Typography>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <CreateUserForm formState={formState} control={control} watch={watch} adminRole={USER_ROLES.TENANT_ADMIN} standardRole={USER_ROLES.TENANT_STANDARD} hasActiveAdminUsers={true}/>
                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            back();
                        }}>
                            Back
                        </Button>
                        <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                            Submit
                        </Button>
                    </Stack>
                </form>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}