import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useEffect, useState} from "react";
import {enqueueSnackbar} from "notistack";
import {CircularProgress, MenuItem, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import {Controller, useForm} from "react-hook-form";
import {RESOURCE_TYPE} from "../../enums";
import accountService from "../../accounts/AccountService";
import AuthContext from "../../api-authorization/AuthContext";
import inputService from "../../util/InputService";
import TextField from "@mui/material/TextField";
import {useTheme} from "@mui/material/styles";
import billingService from "../../billing/BillingService";
import {Elements} from "@stripe/react-stripe-js";
import SetupForm from "../../billing/SetupForm";
import {loadStripe} from "@stripe/stripe-js";
import NavigationContext from "../../layout/NavigationContext";
import TransitionAlert from "../../layout/TransitionAlert";
import Divider from "@mui/material/Divider";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function AddAccountBillingParent({setShowCheckout,accountId: propAccountId,refreshPaymentMethods}) {
    const { accountId: paramAccountId } = useParams();
    const accountId = propAccountId || paramAccountId;
    const { histNavigate,back } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser} = useContext(AuthContext);
    
    const theme = useTheme();
    const {  handleSubmit, control, formState } = useForm();
    const [tabValue, setTabValue] = React.useState(0);
    const navigate = useNavigate();
    
    
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [paymentFormLoading, setPaymentFormLoading] = useState(true);
    const [billingDetailsLoading, setBillingDetailsLoading] = useState(true);
    const [billingDetails, setBillingDetails] = useState();
    
    const [clientSecret, setClientSecret] = useState("");
    const [stripePublicKey, setStripePublicKey] = useState("");
    
    
    
    const handleChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    useEffect( () => {
        async function fetchBillingDetails() {
            const result = await accountService.getAccount(antiForgeryToken, accountId);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setBillingDetails(data);
                    setBillingDetailsLoading(false);
                } else {
                    enqueueSnackbar(data.description, {variant:'error'});
                }
            }
        }
        fetchBillingDetails();
    }, [accountId]);

    const handleAccountDetailsSubmit  = async (formData) => {
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        formData.accountId = accountId;
        const result = await accountService.createOrUpdateStripeAccount(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                await getSetUpIntentSecret();
                setTabValue(1);
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setDisableSubmit(false);
    };

    async function getSetUpIntentSecret() {
        const result = await billingService.getSetUpIntentSecret(antiForgeryToken, RESOURCE_TYPE.ACCOUNT, accountId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setClientSecret(data.clientSecretId);
                setStripePublicKey(data.stripePublicKey);
                setPaymentFormLoading(false);
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
    }



    let cardContent;
    if (paymentFormLoading) {
        cardContent = <p>Loading...</p>;
    } else {
        const options = {
            // passing the client secret obtained in step 3
            clientSecret: clientSecret,
            // Fully customizable with appearance API.
            appearance: {/*...*/},
        };
        const stripePromise = loadStripe(stripePublicKey);
        cardContent = (
            <Elements stripe={stripePromise} options={options}>
                <SetupForm resourceType={RESOURCE_TYPE.ACCOUNT} resourceId={accountId} refreshPaymentMethods={refreshPaymentMethods}/>
            </Elements>
        );
    }

    
    let content;
    if (billingDetailsLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        let label= (
            <>
                <Typography>NOTE:    Use this form to setup the payment method for this account. To update this information go to your Account profile.</Typography>
            </>
        );
        content = (
            <Box sx={{ width: '100%' }}>
                <Typography variant="basketSubHeader" sx={{mt: 2, mb: 2}} noWrap component="div" align={"left"}>
                    {tabValue === 0 ? "Invoice Details" : "Add Payment Method"}
                </Typography>
                <Divider sx={{borderColor: "#b8c4c4", borderWidth:"1px"}} />

                <TabPanel value={tabValue} index={0}>
                    <TransitionAlert message={`NOTE:   The following information will appear on all future invoices for ${billingDetails.email}.`}/>
                    <Box sx={{ maxWidth: 600 }}>
                       
                        <form onSubmit={handleSubmit(handleAccountDetailsSubmit)}>
                            <Controller
                                name="title" 
                                control={control}
                                defaultValue={billingDetails.title}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        select
                                        label="Title"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        disabled={true}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    >
                                        <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                                        <MenuItem key="1" value={1}>Mr</MenuItem>
                                        <MenuItem key="2" value={2}>Mrs</MenuItem>
                                        <MenuItem key="3" value={3}>Ms</MenuItem>
                                        <MenuItem key="4" value={4}>Miss</MenuItem>
                                        <MenuItem key="5" value={5}>Dr</MenuItem>
                                    </TextField>
                                )}
                            />
                            <Controller
                                name="firstName"
                                control={control}
                                defaultValue={billingDetails.firstName}
                                rules={{ required: 'First Name is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="First Name *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        disabled={true}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.firstName}
                                        helperText={formState.errors.firstName ? formState.errors.firstName.message : ''}
                                    />
                                )}
                            />
                            <Controller
                                name="lastName"
                                control={control}
                                defaultValue={billingDetails.lastName}
                                rules={{ required: 'Last Name is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Last Name *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        disabled={true}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.lastName}
                                        helperText={formState.errors.lastName ? formState.errors.lastName.message : ''}
                                    />
                                )}
                            />
                            <Controller
                                name="phoneNumber"
                                control={control}
                                defaultValue={billingDetails.phoneNumber}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Phone"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        disabled={true}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={billingDetails.email}
                                rules={{ required: 'Email is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Email *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        disabled={true}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.email}
                                        helperText={formState.errors.email ? formState.errors.email.message : ''}
                                    />
                                )}
                            />
                            <Typography variant="h6" noWrap component="div" align={"left"} sx={{ mt: 2 }}>
                                Invoice Address details
                            </Typography>
                            <Typography variant="h8" noWrap component="div" align={"left"} sx={{ mt: 2 }}>
                                This address will appear on the company invoice.
                            </Typography>
                            <Controller
                                name="address.addressLine1"
                                control={control}
                                rules={{ required: 'Address Line 1 is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Address Line 1 *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.address?.addressLine1}
                                        helperText={formState.errors.address?.addressLine1 ? formState.errors.address.addressLine1.message : ''}
                                    />
                                )}
                            />
                            <Controller
                                name="address.addressLine2"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Address Line 2"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                            <Controller
                                name="address.locality"
                                control={control}
                                rules={{ required: 'Town / City is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Town / City *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.address?.locality}
                                        helperText={formState.errors.address?.locality ? formState.errors.address.locality.message : ''}
                                    />
                                )}
                            />
                            <Controller
                                name="address.country"
                                control={control}
                                rules={{ required: 'Country is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Country *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.address?.country}
                                        helperText={formState.errors.address?.country ? formState.errors.address.country.message : ''}
                                    />
                                )}
                            />
                            <Controller
                                name="address.postalCode"
                                control={control}
                                rules={{ required: 'Post Code is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Post Code *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.address?.postalCode}
                                        helperText={formState.errors.address?.postalCode ? formState.errors.address.postalCode.message : ''}
                                    />
                                )}
                            />
                            <Stack direction="row" spacing={2} marginTop={2}>
                                <Button variant="contained" color="secondary" onClick={(e) => {
                                    setShowCheckout(false);
                                }}
                                >
                                    Back
                                </Button>
                                <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                                    Next
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                        {cardContent}
                </TabPanel>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}