import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {MenuItem} from "@mui/material";
import {COMPANY_TYPE} from "../../enums";
import * as React from "react";
import {useContext} from "react";
import NavigationContext from "../../layout/NavigationContext";

export default function AddCompanySelect({companyTypeToAdd, setCompanyTypeToAdd,accountData}) {
    const { histNavigate } = useContext(NavigationContext);
    let content=(
        <>
            <Typography variant="h5" noWrap align={"left"} sx={{mb: 4}}>
                What would you like to add?
            </Typography>
            <Typography variant="h6" noWrap align={"left"} sx={{mb: 4}}>
                Please select something to add to the account
            </Typography>
            <TextField
                id="companyTypeToAddInput"
                className="text"
                value={companyTypeToAdd}
                onChange={(e) => {
                    setCompanyTypeToAdd(e.target.value);
                }}
                select
                variant="outlined"
                size="small"
                style={{ width: '50%' }}
            >
                <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                <MenuItem key={COMPANY_TYPE.COMPANY.toString()} value={COMPANY_TYPE.COMPANY}>UK Incorporated Company</MenuItem>
                <MenuItem key={COMPANY_TYPE.NON_UK_COMPANY.toString()} value={COMPANY_TYPE.NON_UK_COMPANY}>Non-UK Company</MenuItem>
                <MenuItem key={COMPANY_TYPE.POST_BOX.toString()} value={COMPANY_TYPE.POST_BOX}>BM Post Box</MenuItem>
            </TextField>
        </>
    );
    
    return content;
}