import React, {useContext, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
    CircularProgress,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Grid,
    MenuItem,
    Stack
} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import accountService from "./AccountService";
import inputService from "../util/InputService";
import {useTheme} from "@mui/material/styles";
import {ROLES} from "../enums";
import NavigationContext from "../layout/NavigationContext";
import {FormGroup} from "reactstrap";

export default function UpdateAccount({user, accountData, getAccountData, antiForgeryToken, refreshUser}) {
    const { back, freshHistNavigate } = useContext(NavigationContext);
    const theme = useTheme();
    const { handleSubmit, control, formState } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const [removeDialogOpen, setRemoveDialogOpen] = React.useState(false);
    const navigate = useNavigate();
    
    let readonly = true;
    if(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD || user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD || user.role === ROLES.ACCOUNT_ADMIN){
        readonly = false;
    }

    const handleRemoveDialogOpen = () => {
        setRemoveDialogOpen(true);
    };

    const handleRemoveDialogClose = () => {
        setRemoveDialogOpen(false);
    };
    
    
    const handleFormSubmit  = async (formData) => {
        setBusy(true);
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        formData.id = accountData.id;
        const result = await accountService.updateAccount(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                getAccountData();
                enqueueSnackbar("Successfully updated account", {variant:'success'});
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setDisableSubmit(false);
        setBusy(false);
    };

    const removeAccount  = async () => {
        setBusy(true);
        const result = await accountService.removeAccount(antiForgeryToken, accountData.id);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully removed account", {variant:'success'});
                freshHistNavigate("/Account/ListTenantAccounts",'Account Search');
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setRemoveDialogOpen(false);
        setBusy(false);
    };


    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
        <Box sx={{ maxWidth: 1200 }}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <Grid container spacing={2}>
                    { (user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD) &&
                        <>
                            <Grid item xs={12} sm={8} md={6} >
                                <Controller
                                    name="accountName"
                                    control={control}
                                    defaultValue={accountData.accountName}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Account Name"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: { ...theme.inputLabelProps },
                                            }}
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            disabled={readonly}
                                            error={!!formState.errors.accountName}
                                            helperText={formState.errors.accountName ? formState.errors.accountName.message : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8} md={6}>
                                <Controller
                                    name="clientName"
                                    control={control}
                                    defaultValue={accountData.clientName}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Client Name"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: { ...theme.inputLabelProps },
                                            }}
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            disabled={true}
                                        />
                                    )}
                                />
                            </Grid>
                        </>
                    }
                    <Grid item xs={12} sm={8} md={6} >
                        <Controller
                            name="title"
                            control={control}
                            defaultValue={accountData.title}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    select
                                    label="Title"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    disabled={readonly}
                                >
                                    <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                                    <MenuItem key="1" value={1}>Mr</MenuItem>
                                    <MenuItem key="2" value={2}>Mrs</MenuItem>
                                    <MenuItem key="3" value={3}>Ms</MenuItem>
                                    <MenuItem key="4" value={4}>Miss</MenuItem>
                                    <MenuItem key="5" value={5}>Dr</MenuItem>
                                </TextField>
                            )}
                        />
                    </Grid>

                    {
                        user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD
                            ?
                            (
                                <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                    <Controller
                                        name="status"
                                        control={control}
                                        defaultValue={accountData.status}
                                        rules={{ required: 'Status is required' }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                label="Status"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    style: { ...theme.inputLabelProps },
                                                }}
                                                margin="normal"
                                                fullWidth
                                                size="small"
                                                disabled={readonly}
                                                error={!!formState.errors.status}
                                                helperText={formState.errors.status ? formState.errors.status.message : ''}
                                            >
                                                <MenuItem key="1" value={1}>Enabled</MenuItem>
                                                <MenuItem key="2" value={2}>Disabled</MenuItem>
                                            </TextField>
                                        )}
                                    />
                                </Grid>
                            )
                            : user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD ?(
                                    <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                        <Controller
                                            name="status"
                                            control={control}
                                            defaultValue={accountData.status}
                                            rules={{ required: 'Status is required' }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Status"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        style: { ...theme.inputLabelProps },
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    size="small"
                                                    disabled={readonly}
                                                    error={!!formState.errors.status}
                                                    helperText={formState.errors.status ? formState.errors.status.message : ''}
                                                >
                                                    <MenuItem key="1" value={1}>Enabled</MenuItem>
                                                    <MenuItem key="2" value={2}>Disabled</MenuItem>
                                                </TextField>
                                            )}
                                        />
                                    </Grid>
                            )
                            : (
                                <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                </Grid>
                            )
                    }
                    
                    <Grid item xs={12} sm={8} md={6}>
                        <Controller
                            name="firstName"
                            control={control}
                            defaultValue={accountData.firstName}
                            rules={{ required: 'First Name is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="First Name *"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    disabled={readonly}
                                    error={!!formState.errors.firstName}
                                    helperText={formState.errors.firstName ? formState.errors.firstName.message : ''}
                                />
                            )}
                        />
                    </Grid>
                    {
                        user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD
                            ?
                            (
                                <Grid item xs={12} sm={8} md={6}>
                                    <Controller
                                        name="affiliate"
                                        control={control}
                                        defaultValue={accountData.affiliate}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                label="Is Affiliate"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    style: { ...theme.inputLabelProps },
                                                }}
                                                margin="normal"
                                                fullWidth
                                                size="small"
                                                disabled={readonly || (user.role !== ROLES.TENANT_ADMIN && user.role !== ROLES.TENANT_STANDARD)}
                                                error={!!formState.errors.affiliate}
                                                helperText={formState.errors.affiliate ? formState.errors.affiliate.message : ''}
                                            >
                                                <MenuItem key="0" value={false}>No</MenuItem>
                                                <MenuItem key="1" value={true}>Yes</MenuItem>
                                            </TextField>
                                        )}
                                    />
                                </Grid>
                            )
                            : (
                                <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                </Grid>
                            )
                    }

                    <Grid item xs={12} sm={8} md={6}>
                        <Controller
                            name="lastName"
                            control={control}
                            defaultValue={accountData.lastName}
                            rules={{ required: 'Last Name is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Last Name *"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    disabled={readonly}
                                    error={!!formState.errors.lastName}
                                    helperText={formState.errors.lastName ? formState.errors.lastName.message : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <Controller
                            name="phoneNumber"
                            control={control}
                            defaultValue={accountData.phoneNumber}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Phone"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    disabled={readonly}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <Controller
                            name="mobileNumber"
                            control={control}
                            defaultValue={accountData.mobileNumber}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Mobile"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    disabled={readonly}
                                    error={!!formState.errors.mobileNumber}
                                    helperText={formState.errors.mobileNumber ? formState.errors.mobileNumber.message : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue={accountData.email}
                            rules={{
                                required: 'Email is required',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Invalid email address',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Email *"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    disabled={readonly}
                                    error={!!formState.errors.email}
                                    helperText={formState.errors.email ? formState.errors.email.message : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                    </Grid>

                </Grid>

                <Stack direction="row" spacing={2} marginTop={2}>
                    <Button variant="contained" color="secondary" onClick={(e) => {
                        back();
                    }}>
                        Back
                    </Button>
                    {readonly === false && (
                        <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                            Update
                        </Button>
                    )}
                    {(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD) && (
                        <Button variant="contained" color="secondary" onClick={handleRemoveDialogOpen}>
                            Remove Account
                        </Button>
                    )}
                </Stack>
            </form>
            <Dialog
                open={removeDialogOpen}
                onClose={handleRemoveDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Warning"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you wish to remove {accountData.email}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>No</Button>
                    <Button onClick={removeAccount} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}