import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {useContext, useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Box, CircularProgress, Grid, MenuItem, Stack} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {ROLES} from "../../enums";
import accountService from "../../accounts/AccountService";
import AuthContext from "../../api-authorization/AuthContext";
import clientService from "../../clients/ClientService";
import {useTheme} from "@mui/material/styles";
import inputService from "../../util/InputService";
import NavigationContext from "../../layout/NavigationContext";

export default function CreateAccount({handleNext,getAccountData}) {
    const { histNavigate,back } = useContext(NavigationContext);
    const theme = useTheme();
    const { antiForgeryToken, user, refreshUser} = useContext(AuthContext);
    
    const {  handleSubmit, control, formState, watch } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [clientData, setClientData] = useState();
    const [isBusy, setBusy] = useState(true);
    const navigate = useNavigate();

    const watchEmail = watch("email");

    useEffect(() => {
        if (user.loggedIn == false || (user.role !== ROLES.TENANT_ADMIN && user.role !== ROLES.TENANT_STANDARD && user.role !== ROLES.CLIENT_ADMIN && user.role !== ROLES.CLIENT_STANDARD)) {
            refreshUser();
            navigate('/login');
        }
    }, [user, navigate]);


    useEffect(() => {
        async function fetchClientData() {
            const result = await clientService.listClients(antiForgeryToken, "");
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            } else {
                let data = await result.json();
                if (result.ok) {
                    setClientData(data.clients);

                } else {
                    enqueueSnackbar(data.description, {variant: 'error'});
                }
            }
            setBusy(false);
        }
        if(user.role ===  ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD){
            fetchClientData();   
        }
        else{
            setBusy(false);
        }
    }, [])
    
    const handleFormSubmit  = async (formData) => {
        setBusy(true);
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        const result = await accountService.createAccount(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                if(data.error){
                    enqueueSnackbar(data.error.description, {variant: 'error'});
                }
                else{
                    enqueueSnackbar("Successfully created account", {variant: 'success'});   
                    getAccountData(data.id);
                    handleNext();
                }
            } else {
                enqueueSnackbar(data.description, {variant: 'error'});
            }
        }
        setDisableSubmit(false);
        setBusy(false);
    };

    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <Box sx={{ maxWidth: "100%" }}>
                <Typography variant="h5" noWrap align={"left"} sx={{mb: 4}}>
                    Register a new customer account
                </Typography>
                
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="title"
                                control={control}
                                defaultValue={0}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        select
                                        label="Title"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    >
                                        <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                                        <MenuItem key="1" value={1}>Mr</MenuItem>
                                        <MenuItem key="2" value={2}>Mrs</MenuItem>
                                        <MenuItem key="3" value={3}>Ms</MenuItem>
                                        <MenuItem key="4" value={4}>Miss</MenuItem>
                                        <MenuItem key="5" value={5}>Dr</MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                            {
                                user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD
                                    ?
                                    (
                                        <Grid item xs={12} sm={8} md={6}>
                                            <Controller
                                                name="clientId"
                                                control={control}
                                                defaultValue=""
                                                rules={{required: 'Client is required'}}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        select
                                                        label="Client *"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            style: { ...theme.inputLabelProps },
                                                        }}
                                                        margin="normal"
                                                        fullWidth
                                                        size="small"
                                                        error={!!formState.errors.clientId}
                                                        helperText={formState.errors.clientId ? formState.errors.clientId.message : ''}
                                                    >
                                                        {clientData.map((client) => (
                                                            <MenuItem key={client.id} value={client.id}>
                                                                {client.companyName}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                )}
                                            />
                                        </Grid>
                                    )
                                    : (
                                        <>
                                            <Grid item xs={12} sm={8} md={6} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={6} sx={{display: {xs: 'none', sm: 'none', md: 'none'}}}>
                                                <Controller
                                                    name="clientId"
                                                    control={control}
                                                    defaultValue={user.clientId}
                                                    rules={{required: 'Client is required'}}
                                                    render={({field}) => (
                                                        <TextField
                                                            {...field}
                                                            label="Client *"
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                style: { ...theme.inputLabelProps },
                                                            }}
                                                            margin="normal"
                                                            fullWidth
                                                            size="small"
                                                            disabled={true}
                                                            error={!!formState.errors.clientId}
                                                            helperText={formState.errors.clientId ? formState.errors.clientId.message : ''}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </>
                                    )
                            }
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="firstName"
                                control={control}
                                defaultValue=""
                                rules={{required: 'First Name is required'}}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="First Name *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.firstName}
                                        helperText={formState.errors.firstName ? formState.errors.firstName.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="lastName"
                                control={control}
                                defaultValue=""
                                rules={{required: 'Last Name is required'}}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="Last Name *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.lastName}
                                        helperText={formState.errors.lastName ? formState.errors.lastName.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="phoneNumber"
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="Phone"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="mobileNumber"
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="Mobile"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.mobileNumber}
                                        helperText={formState.errors.mobileNumber ? formState.errors.mobileNumber.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email address',
                                    },
                                }}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="Email *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.email}
                                        helperText={formState.errors.email ? formState.errors.email.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="repeatEmail"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Repeat Email is required',
                                    validate: {
                                        emailEqual: value => (value === watchEmail) || "Email's are not identical",
                                    },
                                }}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="Repeat Email *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.repeatEmail}
                                        helperText={formState.errors.repeatEmail ? formState.errors.repeatEmail.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                        </Grid>
                    </Grid>

                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                            Next
                        </Button>
                    </Stack>
                </form>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}