import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useState} from "react";
import {enqueueSnackbar} from "notistack";
import {Controller, useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {Container, Grid, InputAdornment} from "@mui/material";
import Button from "@mui/material/Button";
import {useNavigate, useParams} from "react-router-dom";
import inputService from "../util/InputService";
import userService from "./UserService";
import AuthContext from "../api-authorization/AuthContext";
import CssBaseline from "@mui/material/CssBaseline";
import {useTheme} from "@mui/material/styles";
import {ArrowRight, Done, Visibility, VisibilityOff} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

export default function ResetPassword() {
    const theme = useTheme();
    const { userId } = useParams();
    const {token} = useParams();
    const {  handleSubmit, control, formState, watch } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [failedToSetPassword, setFailedToSetPassword] = useState(false);
    
    const navigate = useNavigate();
    const { antiForgeryToken} = useContext(AuthContext);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [passwordHasMinLength, setPasswordHasMinLength] = useState(false);
    const [passwordHasCharCombination, setPasswordHasCharCombination] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const handleFormSubmit  = async (formData) => {
        setDisableSubmit(true);
        formData.userId = userId;
        formData.token = token;
        formData = inputService.trimFields(formData);
        formData.password = password;
        const result = await userService.resetPassword(antiForgeryToken, formData);

        let data = await result.json();
        if (result.ok) {
            navigate('/login');
            enqueueSnackbar("Successfully reset password", {variant:'success'});
        } else {
            setFailedToSetPassword(true);
            enqueueSnackbar(data.description, {variant:'error'});
        }

        setDisableSubmit(false);
    };

    const handleChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        validatePassword(newPassword);
    };

    const validatePassword = (password) => {
        const hasMinLength = password.length >= 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        setPasswordHasMinLength(hasMinLength);
        setPasswordHasCharCombination(hasUpperCase && hasLowerCase && hasNumber && hasSymbol);
    }

    return (
        <>
            <CssBaseline />
            <Box
                sx={{
                    backgroundImage: `url(${theme.loginBackgroundImage})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Container component="main" maxWidth="sm" sx={{backgroundColor:"white",border:2,borderColor:'grey.200',pt:6,pb:10, width:"600px"}}>
                    <Box
                        component="form" noValidate onSubmit={handleSubmit(handleFormSubmit)}
                        sx={{
                            marginTop: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            pl:5,
                            pr:5
                        }}
                    >
                        <img src={theme.loginLogo} alt="logo" style={{height: "80px"}}/>

                        {failedToSetPassword ?
                            <Typography variant="h6" sx={{mt:4}}>
                                We failed to validate the password reset token. Please <a href={"/Users/RequestPasswordReset"}>try again</a>
                            </Typography>
                            :
                            <>
                                <Typography variant="h5" sx={{mt:4, mb:3}}>
                                    Create a new password
                                </Typography>
                                <Typography variant="h6" sx={{textAlign:"center"}}>
                                    For your password to be up to the latest standards,
                                    please consider the following when creating your password:
                                </Typography>
                                <Grid container alignItems="center" sx={{ flexWrap: 'nowrap',mt:4 }}>
                                    <Grid item>
                                        {passwordHasMinLength === true && (
                                            <Done sx={{ fontSize: "22px", color:'green',mt: 'auto', mb: 'auto',mr:1 }} />
                                        )}
                                        {passwordHasMinLength === false && (
                                            <ArrowRight sx={{ fontSize: "22px", mt: 'auto', mb: 'auto',mr:1 }} />
                                        )}
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Typography sx={{ textAlign: "left", fontSize: "15px", overflowWrap: 'anywhere' }}>
                                            The password should consist of a minimum of 8 characters
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                                    <Grid item>
                                        {passwordHasCharCombination === true && (
                                            <Done sx={{ fontSize: "22px", color:'green',mt: 'auto', mb: 'auto',mr:1 }} />
                                        )}
                                        {passwordHasCharCombination === false && (
                                            <ArrowRight sx={{ fontSize: "22px", mt: 'auto', mb: 'auto',mr:1 }} />
                                        )}
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Typography sx={{ textAlign: "left", mt: 1, fontSize: "15px", overflowWrap: 'anywhere' }}>
                                            A combination of uppercase letters, lowercase letters, numbers, and symbols
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Box sx={{width:"80%",mt:4}}>
                                    <Controller
                                        name="password"
                                        control={control}
                                        defaultValue=""
                                        sx={{mt:4}}
                                        render={({ field }) => (
                                            <TextField
                                                label="Password"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                size="small"
                                                type={showPassword ? "text" : "password"}
                                                error={!!formState.errors.password}
                                                helperText={formState.errors.password ? formState.errors.password.message : ''}
                                                onChange={handleChange}
                                                InputProps={{ // <-- This is where the toggle button is added.
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        )}
                                    />
                                </Box>

                                <br />
                                <Button sx={{width:"40%"}} variant="contained" color="secondary" type="submit" disabled={disableSubmit || !passwordHasMinLength || !passwordHasCharCombination}>
                                    Reset Password
                                </Button>
                            </>
                        }

                    </Box>
                </Container>
            </Box>
        </>
    );
}