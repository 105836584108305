import React, {useContext, useState} from "react";
import AuthContext from "../../../api-authorization/AuthContext";
import companiesHouseService from "../../../companiesHouse/CompaniesHouseService";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import TextField from "@mui/material/TextField";
import {CircularProgress, InputAdornment, Pagination, Stack} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import {DataGridPro} from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import NavigationContext from "../../../layout/NavigationContext";

export default function AddUKCompanySearch({handleExitDialogOpen,getCompanyFromCH,accountData}) {
    const { histNavigate } = useContext(NavigationContext);
    const { antiForgeryToken, user, refreshUser } = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [hasSearched, setHasSearched] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [companies, setCompanies] = React.useState([]);
    const [selectedCompanyNumber,setSelectedCompanyNumber] = React.useState(null);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 5,
    });
    const navigate = useNavigate();

    const fetchData = async () => {
        setIsLoading(true);
        let searchRequest = {
            searchTerm: searchTerm
        };
        const result = await companiesHouseService.findCompanies(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setCompanies(data.companies);
                setRowCountState(data.companies.length);
                setHasSearched(true);
                setIsLoading(false);
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
    };

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };

    const handleSearch = (event) => {
        event.preventDefault();
        fetchData();
    };

    const handleRowClick = (params) => {
        setSelectedCompanyNumber(params.row.number);
    };

    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleDateString('en-GB');
    };

    let searchForm = (
        <form onSubmit={handleSearch}>
            <TextField
                id="search-bar"
                className="text"
                value={searchTerm}
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="end">
                            <IconButton type="submit">
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                label="Search"
                variant="outlined"
                size="small"
                sx={{width: '50%', mt:2}}
            />
        </form>
    );

    let content;
    if (!hasSearched) {
        content = (
            <Stack direction="row" spacing={2} marginTop={10}>
                <Button variant="contained" color="secondary" type="button" onClick={(e) => {
                    handleExitDialogOpen();
                }}>
                    Exit
                </Button>
                <Button variant="contained" color="secondary" type="button" onClick={(e) => {
                    handleSearch(e);
                }}>
                    Search
                </Button>
            </Stack>
        );
    }
    else if (isLoading || companies == null) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress/>
            </Box>
        );
    }
        else if(companies.length === 0) {
            content = (
                <>
                    <Typography variant="h6" noWrap align={"left"} sx={{mt: 4}}>
                        No companies found, please try again.
                    </Typography>
                    <Stack direction="row" spacing={2} marginTop={10}>
                        <Button variant="contained" color="secondary" type="button" onClick={(e) => {
                            handleExitDialogOpen();
                        }}>
                            Exit
                        </Button>
                        <Button variant="contained" color="secondary" type="button" onClick={(e) => {
                            handleSearch(e);
                        }}>
                            Search
                        </Button>
                    </Stack>
                </>
            );
        }
    else {
        content = (
            <>
                <DataGridPro
                    rows={companies.slice(
                        paginationModel.page * paginationModel.pageSize,
                        paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                    )}
                    columns={[
                        { field: 'number', headerName: 'Company Number', flex: 1 },
                        { field: 'name', headerName: 'Company Name', flex: 2 },
                        { field: 'status', headerName: 'Status', flex: 1 },
                        { field: 'incorporationDate', headerName: 'Incorporation Date', flex: 1, valueFormatter: dateFormatter }
                    ]}
                    rowCount={paginationModel.pageSize}
                    pageSize={paginationModel.pageSize}
                    hideFooter={true}
                    page={paginationModel.page}
                    onPageChange={handlePageChange}
                    loading={isLoading}
                    onRowClick={handleRowClick}
                    getRowClassName={() => 'rowCursorPointer'}
                    sx={{
                        '.MuiDataGrid-columnHeaderTitle': {
                            WebkitTextStroke: '0.75px'
                        },
                    }}
                />
                <Box display="flex" justifyContent="flex-end">
                    <Pagination
                        count={Math.ceil(rowCountState / paginationModel.pageSize)}
                        page={paginationModel.page + 1}
                        onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                    />
                </Box>
                <Stack direction="row" spacing={2} marginTop={10}>
                    <Button variant="contained" color="secondary" type="button" onClick={(e) => {
                        handleExitDialogOpen();
                    }}>
                        Exit
                    </Button>
                    <Button variant="contained" color="secondary" type="button" disabled={selectedCompanyNumber === null} onClick={(e) => {
                        getCompanyFromCH(selectedCompanyNumber);
                    }}>
                        Next
                    </Button>
                </Stack>
            </>
        );
    }
    return (
        <Box>
            <Typography variant="h6" noWrap align={"left"} sx={{mb: 4,mt:4}}>
                Search a company name or registration number.  *
            </Typography>
            {searchForm}
            <br />
            {content}
        </Box>
    );
}