import React, {useContext, useState} from 'react'
import {
    InputAdornment, Pagination, Stack
} from "@mui/material";
import Box from "@mui/material/Box";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import mailService from "./MailService";
import Button from "@mui/material/Button";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro} from "@mui/x-data-grid-pro";


export default function ListCompanyMail({companyId, antiForgeryToken, refreshUser}) {
    const { histNavigate,back } = useContext(NavigationContext);
    const [isLoading, setIsLoading] = React.useState(true);
    const [users, setUsers] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    const navigate = useNavigate();

    const fetchData = async () => {
        setPaginationModel((prevModel) => ({...prevModel, page: 0}));
        const result = await mailService.listCompanyMail(antiForgeryToken, companyId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            setUsers(data.mail);
            setRowCountState(data.mail.length);
            setIsLoading(false);
        } else {
            enqueueSnackbar(data.description, {variant:'error'});
        }
    };


    React.useEffect(() => {
        fetchData();
    }, []);

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };


    

    const handleRowClick = (params) => {
        histNavigate(`/Mail/Update/${params.id}`,'Update Mail');
    };

    const dateFormatter = (params) => {
        if(params.value === null){
            return "";
        }
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleDateString('en-GB');
    };

    return (
        <Box style={{ height: 400 }}>
            <DataGridPro
                rows={users.slice(
                    paginationModel.page * paginationModel.pageSize,
                    paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                )}
                columns={[
                    { field: 'receivedDate', headerName: 'Received Date', flex: 1, valueFormatter: dateFormatter},
                    { field: 'personName', headerName: 'Person Name', flex: 1 },
                    { field: 'mailType', headerName: 'Mail Type', flex: 1 },
                    { field: 'mailProcessStatus', headerName: 'Status', flex: 1 },
                    { field: 'archiveDate', headerName: 'Archive Date', flex: 1, valueFormatter: dateFormatter }
                ]}
                rowCount={paginationModel.pageSize}
                pageSize={paginationModel.pageSize}
                hideFooter={true}
                page={paginationModel.page}
                onPageChange={handlePageChange}
                loading={isLoading}
                onRowClick={handleRowClick}
                getRowClassName={() => 'rowCursorPointer'}
                autoHeight
                sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                        WebkitTextStroke: '0.75px'
                    },
                }}
            />
            <Box display="flex" justifyContent="flex-end">
                <Pagination
                    count={Math.ceil(rowCountState / paginationModel.pageSize)}
                    page={paginationModel.page + 1}
                    onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                />
            </Box>
            <Stack direction="row" spacing={2} marginTop={2}>
                <Button variant="contained" color="secondary" onClick={(e) => {
                    back();
                }}>
                    Back
                </Button>
            </Stack>
        </Box>
    );

}