import * as React from 'react';
import { useParams } from "react-router-dom";
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {COMPANY_FORMATION_TABS} from "../enums";
import {enqueueSnackbar} from "notistack";
import AuthContext from "../api-authorization/AuthContext";
import {CircularProgress, Stack, useMediaQuery} from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import NavigationContext from "../layout/NavigationContext";
import FormationNameCheck from "./FormationNameCheck";
import formationService from "./FormationService";
import FormationYourCompany from "./FormationYourCompany";
import FormationPackageSelect from "./FormationPackageSelect";
import FormationRegisteredOffice from "./FormationRegisteredOffice";
import FormationDirectors from "./FormationDirectors";
import FormationShareholders from "./FormationShareholders";
import FormationPscs from "./FormationPscs";
import FormationElectronicSignature from "./FormationElectronicSignature";
import FormationConfirmation from "./FormationConfirmation";
import TabErrorAlert from "../layout/TabErrorAlert";
import Button from "@mui/material/Button";

export default function FormationParent() {
    const { histNavigate, replaceTopHist,back } = useContext(NavigationContext);
    const { antiForgeryToken, user, refreshUser} = useContext(AuthContext);
    const isMobile = useMediaQuery('(max-width:600px)');
    const { companyFormationIdParam } = useParams();
    const [companyFormationId, setCompanyFormationId] = useState(companyFormationIdParam ? companyFormationIdParam : null);
    const [formationLoading, setFormationLoading] = useState(true);
    
    const [formationData, setFormationData] = useState({
        accountId:user.accountId,
    });

    const [tabValue, setTabValue] = React.useState(0);
    const navigate = useNavigate();
    let content;
    const handleChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    useEffect( () => {
        getFormationData();
    }, [companyFormationId])

    async function getFormationData() {
        if(companyFormationId){
            const result = await formationService.getFormation(antiForgeryToken, companyFormationId);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setFormationData(data);
                    setTabValue(data.completedTab + 1);
                } else {
                    enqueueSnackbar(data.description, {variant:'error'});
                }
            }
        }
        setFormationLoading(false);
    }

    async function submitFormation() {
        const result = await formationService.submitFormation(antiForgeryToken, companyFormationId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setFormationData(data);
                setTabValue(data.completedTab + 1);
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setFormationLoading(false);
    }
    
    const createOrUpdateFormationData  = async (newFormationData, moveNextTab = true) => {
        await setFormationLoading(true);
        let result;
        if(!companyFormationId){
            result = await formationService.createFormation(antiForgeryToken, newFormationData);
        }
        else {
            result = await formationService.updateFormation(antiForgeryToken, newFormationData);
        }
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setFormationData(data);
                if(moveNextTab)
                {
                    setTabValue(prevTabValue => parseInt(prevTabValue) + 1);   
                }
                if(!companyFormationId){
                    navigate('/CompanyFormation/FormCompany/' + data.id);
                }
                setCompanyFormationId(data.id);
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        await setFormationLoading(false);
    };
    
    const getTabIssues = (tab) => {
        let issues = [];
        if(formationData.tabIssues){
            issues = formationData.tabIssues[tab] || [];
        }
        return issues;
    }
    
    const goToTab = (tab) => {
        setTabValue(tab);
    }
    
    const parentBack = () => {
        setTabValue(prevTabValue => parseInt(prevTabValue) - 1);
    }
    
    
    const getTabHeader = (label,tab) => {
        let disabled = false;
        if(tab != COMPANY_FORMATION_TABS.NAME_CHECK && (formationData?.completedTab == null || formationData.completedTab + 1 < tab)){
            disabled = true;
        }
        return (
            <Tab
                disabled={disabled}
                label={
                    <Stack direction={"row"}>
                        <Typography>
                            {label}
                            {getTabIssues(tab).length > 0 &&
                                <TabErrorAlert title={label} messages={getTabIssues(tab)}/>
                            }
                        </Typography>
                    </Stack>
                }
                sx={{alignItems:"flex-start", textAlign: 'left'}} value={tab.toString()}/>
        );
    }
    

    if (formationLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        const tabListProps = isMobile ?
            {display: 'none'} :
            {width: "16%", borderRight: 1, borderColor: 'divider'}
        content = (
            <>
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                    <img src="/menu/companiesHouseLogoBlack@4x.png" alt="Companies House"
                         style={{height: '34px', width: '34px', marginRight:'20px'}}/>
                    Form a New Company
                </Typography>
                <TabContext value={tabValue.toString()} sx={{ borderRight: 1, borderColor: 'divider' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={tabListProps}>
                            <TabList onChange={handleChange} aria-label="Form a new company" orientation={"vertical"}>
                                {getTabHeader("Name Check",COMPANY_FORMATION_TABS.NAME_CHECK)}
                                {getTabHeader("Package Select",COMPANY_FORMATION_TABS.PACKAGE_SELECT)}
                                {getTabHeader("Your Company",COMPANY_FORMATION_TABS.YOUR_COMPANY)}
                                {getTabHeader("Registered Office",COMPANY_FORMATION_TABS.REGISTERED_OFFICE)}
                                {getTabHeader("Directors & Secretaries",COMPANY_FORMATION_TABS.DIRECTORS_AND_SECRETARIES)}
                                {getTabHeader("Shareholders",COMPANY_FORMATION_TABS.SHAREHOLDERS)}
                                {getTabHeader("PSCs",COMPANY_FORMATION_TABS.PSCS)}
                                {getTabHeader("Electronic Signature",COMPANY_FORMATION_TABS.ELECTRONIC_SIGNATURE)}
                                {getTabHeader("Confirmation",COMPANY_FORMATION_TABS.CONFIRMATION)}
                            </TabList>
                            <Button variant="contained" color="secondary" sx={{mt: 10,ml:2}} onClick={(e) => {
                                back();
                            }}>Save & Exit</Button>
                        </Box>
                        <Box sx={{ flex: 1,mt:-4 }}>
                            <TabPanel value={COMPANY_FORMATION_TABS.NAME_CHECK.toString()}>
                                <FormationNameCheck formationData={formationData} setFormationData={setFormationData} createOrUpdateFormationData={createOrUpdateFormationData}/>
                            </TabPanel>
                            <TabPanel value={COMPANY_FORMATION_TABS.PACKAGE_SELECT.toString()}>
                                <FormationPackageSelect formationData={formationData} createOrUpdateFormationData={createOrUpdateFormationData} parentBack={parentBack}/>
                            </TabPanel>
                            <TabPanel value={COMPANY_FORMATION_TABS.YOUR_COMPANY.toString()}>
                                <FormationYourCompany formationData={formationData} createOrUpdateFormationData={createOrUpdateFormationData} goToTab={goToTab} parentBack={parentBack}/>
                            </TabPanel>
                            <TabPanel value={COMPANY_FORMATION_TABS.REGISTERED_OFFICE.toString()}>
                                <FormationRegisteredOffice formationData={formationData} createOrUpdateFormationData={createOrUpdateFormationData} parentBack={parentBack}/>
                            </TabPanel>
                            <TabPanel value={COMPANY_FORMATION_TABS.DIRECTORS_AND_SECRETARIES.toString()}>
                                <FormationDirectors formationData={formationData} createOrUpdateFormationData={createOrUpdateFormationData} parentBack={parentBack}/>
                            </TabPanel>
                            <TabPanel value={COMPANY_FORMATION_TABS.SHAREHOLDERS.toString()}>
                                <FormationShareholders formationData={formationData} createOrUpdateFormationData={createOrUpdateFormationData} parentBack={parentBack}/>
                            </TabPanel>
                            <TabPanel value={COMPANY_FORMATION_TABS.PSCS.toString()}>
                                <FormationPscs formationData={formationData} createOrUpdateFormationData={createOrUpdateFormationData} parentBack={parentBack}/>
                            </TabPanel>
                            <TabPanel value={COMPANY_FORMATION_TABS.ELECTRONIC_SIGNATURE.toString()}>
                                <FormationElectronicSignature formationData={formationData} createOrUpdateFormationData={createOrUpdateFormationData} parentBack={parentBack}/>
                            </TabPanel>
                            <TabPanel value={COMPANY_FORMATION_TABS.CONFIRMATION.toString()}>
                                <FormationConfirmation formationData={formationData} createOrUpdateFormationData={createOrUpdateFormationData} submitFormation={submitFormation} parentBack={parentBack}/>
                            </TabPanel>
                        </Box>
                    </Box>
                </TabContext>
            </>
        );
    }

    return (
        <>
            {content}
        </>
    );
}