import React from 'react';
import {Grid, InputAdornment, MenuItem, Select} from "@mui/material";
import {Controller} from "react-hook-form";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import Divider from "@mui/material/Divider";
import {POST_BOX_NAME_TYPE} from "../../../enums";
import Typography from "@mui/material/Typography";

export default function AddPostBoxDetails({formState, control, selectedPostBoxNameType, setSelectedPostBoxNameType, setPostBoxName}) {
    
    
    return (
        <Box sx={{ maxWidth: 600 }}>
            <Typography variant="h6" noWrap align={"left"} sx={{mb: 2,mt:2}}>
                Please complete the below information
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <Controller
                        name="postBoxName"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: 'Post box name is required',
                            maxLength : {value: selectedPostBoxNameType === 1 ? 4 : 100, message: selectedPostBoxNameType === 1 ? "Max length is 4" : "Max length is 100"},
                            pattern: { value: selectedPostBoxNameType === 1 ? /^[0-9]+$/ : null, message: "Invalid box number" }
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Post Box Name *"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size="small"
                                inputProps={{
                                    maxLength: selectedPostBoxNameType === 1 ? 4: 100,
                                }}
                                onChange={(e) => {
                                    if(selectedPostBoxNameType === 1) {
                                        setPostBoxName("BM BOX " + e.target.value);
                                    }
                                    else
                                    {
                                        setPostBoxName(e.target.value);
                                    }
                                    field.onChange(e);
                                }}
                                sx={{mb:0}}
                                error={!!formState.errors.postBoxName}
                                helperText={formState.errors.postBoxName ? formState.errors.postBoxName.message : ''}
                                InputProps={{
                                    startAdornment: selectedPostBoxNameType === 1 ?
                                        <InputAdornment position="start">BM BOX</InputAdornment> : null,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Divider sx={{ height: 28, m: 0.5, borderWidth:1, borderColor:'grey.500', borderLeft: 1 }} orientation="vertical" />
                                            <Select
                                                displayEmpty
                                                value={selectedPostBoxNameType}
                                                onChange={(e) => {
                                                    setSelectedPostBoxNameType(e.target.value);
                                                }}
                                                sx={{
                                                    p: 0,
                                                    minWidth: 'auto',
                                                    '& .MuiOutlinedInput-notchedOutline': { border: 0 },
                                                    '&:focus-within .MuiOutlinedInput-notchedOutline': { border: 0 },
                                                    '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 0 },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 0 },
                                                    '& .MuiSelect-root:focus': {
                                                        background: 'none',
                                                    },
                                                }}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "right"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "right"
                                                    }
                                                }}
                                            >
                                                <MenuItem value={POST_BOX_NAME_TYPE.NUMBER}>Number</MenuItem>
                                                <MenuItem value={POST_BOX_NAME_TYPE.NAME}>Name</MenuItem>
                                            </Select>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} >
                    <Controller
                        name="previousNames"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Previous Names"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size="small"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                            name="startDate"
                            control={control}
                            defaultValue={null}
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    label="Start Date"
                                    slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                    format={"DD/MM/YYYY"}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{mt:1}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                            name="endDate"
                            control={control}
                            defaultValue={null}
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    label="End Date"
                                    format={"DD/MM/YYYY"}
                                    slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>

            </Grid>
        </Box>
    );

}