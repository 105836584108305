import React, {useContext, useState} from "react";
import AuthContext from "../../../api-authorization/AuthContext";
import Box from "@mui/material/Box";
import {Checkbox, CircularProgress, FormControlLabel, Stack} from "@mui/material";
import AddUKCompanySearch from "./AddUKCompanySearch";
import companiesHouseService from "../../../companiesHouse/CompaniesHouseService";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {ADD_UK_COMPANY_SCREEN_MODE, RESOURCE_TYPE, ROLES} from "../../../enums";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddCompanySelect from "../AddCompanySelect";
import {FormGroup} from "reactstrap";
import {Controller, useForm} from "react-hook-form";
import inputService from "../../../util/InputService";
import companyService from "../../../companies/CompanyService";
import AddCompanyPrimaryContact from "../shared/AddCompanyPrimaryContact";
import AddCompanyDetails from "./AddCompanyDetails";

export default function AddUKCompanyParent({handleNext, handleExitDialogOpen,companyTypeToAdd, setCompanyTypeToAdd, accountData, setCompanyData,setResourceType}) {
    const {  handleSubmit, control, formState,setValue } = useForm();
    const {antiForgeryToken, user, refreshUser} = useContext(AuthContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const [companyConfirmation,setCompanyConfirmation] = React.useState(false);
    
    const [screenMode, setScreenMode] = React.useState(ADD_UK_COMPANY_SCREEN_MODE.SEARCH);
    const [chCompanyData, setChCompanyData] = React.useState({});
    const [isComplianceApproved, setIsComplianceApproved] = useState(false);
    const [isAddressApproved, setIsAddressApproved] = useState(false);
    const [isDisablePrimaryContactChecked, setIsDisablePrimaryContactChecked] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    
    const navigate = useNavigate();
    
    let content;

    async function getCompanyFromCH(companyNumber) {
        setIsLoading(true);
        const result = await companiesHouseService.getCompany(antiForgeryToken, companyNumber);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setChCompanyData(data);
                setScreenMode(ADD_UK_COMPANY_SCREEN_MODE.DETAILS);
                setIsLoading(false);
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
    }

    const handleFormSubmit  = async (primaryContactData) => {
        setDisableSubmit(true);
        let addCompanyToAccountRequest = chCompanyData;
        addCompanyToAccountRequest.accountId = accountData.id;
        primaryContactData = inputService.trimFields(primaryContactData);
        addCompanyToAccountRequest.primaryContact = primaryContactData;
        const result = await companyService.addCompanyToAccount(antiForgeryToken, addCompanyToAccountRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully added company to account", {variant:'success'});
                if(user.role == ROLES.ACCOUNT_ADMIN){
                    refreshUser();
                }
                let getCompanyRequest = {
                    id: data
                };
                const companyDataResult = await companyService.getCompany(antiForgeryToken, getCompanyRequest);
                let companyDataResultJson = await companyDataResult.json();
                if (companyDataResult.ok) {
                    await setResourceType(RESOURCE_TYPE.COMPANY);
                    await setCompanyData(companyDataResultJson);
                    handleNext();
                } else {
                    enqueueSnackbar(companyDataResultJson.description);
                }
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setDisableSubmit(false);
    };
    
    if (isLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <>
                {screenMode === ADD_UK_COMPANY_SCREEN_MODE.SEARCH && (
                    <>
                        <AddCompanySelect companyTypeToAdd={companyTypeToAdd} setCompanyTypeToAdd={setCompanyTypeToAdd}/>
                        <AddUKCompanySearch handleExitDialogOpen={handleExitDialogOpen} getCompanyFromCH={getCompanyFromCH} accountData={accountData}/>
                    </>
                )}
                {screenMode === ADD_UK_COMPANY_SCREEN_MODE.DETAILS && (
                    <Box sx={{ width: '100%' }}>
                        <Typography variant="h5" noWrap component="div" align={"left"} sx={{ mt: 2, mb:4 }}>
                            Companies House Details for - {chCompanyData.name}
                        </Typography>

                        <AddCompanyDetails companyDetails={chCompanyData} />
                        <Controller
                            name="companyConfirmation"
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value}
                                                           onChange={(e) => {
                                                               // Update react-hook-form's form state
                                                               field.onChange(e.target.checked);

                                                               // Update local state - You might not need this anymore
                                                               setCompanyConfirmation(e.target.checked);
                                                           }}
                                        />}
                                        sx={{mt:2}}
                                        label={`Confirm this is the correct company and list of directors you want to add to account - ${accountData.email} before proceeding`}
                                    />
                                </FormGroup>
                            )}
                        />
                        <Stack direction="row" spacing={2} marginTop={2}>
                            <Button variant="contained" color="secondary" onClick={(e) => {
                                setScreenMode(ADD_UK_COMPANY_SCREEN_MODE.SEARCH);
                            }}>
                                Back
                            </Button>
                            <Button variant="contained" color="secondary" disabled={companyConfirmation === false} onClick={(e) => {
                                setScreenMode(ADD_UK_COMPANY_SCREEN_MODE.ADD_PRIMARY_CONTACT);
                            }}>
                                Next
                            </Button>
                        </Stack>
                    </Box>
                )}
                {screenMode === ADD_UK_COMPANY_SCREEN_MODE.ADD_PRIMARY_CONTACT && (
                    <>
                        <form onSubmit={handleSubmit(handleFormSubmit)}>
                            <Typography variant="h5" noWrap component="div" align={"left"} sx={{mt: 2, mb: 4}}>
                                Add Primary Contact information for - {chCompanyData.name}
                            </Typography>
                            <AddCompanyPrimaryContact setValue={setValue} formState={formState} control={control}
                                                      setIsComplianceApproved={setIsComplianceApproved}
                                                      setIsAddressApproved={setIsAddressApproved}
                                                      accountData={accountData}
                                                      isDisablePrimaryContactChecked={isDisablePrimaryContactChecked}
                                                      setIsDisablePrimaryContactChecked={setIsDisablePrimaryContactChecked}
                                                      companyType={companyTypeToAdd}/>
                            <Stack direction="row" spacing={2} marginTop={2}>
                                <Button variant="contained" color="secondary" onClick={(e) => {
                                    setScreenMode(ADD_UK_COMPANY_SCREEN_MODE.DETAILS);
                                }}>
                                    Back
                                </Button>
                                <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit || isComplianceApproved === false || isAddressApproved === false}>
                                    Next
                                </Button>
                            </Stack>
                        </form>
                        </>
                        )}
                    </>
                );
                }

                return content;
                }