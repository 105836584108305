import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {ACCOUNT_TABS, ADD_ACCOUNT_WIZARD_TABS, ROLES} from "../enums";
import {
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Step,
    StepLabel,
    Stepper
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AuthContext from "../api-authorization/AuthContext";
import {Circle, Done} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import CreateAccount from "./accounts/CreateAccount";
import Divider from "@mui/material/Divider";
import AddCompanyParent from "./companies/AddCompanyParent";
import accountService from "../accounts/AccountService";
import {enqueueSnackbar} from "notistack";
import AddProduct from "./products/AddProduct";
import UpdateMailSettings from "./mailSettings/UpdateMailSettings";
import Button from "@mui/material/Button";
import NavigationContext from "../layout/NavigationContext";

function StepperIconWithNumber({ size = 16, count = 0, completed = false, active = false }) {
    const theme = useTheme();
    let borderColor = theme.palette.primary.main;
    let top = "0.7em";
    let circleBackgroundColour = "primary";
    let countColour = "white";
    
    if(completed){
        borderColor = theme.palette.iconCompleted.main;
        top = "0.4em";
        circleBackgroundColour = "iconCompleted";
    }
    else if(active){
        borderColor = theme.palette.primary.main;
        top = "0.7em";
        circleBackgroundColour = "primary";
    }
    else{
        borderColor = theme.palette.iconToBeCompletedBorder.main;
        top = "0.7em";
        circleBackgroundColour = "iconToBeCompletedBackground";
        countColour = theme.palette.iconToBeCompletedBorder.main;
    }
    
    const styles = {
        root: {
            borderRadius: "200px",
            boxSizing: "border-box",
            backgroundColor: "white",
            border: "2px solid",
            position: "relative",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            borderColor: borderColor, 
        },
        icon: {
            fontSize: "2.2em",
        },
        count: {
            position: "absolute",
            lineHeight: 1,
            color: countColour,
            top: top,
            fontSize: "0.9em",
            fontWeight: "bolder",
        },
    };
    
    let content = count;
    if(completed){
        content = <Done/>;
    }

    return (
        <div style={{...styles.root, fontSize: size}}>
            <Circle color={circleBackgroundColour} style={styles.icon} />
            <Typography component="span" style={styles.count}>
                {content}
            </Typography>
        </div>
    );
}

export default function AddAccountWizardParent() {
    const { freshHistNavigate,histNavigate, back } = useContext(NavigationContext);
    const { antiForgeryToken, user, refreshUser} = useContext(AuthContext);
    const [exitDialogOpen, setExitDialogOpen] = React.useState(false);
    const { accountId, companyType } = useParams();
    const addAccountMode = !accountId ? true : false;
    
    let parsedCompanyType = parseInt(companyType);
    
    const [isBusy, setBusy] = useState(true);
    const [accountData, setAccountData] = useState();
    const [companyData,setCompanyData] = useState();
    const [resourceType, setResourceType] = useState();
    
    const navigate = useNavigate();
    let content;
    let steps = [
        {label: "Account details", tab: ADD_ACCOUNT_WIZARD_TABS.CREATE_ACCOUNT, optional: false, hide: !addAccountMode},
        {label: "Companies", tab: ADD_ACCOUNT_WIZARD_TABS.ADD_COMPANY, optional: addAccountMode, hide: false},
        {label: "Products", tab: ADD_ACCOUNT_WIZARD_TABS.ADD_PRODUCT, optional: false, hide: false},
        {label: "Mail settings", tab: ADD_ACCOUNT_WIZARD_TABS.MAIL_SETTINGS, optional: false, hide: (user.role !== ROLES.TENANT_ADMIN && user.role !== ROLES.TENANT_STANDARD)}
    ]
    
    const [activeStep, setActiveStep] = React.useState(ADD_ACCOUNT_WIZARD_TABS.CREATE_ACCOUNT);

    //

    const handleNext = () => {
        // Check if all the next steps are hidden
        const allNextStepsHidden = steps
            .slice(activeStep + 1) // Get all steps after the current step
            .every((step) => step.hide); // Check if all those steps are hidden

        // If it's the last step or all next steps are hidden, navigate to the account details page
        if (activeStep === steps.length - 1 || allNextStepsHidden) {
            freshHistNavigate("/", "Home");
            return;
        }

        // Otherwise, proceed to the next step
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleExitDialogOpen = () => {
        setExitDialogOpen(true);
    };

    const handleExitDialogClose = () => {
        setExitDialogOpen(false);
    };

    React.useEffect(() => {
        if(accountId){
            getAccountData(accountId);
            setActiveStep(ADD_ACCOUNT_WIZARD_TABS.ADD_COMPANY);
        }
        else{
            setBusy(false);
        }
    }, []);

    async function getAccountData(accountId) {
        setBusy(true);
        const result = await accountService.getAccount(antiForgeryToken, accountId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setAccountData(data);
                setBusy(false);
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
    }
    
    const getTabContent = (tab) => {
        switch (tab) {
            case ADD_ACCOUNT_WIZARD_TABS.CREATE_ACCOUNT:
                return (
                    <CreateAccount
                        handleNext={handleNext}
                        getAccountData={getAccountData}
                    />
                );
            case ADD_ACCOUNT_WIZARD_TABS.ADD_COMPANY:
                return (
                    <AddCompanyParent
                        handleExitDialogOpen={handleExitDialogOpen}
                        handleNext={handleNext}
                        accountData={accountData}
                        setCompanyData={setCompanyData}
                        setResourceType={setResourceType}
                        companyType={parsedCompanyType}
                    />
                );
            case ADD_ACCOUNT_WIZARD_TABS.ADD_PRODUCT:
                return (
                    <AddProduct
                        handleExitDialogOpen={handleExitDialogOpen}
                        handleNext={handleNext}
                        accountData={accountData}
                        companyData={companyData}
                        resourceType={resourceType}
                    />
                );
            case ADD_ACCOUNT_WIZARD_TABS.MAIL_SETTINGS:
                return (
                    <UpdateMailSettings
                        companyData={companyData}
                        resourceType={resourceType}
                        handleNext={handleNext}
                    />  
                );
            default:
                return (
                    <CreateAccount
                        handleNext={handleNext}
                        getAccountData={getAccountData}
                    />
                );
        }
    }

    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <>
                <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={activeStep} sx={{mt:2,mb:2,width: "100%"}} alternativeLabel>
                        {steps.map((step, index) => {
                            if(step.hide){
                                return null;
                            }
                            const stepProps = {};
                            const labelProps = {};
                            if (step.optional) {
                                labelProps.optional = (
                                    <Typography variant="caption">Optional</Typography>
                                );
                            }
                            return (
                                <Step key={step.label} {...stepProps}>
                                    <StepLabel
                                        {...labelProps}
                                        StepIconComponent={(props) => (
                                            <StepperIconWithNumber count={props.icon} completed={activeStep > step.tab} active={activeStep == step.tab}/>
                                        )}
                                    >
                                        <Typography variant="h6">{step.label}</Typography>
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <Divider/>
                    <Box sx={{mt:2}}>{getTabContent(steps[activeStep].tab)}</Box>
                    <Dialog
                        open={exitDialogOpen}
                        onClose={handleExitDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Warning"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you wish to exit?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleExitDialogClose}>No</Button>
                            <Button onClick={(e) => {
                                histNavigate(`/Account/Update/${accountData.id}/${ACCOUNT_TABS.DETAILS}`,'Update Account');
                            }} autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </>
        );
    }

    return (
        <>
            {content}
        </>
    );
}