import React, {useContext} from 'react'
import {DataGridPro, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid-pro';
import {CircularProgress, Grid, Pagination} from "@mui/material";
import {useNavigate, useParams} from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AuthContext from "../api-authorization/AuthContext";
import NavigationContext from "../layout/NavigationContext";
import importService from "./ImportService";
import Breadcrumb from "../layout/Breadcrumb";


function CustomToolbar({fileName}) {
    return (
        <GridToolbarContainer>
            <GridToolbarExport csvOptions={{fileName:fileName}}/>
        </GridToolbarContainer>
    );
}

export default function ListCsvImportJobRows() {
    const { histNavigate, back } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser } = useContext(AuthContext);
    const { csvImportJobId} = useParams();
    const [isLoading, setIsLoading] = React.useState(true);
    const [csvImportJobRowResponse, setCsvImportJobRowResponse] = React.useState([]);
    const navigate = useNavigate();

    const fetchData = async () => {
        const result = await importService.listCsvImportJobRows(antiForgeryToken,csvImportJobId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setCsvImportJobRowResponse(data);
                setIsLoading(false);
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);
    
    const dateFormatter = (params) => {
        const date = new Date(params);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleString('en-GB');
    };
    
    let content;
    
    if (isLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        let breadcrumbItems = [
            {description: `Account Import History`, url: `/Import/CsvImportHistory`}
        ];
        
        content = (
            <Box sx={{height: '60vh'}}>
                <Breadcrumb breadcrumbItems={breadcrumbItems}/>
                <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: '#fff' }}>
                    <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}} flexGrow={1}>
                        Account Import Job Details
                    </Typography>
                    <Box sx={{maxWidth: 500}}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                Created At:
                            </Grid>
                            <Grid item xs={6}>
                                {dateFormatter(csvImportJobRowResponse.createdAt)}
                            </Grid>
                            <Grid item xs={6}>
                                Created By:
                            </Grid>
                            <Grid item xs={6}>
                                {csvImportJobRowResponse.createdByEmail}
                            </Grid>
                            <Grid item xs={6}>
                                File:
                            </Grid>
                            <Grid item xs={6}>
                                {csvImportJobRowResponse.fileName}
                            </Grid>
                            <Grid item xs={6}>
                                Client Name:
                            </Grid>
                            <Grid item xs={6}>
                                {csvImportJobRowResponse.clientName}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <br />
                <DataGridPro
                    rows={csvImportJobRowResponse.csvImportRows}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                rowNumber: false,
                                title: false,
                                firstName: false,
                                lastName: false,
                                tradingName: false,
                                phoneNumber: false,
                                addressLine1: false,
                                addressLine2: false,
                                town: false,
                                country: false,
                                postCode: false,
                                subscription: false,
                            },
                        },
                    }}
                    columns={[
                        { field: 'thirdPartyId', headerName: 'Third Party ID', flex: 1 },
                        { field: 'rowNumber', headerName: 'Row', flex: 1 },
                        { field: 'companyNumber', headerName: 'Company Number', flex: 1 },
                        { field: 'companyName', headerName: 'Company Name', flex: 2 },
                        { field: 'postBoxNumber', headerName: 'Post Box Number', flex: 1 },
                        { field: 'postBoxName', headerName: 'Post Box Name', flex: 2 },
                        { field: 'tradingName', headerName: 'Trading Name', flex: 1 },
                        { field: 'title', headerName: 'Title', flex: 1 },
                        { field: 'firstName', headerName: 'First Name', flex: 1 },
                        { field: 'lastName', headerName: 'Last Name', flex: 1 },
                        { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
                        { field: 'email', headerName: 'Email', flex: 2 },
                        { field: 'addressLine1', headerName: 'Address Line 1', flex: 1 },
                        { field: 'addressLine2', headerName: 'Address Line 2', flex: 1 },
                        { field: 'town', headerName: 'Town', flex: 1 },
                        { field: 'country', headerName: 'Country', flex: 1 },
                        { field: 'postCode', headerName: 'Post Code', flex: 1 },
                        { field: 'productName', headerName: 'Product Name', flex: 2 },
                        { field: 'subscription', headerName: 'Subscription', flex: 2 },
                        {
                            field: "result",
                            headerName: "Result",
                            flex: 1,
                            renderCell: (params) => (
                                <>
                                    {params.value === 1 ? 
                                        <Typography color={"green"}>Success</Typography>
                                        : <Typography color={"red"}>Failure</Typography>
                                    }
                                </>
                            )
                        },
                        { field: 'errorMessage', headerName: 'Error', flex: 3 },
                    ]}
                    hideFooter={true}
                    loading={isLoading}
                    slots={{
                        toolbar: () => <CustomToolbar fileName={`${csvImportJobRowResponse.fileName}_results`} />,
                    }}
                    sx={{
                        '.MuiDataGrid-columnHeaderTitle': {
                            WebkitTextStroke: '0.75px'
                        },
                    }}
                />
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}