import React from 'react';
import {Grid} from "@mui/material";
import {Controller} from "react-hook-form";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


export default function AddNonUkCompanyDetails({setCompanyName,formState, control}) {
    
    return (
        <Box sx={{ maxWidth: 600 }}>
            <Typography variant="h6" noWrap align={"left"} sx={{mb: 2,mt:2}}>
                Please complete the below information
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: 'Name is required',
                            maxLength : {value: 100, message: "Max length is 100"},
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Name  *"
                                variant="outlined"
                                margin="normal"
                                onChange={(e) => {
                                    setCompanyName(e.target.value);
                                    field.onChange(e.target.value);
                                }}
                                fullWidth
                                size="small"
                                error={!!formState.errors.name}
                                helperText={formState.errors.name ? formState.errors.name.message : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} >
                    <Controller
                        name="tradingNames"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Trading Names"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size="small"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} >
                    <Controller
                        name="thirdPartyId"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Third Party Id"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size="small"
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Box>
    );

}