import React, {useContext, useState} from 'react'
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {InputAdornment, Pagination} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {ACCOUNT_TABS} from "../enums";
import accountService from "./AccountService";
import AuthContext from "../api-authorization/AuthContext";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro} from "@mui/x-data-grid-pro";

export default function ListClientAccounts() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { antiForgeryToken, user, refreshUser } = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [accounts, setAccounts] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    const navigate = useNavigate();

    const fetchData = async (resetPage = false) => {
        setIsLoading(true);
        if (resetPage) {
            setPaginationModel((prevModel) => ({...prevModel, page: 0}));
        }
        let searchRequest = {
            appScope: 2,
            searchTerm: searchTerm,
            clientId: user.clientId,
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
        };
        const result = await accountService.listAccounts(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setAccounts(data.accounts);
                setRowCountState(data.totalCount);
                setIsLoading(false);
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
    };

    React.useEffect(() => {
        fetchData(true); // on initial load
    }, []);

    const handlePageChange = async (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
        fetchData();
    };

    const handleSearch = (event) => {
        event.preventDefault();
        fetchData(true); // on search
    };

    const handleRowClick = (params) => {
        histNavigate(`/Account/Update/${params.id}/${ACCOUNT_TABS.DETAILS}`,'Update Account');
    };

    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleDateString('en-GB');
    };

    return (
        <Box sx={{height: 400}}>
            <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}}>
                Account search
            </Typography>
            <form onSubmit={handleSearch}>
                <TextField
                    id="search-bar"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    label="Search accounts"
                    variant="outlined"
                    size="small"
                    style={{ width: '100%' }}
                />
            </form>
            <br />
            <DataGridPro
                rows={accounts}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            status: false,
                        },
                    },
                }}

                columns={[
                    { field: 'email', headerName: 'Email', flex: 1},
                    { field: 'name', headerName: 'Name', flex: 1},
                    { field: 'status', headerName: 'Status', flex: 1 },
                    { field: 'dateCreated', headerName: 'Date Created', flex: 1, valueFormatter: dateFormatter }
                ]}
                rowCount={rowCountState}
                pageSize={paginationModel.pageSize}
                hideFooter={true}
                page={paginationModel.page}
                onPageChange={handlePageChange}
                loading={isLoading}
                onRowClick={handleRowClick}
                getRowClassName={() => 'rowCursorPointer'}
                autoHeight
                sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                        WebkitTextStroke: '0.75px'
                    },
                }}
            />
            <Box display="flex" justifyContent="flex-end">
                <Pagination
                    count={Math.ceil(rowCountState / paginationModel.pageSize)}
                    page={paginationModel.page + 1}
                    onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                />
            </Box>
        </Box>
    );
}