import React, {useContext, useState} from 'react'
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    InputAdornment,
    MenuItem, Pagination, Paper,
    Stack
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AuthContext from "../api-authorization/AuthContext";
import mailService from "./MailService";
import Button from "@mui/material/Button";
import {Document, Page} from "react-pdf";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {COMPANY_TABS, RESOURCE_TYPE} from "../enums";
import NavigationContext from "../layout/NavigationContext";
import InfoTooltip from "../layout/InfoTooltip";

export default function ListMyMail() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { antiForgeryToken, user, refreshUser} = useContext(AuthContext);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isMailItemLoading, setIsMailItemLoading] = React.useState(true);
    
    const [mail, setMail] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    
    const [mailGroupedType, setMailGroupedType] = useState(null);
    const [mailReadStatus, setMailReadStatus] = useState(null);
    
    const [mailDialogOpen, setMailDialogOpen] = React.useState(false);
    const [mailItemContent, setMailItemContent] = React.useState(null);
    const [mailItemContentType, setMailItemContentType] = React.useState(null);
    const [canReadMailItem, setCanReadMailItem] = React.useState(true);
    const [mailSubscriptionStatusIsUnpaid, setMailSubscriptionStatusIsUnpaid] = React.useState(false);
    const [mailSubscriptionStatusIsCancelled,setMailSubscriptionStatusIsCancelled] = React.useState(false);
    const [canMailForwardingBeRequested, setCanMailForwardingBeRequested] = React.useState(false);
    const [companyId, setCompanyId] = React.useState(null);
    const [mailId, setMailId] = React.useState(null);
    
    
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    const navigate = useNavigate();

    const fetchData = async () => {
        setPaginationModel((prevModel) => ({...prevModel, page: 0}));
        setIsLoading(true);
        let searchRequest = {
            accountId: user.accountId,
            appScope: 3,
            mailGroupedTypes : mailGroupedType,
            mailReadStatus : mailReadStatus,
            myMail: true
        };
        const result = await mailService.listMail(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            setMail(data.mail);
            setRowCountState(data.mail.length);
            setIsLoading(false);
        } else {
            enqueueSnackbar(data.description, {variant:'error'});
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };

    const handleSearch = (event) => {
        event.preventDefault();
        fetchData();
    };

    let tooltipMsg = (
        <>
        <p><b>How do I see the full scanned item of mail?</b><br/>To see all the scanned pages of mail, please select the item of mail to preview and press the ‘Download’ button on the preview screen.</p>
        <p><b>How do I know when the mail item was received?</b><br/>The ‘Received Date’ field is when the mail item was received by our post room at 27 Old Gloucester Street. We scan and or forward all mail on the same day it is received at our office by Royal Mail. </p>
        <p><b>How do I request a mail item be posted to me?</b><br/>Click to preview the mail item, and select the ‘Request Mail’ link. Your mail will be re-enclosed and posted to you on the same day where possible, or the very next business day. </p>
        </>
    );
    const handleRowClick = async (params) => {
        setIsMailItemLoading(true);
        const result = await mailService.downloadMail(antiForgeryToken, params.id);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            handleMailDialogOpen();
            if(data.mailForwardingSubscriptionUnpaid || data.mailForwardingSubscriptionCancelled){
                setCanReadMailItem(false);
                setMailSubscriptionStatusIsUnpaid(data.mailForwardingSubscriptionUnpaid);
                setMailSubscriptionStatusIsCancelled(data.mailForwardingSubscriptionCancelled);
            }
            else{
                setCanReadMailItem(true);
                setMailSubscriptionStatusIsUnpaid(false);
                setMailSubscriptionStatusIsCancelled(false);
                setMailItemContent(data.content);
                setMailItemContentType(data.contentType)
            }
            setCanMailForwardingBeRequested(data.canMailForwardingBeRequested);
            setCompanyId(data.companyId);
            setMailId(data.mailId);
            setIsMailItemLoading(false);
        } else {
            enqueueSnackbar(data.description, {variant:'error'});
        }
    };
    
    const requestMailForwarding = async (mailId) => {
        setIsLoading(true);
        const result = await mailService.requestMailForwarding(antiForgeryToken, mailId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            enqueueSnackbar("This mail item has been requested for forwarding. Please allow 3-5 business days to receive this mail item.", {variant:'success'});
            handleMailDialogClose();
        } else {
            enqueueSnackbar(data.description, {variant:'error'});
        }
        setIsLoading(false);
    }

    function downloadPDF(pdf) {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        //TODO establish naming conventions for mail items
        const fileName = "mailItem.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    
    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleDateString('en-GB');
    };


    const handleMailDialogOpen = () => {
        setMailDialogOpen(true);
    };

    const handleMailDialogClose = () => {
        setMailDialogOpen(false);
    };


    const renderUnpaidMessage = () => (
        <Dialog
            open={mailDialogOpen}
            onClose={handleMailDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
        >
            <DialogContent>
                <i>
                This company’s account is in arrears.
                <Button
                    sx={{
                        color: "#082874",
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontStyle: "italic",
                    }}
                    onClick={() =>
                        histNavigate(`Company/Update/${companyId}/${COMPANY_TABS.PRODUCTS}`)
                    }
                >
                    Please click here to make payment for the outstanding invoices.
                </Button>
                </i>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleMailDialogClose}
                        variant="contained"
                        color="secondary">Close</Button>
            </DialogActions>
        </Dialog>
    );

    const renderCancelledMessage = () => (
        <Dialog
            open={mailDialogOpen}
            onClose={handleMailDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
        >
            <DialogContent>
                <i>
                    This company no longer has an active mail service product.
                    <Button
                        sx={{
                            color: "#082874",
                            cursor: "pointer",
                            textDecoration: "underline",
                            fontStyle: "italic",
                        }}
                        onClick={() =>
                            histNavigate(`/Product/AddNew/${RESOURCE_TYPE.COMPANY}/${companyId}`)
                        }
                    >
                        Please click here to re-subscribe
                    </Button>
                </i>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleMailDialogClose}
                        variant="contained"
                        color="secondary">Close</Button>
            </DialogActions>
        </Dialog>    
    );

    const renderMailContent = () => {
        if (mailItemContent !== null) {
            return mailItemContentType === "pdf" ? (
                <Dialog
                    open={mailDialogOpen}
                    onClose={handleMailDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="lg"
                >
                    <DialogContent>
                        <Document
                            file={`data:application/pdf;base64,${mailItemContent}`}
                            onLoadError={console.error}
                        >
                            <Page
                                scale={1}
                                pageNumber={1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                            />
                        </Document>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={!canMailForwardingBeRequested}
                            onClick={(e) => {
                                requestMailForwarding(mailId);
                            }}>Request Mail Forwarding</Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => {
                                downloadPDF(mailItemContent);
                            }}>Download</Button>
                        <Button onClick={handleMailDialogClose}
                                variant="contained"
                                color="secondary">Close</Button>
                    </DialogActions>
                </Dialog>
            ) : (
                <Dialog
                    open={mailDialogOpen}
                    onClose={handleMailDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="lg"
                >
                    <DialogContent>
                        <img
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                            src={`data:image/${mailItemContentType};base64,${mailItemContent}`}
                            alt="Preview"
                        />
                    </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={(e) => {
                                    downloadPDF(mailItemContent);
                                }}>Download</Button>
                            <Button onClick={handleMailDialogClose}
                                    variant="contained"
                                    color="secondary">Close</Button>
                        </DialogActions>
                </Dialog>
            );
        } else {
            return (
                <Dialog
                    open={mailDialogOpen}
                    onClose={handleMailDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="lg"
                >
                    <DialogContent>
                        <i>No preview available</i>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleMailDialogClose}
                                variant="contained"
                                color="secondary">Close</Button>
                    </DialogActions>
                </Dialog>
            );
        }
    };

    const renderMailDialog = () => {
        if (mailSubscriptionStatusIsUnpaid) {
            return renderUnpaidMessage();
        } else if (mailSubscriptionStatusIsCancelled) {
            return renderCancelledMessage();
        } else {
            return renderMailContent();
        }
    };

   
    return (
        <Box sx={{height: 900}}>
            <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}}>
                My Mail
            </Typography>
            <InfoTooltip title="My Mail" message={tooltipMsg} />
            </Stack>
            <form onSubmit={handleSearch}>
                <Stack direction="row" spacing={2} sx={{width: "80%"}}>
                    <TextField
                        id="mailGroupedType"
                        select
                        label="Type"
                        value={mailGroupedType}
                        onChange={(e) => {
                            setMailGroupedType(e.target.value);
                            if(e.target.value == null) {
                                setMailGroupedType(null);
                            }
                        }}
                        variant="outlined"
                        margin="normal"
                        size="small"
                        sx={{mt:1, width:"35%"}}
                    >
                        <MenuItem key="0" value={null}>&nbsp;</MenuItem>
                        <MenuItem key="1" value={1}>HMRC</MenuItem>
                        <MenuItem key="2" value={2}>Companies House</MenuItem>
                        <MenuItem key="3" value={3}>The Pension Regulator</MenuItem>
                        <MenuItem key="4" value={4}>Information Commissioners Office</MenuItem>
                        <MenuItem key="5" value={5}>Other Statutory Mail</MenuItem>
                        <MenuItem key="6" value={6}>Business Mail</MenuItem>
                        <MenuItem key="7" value={7}>Parcels</MenuItem>
                    </TextField>
                    <TextField
                        id="mailReadStatus"
                        select
                        label="Status"
                        value={mailReadStatus}
                        onChange={(e) => {
                            setMailReadStatus(e.target.value);
                            if(e.target.value == null) {
                                setMailReadStatus(null);
                            }
                        }}
                        variant="outlined"
                        margin="normal"
                        size="small"
                        sx={{mt:1, width:"35%"}}
                    >
                        <MenuItem key="0" value={null}>&nbsp;</MenuItem>
                        <MenuItem key="1" value={1}>Read</MenuItem>
                        <MenuItem key="2" value={2}>Unread</MenuItem>
                        <MenuItem key="3" value={3}>Archived</MenuItem>
                    </TextField>
                    <Button style={{height:"80%", width: "10%"}}  variant="contained" color="secondary" onClick={(e) => {
                        setMailGroupedType(null);
                        setMailReadStatus(null);
                    }}>
                        Reset
                    </Button>
                    <Button style={{height:"80%", width: "10%"}}  variant="contained" color="secondary" type="submit">
                        Search
                    </Button>
                </Stack>

            </form>
            <br />
            <DataGridPro
                rows={mail.slice(
                    paginationModel.page * paginationModel.pageSize,
                    paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                )}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            postageFee: false,
                            handlingFee: false,
                        },
                    },
                }}
                columns={[
                    { field: 'receivedDate', headerName: 'Received Date', flex: 1, valueFormatter: dateFormatter},
                    { field: 'companyName', headerName: 'Company Name', flex: 1 },
                    { field: 'personName', headerName: 'Person Name', flex: 1 },
                    { field: 'mailType', headerName: 'Mail Type', flex: 1 },
                    { field: 'mailReadStatus', headerName: 'Status', flex: 1 },
                    { field: 'postageFee', headerName: 'Postage', flex: 1, valueFormatter: (params) => params.value?.toFixed(2) },
                    { field: 'handlingFee', headerName: 'Handling', flex: 1, valueFormatter: (params) => params.value?.toFixed(2) }
                ]}
                rowCount={paginationModel.pageSize}
                pageSize={paginationModel.pageSize}
                hideFooter={true}
                page={paginationModel.page}
                onPageChange={handlePageChange}
                loading={isLoading}
                onRowClick={handleRowClick}
                getRowClassName={() => 'rowCursorPointer'}
                autoHeight
                sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                        WebkitTextStroke: "0.75px"
                    }
                }}
            />
            <Box display="flex" justifyContent="flex-end">
                <Pagination
                    count={Math.ceil(rowCountState / paginationModel.pageSize)}
                    page={paginationModel.page + 1}
                    onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                />
            </Box>
            {isMailItemLoading ? (
                <Dialog
                    open={mailDialogOpen}
                    onClose={handleMailDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="lg"
                >
                    <DialogContent>
                        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                            <CircularProgress />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleMailDialogClose}
                                variant="contained"
                                color="secondary">Close</Button>
                    </DialogActions>
                </Dialog>
            ) : (
                renderMailDialog()
            )}
        </Box>
    );
}
