import React, {useEffect} from 'react';
import { Controller } from 'react-hook-form';
import TextField from "@mui/material/TextField";
import {Box, Checkbox, FormControlLabel, Grid, MenuItem} from "@mui/material";
import Typography from "@mui/material/Typography";
import {FormGroup} from "reactstrap";
import TransitionAlert from "../../../layout/TransitionAlert";
import {COMPANY_TYPE} from "../../../enums";

export default function AddCompanyPrimaryContact({setValue,formState, control, setIsComplianceApproved, setIsAddressApproved,accountData,isDisablePrimaryContactChecked,setIsDisablePrimaryContactChecked,companyType}) {
    const setPrimaryContactDetailsSameAsAccount = (e) => {
        if(e === true){
            setIsDisablePrimaryContactChecked(true);
            // Use setValue from useForm() hook to change the value of specific fields
            setValue('phoneNumber', accountData.phoneNumber);
            setValue('title',accountData.title);
            setValue('firstName',accountData.firstName);
            setValue('lastName',accountData.lastName);
            setValue('email',accountData.email);
            setValue('mobileNumber',accountData.mobileNumber);
        }
        else{
            setIsDisablePrimaryContactChecked(false);
            setValue('phoneNumber', '');
            setValue('title',0);
            setValue('firstName','');
            setValue('lastName','');
            setValue('email','');
            setValue('mobileNumber','');
        }
    };

    useEffect(() => {
        setPrimaryContactDetailsSameAsAccount(isDisablePrimaryContactChecked);
    }, []); 
    
    let transitionMessage = "NOTE:   This is the primary contact information for this business. This information is required before the company can be added to the account.";
    let addressApprovedLabel = "I agree to use this address as my mail forwarding address for the company and its directors.";
    if(companyType === 2){
        transitionMessage = "NOTE:   This is the primary contact information for this post box. This information is required before the box can be added to the account.";
        addressApprovedLabel = "I confirm that the primary contact listed above has been AML checked and this will be the mail forwarding address for the post box."
    }
    
    return (
        <Box>
            <TransitionAlert message={transitionMessage}/>
            <Controller
                name="primaryContactDetailsSameAsAccount"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox {...field} checked={field.value}
                                               onChange={(e) => {
                                                   field.onChange(e.target.checked);
                                                   setPrimaryContactDetailsSameAsAccount(e.target.checked);
                                               }}
                            />}
                            label={`Tick this box if the primary contact information is the same as the account owner details.`}
                        />
                    </FormGroup>
                )}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="title"
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                select
                                label="Title"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size="small"
                                disabled={isDisablePrimaryContactChecked}
                            >
                                <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                                <MenuItem key="1" value={1}>Mr</MenuItem>
                                <MenuItem key="2" value={2}>Mrs</MenuItem>
                                <MenuItem key="3" value={3}>Ms</MenuItem>
                                <MenuItem key="4" value={4}>Miss</MenuItem>
                                <MenuItem key="5" value={5}>Dr</MenuItem>
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="phoneNumber"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Phone"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size="small"
                                disabled={isDisablePrimaryContactChecked}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="firstName"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'First name is required' }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="First Name *"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size="small"
                                error={!!formState.errors.firstName}
                                helperText={formState.errors.firstName ? formState.errors.firstName.message : ''}
                                disabled={isDisablePrimaryContactChecked}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="mobileNumber"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Mobile"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size="small"
                                error={!!formState.errors.mobileNumber}
                                helperText={formState.errors.mobileNumber ? formState.errors.mobileNumber.message : ''}
                                disabled={isDisablePrimaryContactChecked}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="lastName"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'Last name is required' }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Last Name *"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size="small"
                                error={!!formState.errors.lastName}
                                helperText={formState.errors.lastName ? formState.errors.lastName.message : ''}
                                disabled={isDisablePrimaryContactChecked}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: 'Email is required',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address',
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Email *"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size="small"
                                error={!!formState.errors.email}
                                helperText={formState.errors.email ? formState.errors.email.message : ''}
                                disabled={isDisablePrimaryContactChecked}
                            />
                        )}
                    />
                </Grid>
                {companyType === COMPANY_TYPE.COMPANY &&
                    <Grid item xs={12} sm={8} md={6}>
                        <Controller
                            name="businessRole"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Business Role"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    error={!!formState.errors.businessRole}
                                    helperText={formState.errors.businessRole ? formState.errors.businessRole.message : ''}
                                />
                            )}
                        />
                    </Grid>
                }
            </Grid>
            <Typography variant="h6" noWrap component="div" align={"left"} sx={{ mt: 2 }}>
                {companyType === COMPANY_TYPE.COMPANY &&
                    <>Address Details</>
                }
                {companyType === COMPANY_TYPE.POST_BOX &&
                    <>Primary Address Details</>
                }
            </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} md={6}>
                        <Controller
                            name="address.addressLine1"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Address Line 1 is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Address Line 1 *"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    error={!!formState.errors.address?.addressLine1}
                                    helperText={formState.errors.address?.addressLine1 ? formState.errors.address.addressLine1.message : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <Controller
                            name="address.addressLine2"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Address Line 2"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <Controller
                            name="address.locality"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Town / City is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Town / City *"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    error={!!formState.errors.address?.locality}
                                    helperText={formState.errors.address?.locality ? formState.errors.address.locality.message : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <Controller
                            name="address.country"
                            control={control}
                            defaultValue={"United Kingdom"}
                            rules={{ required: 'Country is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Country *"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    error={!!formState.errors.address?.country}
                                    helperText={formState.errors.address?.country ? formState.errors.address.country.message : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <Controller
                            name="address.postalCode"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Post Code is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Post Code *"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    error={!!formState.errors.address?.postalCode}
                                    helperText={formState.errors.address?.postalCode ? formState.errors.address.postalCode.message : ''}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            <Controller
                name="marketingOptOut"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox {...field} checked={field.value} />}
                            label="Tick this box if you would like to opt out of marketing communications from our closely selected partners."
                        />
                    </FormGroup>
                )}
            />
            <Controller
                name="addressApproved"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox {...field} checked={field.value}
                                               onChange={(e) => {
                                                   // Update react-hook-form's form state
                                                   field.onChange(e.target.checked);

                                                   // Update local state - You might not need this anymore
                                                   setIsAddressApproved(e.target.checked);
                                               }}
                            />}
                            label={addressApprovedLabel}
                        />
                    </FormGroup>
                )}
            />
            {companyType === COMPANY_TYPE.COMPANY &&
                <Controller
                    name="complianceApproved"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox {...field} checked={field.value}
                                                   onChange={(e) => {
                                                       // Update react-hook-form's form state
                                                       field.onChange(e.target.checked);

                                                       // Update local state - You might not need this anymore
                                                       setIsComplianceApproved(e.target.checked);
                                                   }}
                                />}
                                label={`I, ${accountData.firstName} ${accountData.lastName}, confirm in both this Compliance Statement and the names and addresses in the above 
                                    Primary Stakeholder information, that I have the authority to act for and on behalf of this company and will comply with the Companies Act 2006.`}
                            />
                        </FormGroup>
                    )}
                />
            }
        </Box>
        
        
    );

}