import * as React from 'react';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
    AdminPanelSettingsOutlined, AllInbox, BarChart, CancelOutlined, ContactsOutlined, Dashboard, DynamicFeedOutlined,
    ExpandLess, ExpandMore, ForwardToInboxOutlined,
    MailOutlined, ManageAccountsOutlined, MarkEmailReadOutlined, PersonAddAltOutlined,
    PersonSearchOutlined, PublicOutlined, PublishOutlined, QuizOutlined,
    Scanner,
    Search
} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Collapse } from "@mui/material";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import NavigationContext from "../NavigationContext";
import { useContext, useState, useCallback } from "react";
import {ROLES, SCANNED_MAIL_TYPE} from "../../enums";
import AuthContext from "../../api-authorization/AuthContext";
export default function TenantMenu({ open, setOpen, isMobile }) {
    const { freshHistNavigate } = useContext(NavigationContext);
    const { antiForgeryToken, user } = useContext(AuthContext);
    const theme = useTheme();
    const [expandedMenu, setExpandedMenu] = useState(null); // State to track the currently expanded menu

    const handleToggle = (menu) => {
        setExpandedMenu((prevMenu) => (prevMenu === menu ? null : menu));
    };

    const handleNavigation = useCallback((path, title) => (e) => {
        e.preventDefault();
        if (isMobile) {
            setOpen(false);
        }
        freshHistNavigate(path, title);
    }, [isMobile, setOpen, freshHistNavigate]);

    return (
        <Box display="flex" flexDirection="column" sx={{ height: '90vh', overflow: 'auto' }}>
            <Box>
                <ListItemButton
                    sx={{ minHeight: 48, justifyContent: 'initial', px: 2.5 }}
                    onClick={handleNavigation("/", "Home")}>
                    <ListItemIcon
                        sx={{ minWidth: 0, mr: open ? 3 : 0, justifyContent: 'initial', color: '#fff' }}>
                        <Dashboard />
                    </ListItemIcon>
                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Home</Typography>} sx={{ display: open ? "block" : "none" }} />
                </ListItemButton>

                <ListItemButton
                    sx={{ minHeight: 48, justifyContent: 'initial', px: 2.5 }}
                    onClick={handleNavigation("/Account/Create", "Add Account")}>
                    <ListItemIcon
                        sx={{ minWidth: 0, mr: open ? 3 : 0, justifyContent: 'initial', color: '#fff' }}>
                        <PersonAddAltOutlined />
                    </ListItemIcon>
                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Add Account</Typography>} sx={{ display: open ? "block" : "none" }} />
                </ListItemButton>
                
                <ListItemButton
                    sx={{ minHeight: 48, justifyContent: 'initial', px: 2.5 }}
                    onClick={(e) => {
                        e.preventDefault();
                        if (open) {
                            handleToggle('mail');
                        } else {
                            handleNavigation("/Mail/CheckIn", 'Check-In Mail')(e);
                        }
                    }}>
                    <ListItemIcon
                        sx={{ minWidth: 0, mr: open ? 3 : 0, justifyContent: 'initial', color: '#fff' }}>
                        <MailOutlined />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Mail</Typography>}
                        sx={{ display: open ? "block" : "none" }}
                    />
                    {expandedMenu === 'mail' ? <ExpandLess sx={{ display: open ? "block" : "none" }} /> : <ExpandMore sx={{ display: open ? "block" : "none" }} />}
                </ListItemButton>

                {open &&
                    <Collapse in={expandedMenu === 'mail'} timeout="auto" unmountOnExit sx={{ ml: 2 }}>
                        <List component="div" disablePadding>
                            <ListItem key="CheckInMail" disablePadding>
                                <ListItemButton onClick={handleNavigation("/Mail/CheckIn", 'Check-In Mail')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <MarkEmailReadOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Check-in Mail</Typography>} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="CheckOutMail" disablePadding>
                                <ListItemButton onClick={handleNavigation("/Mail/CheckOut", 'Check-Out Mail')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <ForwardToInboxOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Check-out Mail</Typography>} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="PreSortMail" disablePadding>
                                <ListItemButton onClick={handleNavigation(`Mail/PreSortMailBatches`, 'Pre-Sort Mail')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <AllInbox />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Pre-Sort Mail</Typography>} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="ScannedMail" disablePadding>
                                <ListItemButton onClick={handleNavigation(`/Mail/ScannedMailBatches`, 'Scanned Mail')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <Scanner />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Scanned Mail</Typography>} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="UnknownMail" disablePadding>
                                <ListItemButton onClick={handleNavigation(`/Mail/UnknownMailBatches`, 'Unknown Mail')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <QuizOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Unknown Mail</Typography>} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse>
                }

                <ListItemButton
                    sx={{ minHeight: 48, justifyContent: 'initial', px: 2.5 }}
                    onClick={(e) => {
                        e.preventDefault();
                        if (open) {
                            handleToggle('search');
                        } else {
                            handleNavigation("/Account/ListTenantAccounts", "Account Search")(e);
                        }
                    }}>
                    <ListItemIcon
                        sx={{ minWidth: 0, mr: open ? 3 : 0, justifyContent: 'initial', color: '#fff' }}>
                        <Search />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Search</Typography>}
                        sx={{ display: open ? "block" : "none" }}
                    />
                    {expandedMenu === 'search' ? <ExpandLess sx={{ display: open ? "block" : "none" }} /> : <ExpandMore sx={{ display: open ? "block" : "none" }} />}
                </ListItemButton>

                {open &&
                    <Collapse in={expandedMenu === 'search'} timeout="auto" unmountOnExit sx={{ ml: 2 }}>
                        <List component="div" disablePadding>
                            <ListItem key="AccountSearch" disablePadding>
                                <ListItemButton onClick={handleNavigation("/Account/ListTenantAccounts", 'Account Search')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <PersonSearchOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Account Search</Typography>} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="CompanySearch" disablePadding>
                                <ListItemButton onClick={handleNavigation("/Company/ListTenantCompanies", "Company Search")}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <img width={25} height={25} src={"/menu/companySearch@3x.png"} alt="Company Search" />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Company Search</Typography>} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="PostBoxSearch" disablePadding>
                                <ListItemButton onClick={handleNavigation("/Company/ListTenantPostBoxes", "Company Search")}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <img width={25} height={25} src={"/menu/post_box_icon.svg"} alt="Post Box Search" />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Post Box Search</Typography>} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="NonUkCompanySearch" disablePadding>
                                <ListItemButton onClick={handleNavigation("/Company/ListTenantNonUkCompanies", "Non-UK Company Search")}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <PublicOutlined/>
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Non-UK Company Search</Typography>} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="companiesHouseSearch" disablePadding>
                                <ListItemButton onClick={handleNavigation("/CompaniesHouse/List", 'Companies House Search')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <img width={25} src={"/menu/companiesHouseLogo@3x.png"} alt="Companies House Search" />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Companies House Search</Typography>} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="mailSearch" disablePadding>
                                <ListItemButton onClick={handleNavigation("/Mail/ListTenantMail", 'Mail Search')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <img width={25} src={"/menu/mailSearch@3x.png"} alt="Mail Search" />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Mail Search</Typography>} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse>
                }

                <ListItemButton
                    sx={{ minHeight: 48, justifyContent: 'initial', px: 2.5 }}
                    onClick={(e) => {
                        e.preventDefault();
                        if (open) {
                            handleToggle('manage');
                        } else {
                            handleNavigation("/Tenant/ListTenantUsers", 'User Search')(e);
                        }
                    }}>
                    <ListItemIcon
                        sx={{ minWidth: 0, mr: open ? 3 : 0, justifyContent: 'initial', color: '#fff' }}>
                        <AdminPanelSettingsOutlined />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Manage</Typography>}
                        sx={{ display: open ? "block" : "none" }}
                    />
                    {expandedMenu === 'manage' ? <ExpandLess sx={{ display: open ? "block" : "none" }} /> : <ExpandMore sx={{ display: open ? "block" : "none" }} />}
                </ListItemButton>

                {open &&
                    <Collapse in={expandedMenu === 'manage'} timeout="auto" unmountOnExit sx={{ ml: 2 }}>
                        <List component="div" disablePadding>
                            <ListItem key="UserSearch" disablePadding>
                                <ListItemButton onClick={handleNavigation("/Tenant/ListTenantUsers", 'User Search')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <ManageAccountsOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Admin Users</Typography>} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="ClientSearch" disablePadding>
                                <ListItemButton onClick={handleNavigation("/Client/List", 'Client Search')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <ContactsOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Clients</Typography>} />
                                </ListItemButton>
                            </ListItem>
                            
                            {(user?.role === ROLES.TENANT_ADMIN) && (
                                <ListItem key="CsvImport" disablePadding>
                                    <ListItemButton onClick={handleNavigation("/Import/CsvImportHistory", 'CSV Import History')}>
                                        <ListItemIcon sx={{ color: '#fff' }}>
                                            <PublishOutlined />
                                        </ListItemIcon>
                                        <ListItemText primary={<Typography style={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Import Accounts</Typography>} />
                                    </ListItemButton>
                                </ListItem>
                            )}
                        </List>
                    </Collapse>
                }

                <ListItemButton
                    sx={{ minHeight: 48, justifyContent: 'initial', px: 2.5 }}
                    onClick={(e) => {
                        e.preventDefault();
                        if (open) {
                            handleToggle('reports');
                        } else {
                            handleNavigation("/Reports/CompanyReport", 'Company Report')(e);
                        }
                    }}>
                    <ListItemIcon
                        sx={{ minWidth: 0, mr: open ? 3 : 0, justifyContent: 'initial', color: '#fff' }}>
                        <BarChart />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Reports</Typography>}
                        sx={{ display: open ? "block" : "none" }}
                    />
                    {expandedMenu === 'reports' ? <ExpandLess sx={{ display: open ? "block" : "none" }} /> : <ExpandMore sx={{ display: open ? "block" : "none" }} />}
                </ListItemButton>

                {open &&
                    <Collapse in={expandedMenu === 'reports'} timeout="auto" unmountOnExit sx={{ ml: 2 }}>
                        <List component="div" disablePadding>
                            <ListItem key="CompanyReport" disablePadding>
                                <ListItemButton onClick={handleNavigation("/Reports/CompanyReport", 'Company Report')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <img width={25} src={"/menu/paymentDefaults@3x.png"} alt="Companies" />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography style={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Companies</Typography>} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="MailReport" disablePadding>
                                <ListItemButton onClick={handleNavigation("/Reports/MailReport/List", 'Mail Report')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <DynamicFeedOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Mail</Typography>} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="PaymentDefaults" disablePadding>
                                <ListItemButton onClick={handleNavigation("/Reports/PaymentDisputes/List", 'Payment Disputes')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <img width={25} src={"/menu/paymentDefaults@3x.png"} alt="Payment Defaults" />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography style={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Payment Defaults</Typography>} />
                                </ListItemButton>
                            </ListItem>
                            
                            <ListItem key="VirtualSquatters" disablePadding>
                                <ListItemButton onClick={handleNavigation("/Reports/VirtualSquatters/List", 'Virtual Squatters')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <CancelOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Virtual Squatters</Typography>} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse>
                }
            </Box>
            {open === true && (
                <Box mt="auto" p={2}>
                    <Typography sx={{ color: 'white', fontWeight: 600, fontSize: 10 }}>
                        {theme.menuFooterText}
                    </Typography>
                </Box>
            )}
        </Box>
    );
}
