const addCompanyToAccount  = async (antiForgeryToken, formData) => {
    return fetch(`api/Companies/AddCompanyToAccount`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
};

const addPostBoxToAccount  = async (antiForgeryToken, formData) => {
    return fetch(`api/Companies/AddPostBoxToAccount`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
};

const addNonUkCompanyToAccount  = async (antiForgeryToken, formData) => {
    return fetch(`api/Companies/AddNonUkCompanyToAccount`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
};

const listCompanies  = async (antiForgeryToken, findCompaniesRequest) => {
    return fetch(`api/Companies/FindCompanies`, {
        method: 'POST',
        body: JSON.stringify(findCompaniesRequest),
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const listPostBoxes  = async (antiForgeryToken, findPostBoxesRequest) => {
    return fetch(`api/Companies/FindPostBoxes`, {
        method: 'POST',
        body: JSON.stringify(findPostBoxesRequest),
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const listNonUkCompanies  = async (antiForgeryToken, findPostBoxesRequest) => {
    return fetch(`api/Companies/FindNonUkCompanies`, {
        method: 'POST',
        body: JSON.stringify(findPostBoxesRequest),
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

async function updateCompany(antiForgeryToken, formData) {
    return fetch(`/api/Companies`, {
        method: 'PATCH',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
}

async function updatePostBox(antiForgeryToken, formData) {
    return fetch(`/api/Companies/UpdatePostBox`, {
        method: 'PATCH',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
}

async function updateNonUkCompany(antiForgeryToken, formData) {
    return fetch(`/api/Companies/UpdateNonUkCompany`, {
        method: 'PATCH',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
}

const findCompaniesQuickSearch  = async (antiForgeryToken, searchRequest) => {
    return fetch(`api/Companies/FindCompaniesQuickSearch`, {
        method: 'POST',
        body: JSON.stringify(searchRequest),
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const getCompany  = async (antiForgeryToken, getCompanyRequest) => {
    return fetch(`api/Companies/GetCompany`, {
        method: 'POST',
        body: JSON.stringify(getCompanyRequest),
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const refreshCompany  = async (antiForgeryToken, companyId) => {
    return fetch(`api/Companies/RefreshCompany/${companyId}`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const removeCompanyFromAccount  = async (antiForgeryToken, companyId) => {
    return fetch(`api/Companies/RemoveCompanyFromAccount/${companyId}`, {
        method: 'DELETE',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const removePostBoxFromAccount  = async (antiForgeryToken, companyId) => {
    return fetch(`api/Companies/RemovePostBoxFromAccount/${companyId}`, {
        method: 'DELETE',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const removeNonUkCompanyFromAccount  = async (antiForgeryToken, companyId) => {
    return fetch(`api/Companies/RemoveNonUkCompanyFromAccount/${companyId}`, {
        method: 'DELETE',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const getBillingDetails  = async (antiForgeryToken, companyId) => {
    return fetch(`/api/Companies/GetBillingDetails/${companyId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

async function findCompaniesMailSettings(antiForgeryToken, searchRequest){
    return fetch(`/api/Companies/FindCompaniesMailSettings`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(searchRequest),
    });
}

async function getCompanyMailSettings(antiForgeryToken, companyId){
    return fetch(`/api/Companies/GetMailSettings/${companyId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function updateMailSettings(antiForgeryToken, formData){
    return fetch(`/api/Companies/UpdateMailSettings`, {
        method: 'PATCH',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
}

async function sendCompanyEmail(antiForgeryToken, formData){
    return fetch(`/api/Companies/SendCompanyEmail`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
}

function buildAuthHeaders(antiForgeryToken) {
    return !antiForgeryToken ? {} : {
        [antiForgeryToken.tokenName] : antiForgeryToken.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
}

const companyService = Object.freeze({
    addCompanyToAccount,
    addPostBoxToAccount,
    addNonUkCompanyToAccount,
    listCompanies,
    listPostBoxes,
    listNonUkCompanies,
    getCompany,
    getBillingDetails,
    findCompaniesMailSettings,
    removeCompanyFromAccount,
    removePostBoxFromAccount,
    removeNonUkCompanyFromAccount,
    getCompanyMailSettings,
    updateMailSettings,
    findCompaniesQuickSearch,
    refreshCompany,
    updateCompany,
    sendCompanyEmail,
    updatePostBox,
    updateNonUkCompany
});
export default companyService;