import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {enqueueSnackbar} from "notistack";
import {useForm} from "react-hook-form";
import PrimaryContactForm from "./PrimaryContactForm";
import {CircularProgress, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import companyService from "../CompanyService";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AuthContext from "../../api-authorization/AuthContext";
import inputService from "../../util/InputService";
import personService from "./PersonService";
import NavigationContext from "../../layout/NavigationContext";
import {COMPANY_TYPE} from "../../enums";

export default function CreatePerson() {
    const { histNavigate, back } = useContext(NavigationContext);
    const {handleSubmit, control, formState, getValues, setValue} = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const {companyId} = useParams();
    const [companyData, setCompanyData] = useState();
    const navigate = useNavigate();
    const { antiForgeryToken, user,refreshUser} = useContext(AuthContext);

    const [isBusy, setIsBusy] = useState(true);
    const [isForwardingAddressAmlApproved, setIsForwardingAddressAmlApproved] = useState(false);
    const [forwardingAddressRequiresAmlApproval, setForwardingAddressRequiresAmlApproval] = useState(false);

    useEffect(() => {
        async function fetchData() {
            let getCompanyRequest = {
                id: companyId
            };
            const result = await companyService.getCompany(antiForgeryToken, getCompanyRequest);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            } else {
                let data = await result.json();
                if (result.ok) {
                    setCompanyData(data);
                    setIsBusy(false);
                } else {
                    enqueueSnackbar(data.description, {variant:'error'});
                }
            }
        }

        fetchData();
    }, [companyId])

    function checkIfPersonRequiresAmlApproval(e, field) {
        field.onChange(e); // This is needed to ensure react-hook-form's state is updated
        setForwardingAddressRequiresAmlApproval(getValues("isPrimaryContact"));
        if(!getValues("isPrimaryContact")){
            setValue("forwardingAddressAmlApproved", false);
            setIsForwardingAddressAmlApproved(false);
        }
    }

    const handleFormSubmit  = async (formData) => {
        setIsBusy(true);
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        formData.companyId = companyId;
        const result = await personService.createPerson(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully added contact to company", {variant:'success'});
                back();
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setDisableSubmit(false);
        setIsBusy(false);
    };

    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress/>
            </Box>
        );
    } else {
        content = (
            <Box sx={{ maxWidth: 1200 }}>
                <Typography variant="h5" noWrap align={"left"} sx={{mb: 2}}>
                    Add Contact - {companyData.companyType === COMPANY_TYPE.POST_BOX ? companyData.postBoxName : companyData.name}
                </Typography>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <PrimaryContactForm formState={formState} control={control}
                                        setIsForwardingAddressAmlApproved={setIsForwardingAddressAmlApproved}
                                        checkIfPersonRequiresAmlApproval={checkIfPersonRequiresAmlApproval}
                                        forwardingAddressRequiresAmlApproval={forwardingAddressRequiresAmlApproval}
                                        companyType={companyData.companyType}
                                        user={user}
                    />
                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            back();
                        }}>
                            Back
                        </Button>
                        <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit || (!isForwardingAddressAmlApproved && forwardingAddressRequiresAmlApproval)}>
                            Submit
                        </Button>
                    </Stack>
                </form>
            </Box>
        );
    }
    return (
        <>
            {content}
        </>
    );
}