import React, {useContext, useEffect, useRef, useState} from 'react'
import {Pagination} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TransitionAlert from "../layout/TransitionAlert";
import {COMPANY_TABS, ROLES} from "../enums";
import companyService from "../companies/CompanyService";
import {enqueueSnackbar} from "notistack";
import AuthContext from "../api-authorization/AuthContext";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro} from "@mui/x-data-grid-pro";
import Button from "@mui/material/Button";
import {Done} from "@mui/icons-material";

export default function RefeshCompanies() {
    const { histNavigate } = useContext(NavigationContext);
    const { antiForgeryToken, user, refreshUser} = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [companies, setCompanies] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [jobRunning, setJobRunning] = React.useState(false);
    const [companiesProcessed, setCompaniesProcessed] = React.useState([]);
    const isJobRunning = useRef(false);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 9999,
    });
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            isJobRunning.current = false;
        };
    }, []);

    useEffect(() => {
        if (user.loggedIn == false || (user.role !== ROLES.TENANT_ADMIN && user.role !== ROLES.TENANT_STANDARD)) {
            navigate('/');
        }
    }, [user, navigate]);

    const fetchData = async () => {
        let searchRequest = {
            appScope: 1,
            searchTerm: searchTerm
        };
        const result = await companyService.listCompanies(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            setCompanies(data.companies);
            setRowCountState(data.companies.length);
            setIsLoading(false);
        } else {
            enqueueSnackbar(data.description, {variant:'error'});
        }
    };

    const refreshAllCompanies = async () => {
        setJobRunning(true);
        isJobRunning.current = true;
        for (let company of companies) {
            if (isJobRunning.current) {
                await refreshCompanyWithRetry(company, 3);
            } else {
                break;
            }
        }
        isJobRunning.current = false;
        setJobRunning(false);
    };

    const refreshCompanyWithRetry = async (company, retries) => {
        try {
            const success = await refreshCompany(company);

            if (!success && retries > 0) {
                enqueueSnackbar("Waiting 5 minutes...", {variant:'warning'});
                await new Promise(resolve => setTimeout(resolve, 5 * 60 * 1000));
                return refreshCompanyWithRetry(company, retries - 1);
            }
        } catch (error) {

            if (retries > 0) {
                enqueueSnackbar("Waiting 5 minutes...", {variant:'warning'});
                await new Promise(resolve => setTimeout(resolve, 5 * 60 * 1000));
                return refreshCompanyWithRetry(company, retries - 1);
            }
            else{
                return false;
            }
        }
    };

    const refreshCompany  = async (company) => {
        if(isJobRunning.current === false){
            return false;
        }
        const result = await companyService.refreshCompany(antiForgeryToken, company.id);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            if (result.ok) {
                setCompaniesProcessed((prevCompaniesProcessed) => [...prevCompaniesProcessed, company.id]);
                return true;
            } else {
                let data = await result.json();
                enqueueSnackbar(`Error processing company ${company.number}` + data.description, {variant:'error'});
                return false;
            }
        }
    };
    
    


    React.useEffect(() => {
        fetchData();
    }, [paginationModel]);

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };

    const handleSearch = (event) => {
        event.preventDefault();
        fetchData();
    };

    const handleRowClick = (params) => {
        histNavigate(`/Company/Update/${params.id}/${COMPANY_TABS.DETAILS}`,'Update Company');
    };

    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleDateString('en-GB');
    };
    
    return (
        <Box style={{ height: 400 }}>
            <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}}>
                Company refresh
            </Typography>
            <TransitionAlert message={"NOTE:   For admin user accounts only - refresh companies with data from CH in the application"}/>
            {jobRunning == true
                ?
                <Button variant="contained" color="secondary" sx={{mt:2, mb:2}} onClick={(e) => {
                    isJobRunning.current = false;
                    setJobRunning(false);
                }}>
                       Stop Job
                    </Button>
                :
                    <Button variant="contained" color="secondary" sx={{mt:2, mb:2}} onClick={(e) => {
                        refreshAllCompanies();
                    }}>
                        Refresh All Companies
                    </Button>
            }
            <br />
            <DataGridPro
                rows={companies.slice(
                    paginationModel.page * paginationModel.pageSize,
                    paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                )}
                columns={[
                    { field: 'accountEmail', headerName: 'Account Email', flex: 1 },
                    { field: 'number', headerName: 'Company Number', flex: 1 },
                    { field: 'name', headerName: 'Company Name', flex: 1 },
                    { field: 'incorporationDate', headerName: 'Incorporation Date', flex: 1, valueFormatter: dateFormatter },
                    { field: 'clientName', headerName: 'Client', flex: 1 },
                    {
                        field: "processed",
                        headerName: "Refreshed",
                        width: 140,
                        disableClickEventBubbling: true,
                        renderCell: (params) => {
                            const refreshed = companiesProcessed.includes(params.row.id);
                            return (
                                <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                                    {refreshed && <Done />}
                                </div>
                            );
                        }
                    }
                ]}
                rowCount={paginationModel.pageSize}
                pageSize={paginationModel.pageSize}
                hideFooter={true}
                page={paginationModel.page}
                onPageChange={handlePageChange}
                loading={isLoading}
                onRowClick={handleRowClick}
                getRowClassName={() => 'rowCursorPointer'}
                autoHeight
                sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                        WebkitTextStroke: '0.75px'
                    },
                }}
            />
            <Box display="flex" justifyContent="flex-end">
                <Pagination
                    count={Math.ceil(rowCountState / paginationModel.pageSize)}
                    page={paginationModel.page + 1}
                    onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                />
            </Box>
        </Box>
    );
}
