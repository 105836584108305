import * as React from 'react';
import { useForm } from 'react-hook-form';
import {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {Box, CircularProgress, Stack} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import userService from "../users/UserService";
import UpdateUserForm from "../users/UpdateUserForm";
import inputService from "../util/InputService";
import AuthContext from "../api-authorization/AuthContext";
import {ROLES, USER_ROLES} from "../enums";
import NavigationContext from "../layout/NavigationContext";

export default function UpdateTenantUser() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { antiForgeryToken, user,refreshUser } = useContext(AuthContext);
    const {  handleSubmit, control, formState, watch } = useForm();
    const { userId} = useParams();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [busy, setBusy] = useState(true);
    const [userData, setUserData] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            const result = await userService.getUser(antiForgeryToken, userId);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setUserData(data);
                    setBusy(false);
                } else {
                    enqueueSnackbar(data.description, {variant:'error'});
                }
            }
        }
        fetchData();
    }, [])

    const handleFormSubmit  = async (formData) => {
        setBusy(true);
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        formData.userId = userData.id;
        const result = await userService.updateUser(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully updated tenant user", {variant:'success'});
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setDisableSubmit(false);
        setBusy(false);
    };


    const reInviteUser  = async () => {
        setBusy(true);
        setDisableSubmit(true);
        const result = await userService.reInviteUser(antiForgeryToken, userId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully re-invited user", {variant:'success'});
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setDisableSubmit(false);
        setBusy(false);
    };


    const sendPasswordResetLink  = async () => {
        setBusy(true);
        setDisableSubmit(true);
        const result = await userService.adminSendPasswordResetLink(antiForgeryToken, userId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully sent reset password", {variant:'success'});
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setDisableSubmit(false);
        setBusy(false);
    };

    const isDateNull = (dateParam) => {
        const date = new Date(dateParam);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return true;
        }
        return false;
    };

    let content;
    if (busy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {

        let readonly = true;
        if(user.role === ROLES.TENANT_ADMIN || user.userId == userData.id){
            readonly = false;
        }
        
        content = (
            <Box sx={{ maxWidth: 1200 }}>
                <Typography variant="h5" noWrap align={"left"} sx={{mb: 2}}>
                    User - {userData.email}
                </Typography>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <UpdateUserForm formState={formState} control={control} watch={watch} defaultValues={userData} adminRole={USER_ROLES.TENANT_ADMIN} standardRole={USER_ROLES.TENANT_STANDARD} user={user} readonly={readonly}/>
                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            back();
                        }}>
                            Back
                        </Button>
                        {!readonly && (
                            <>
                                <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                                    Update
                                </Button>
                                {isDateNull(userData.activatedAt) && (
                                    <Button variant="contained" color="secondary" onClick={reInviteUser}>
                                        Send Activation
                                    </Button>  
                                )}
                                <Button variant="contained" color="secondary" onClick={sendPasswordResetLink}>
                                    Reset Password
                                </Button>
                            </>
                        )}
                    </Stack>
                </form>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}