import * as React from 'react';
import { useForm } from 'react-hook-form';
import {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {Box, CircularProgress, Stack} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import CreateUserForm from "../users/CreateUserForm";
import AuthContext from "../api-authorization/AuthContext";
import inputService from "../util/InputService";
import {USER_ROLES} from "../enums";
import accountService from "./AccountService";
import userService from "../users/UserService";
import NavigationContext from "../layout/NavigationContext";

export default function CreateAccountUser() {
    const { histNavigate,back } = useContext(NavigationContext);
    const {  handleSubmit, control, formState, watch } = useForm();
    const [accountLoading, setAccountLoading] = useState(true);
    const [accountData, setAccountData] = useState();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const navigate = useNavigate();
    const { accountId } = useParams();
    const { antiForgeryToken, user, refreshUser} = useContext(AuthContext);


    useEffect(() => {
        async function fetchData() {
            const result = await accountService.getAccount(antiForgeryToken, accountId);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setAccountData(data);
                    setAccountLoading(false);
                } else {
                    enqueueSnackbar(data.description, {variant:'error'});
                }
            }
        }
        fetchData();
    }, [])
    
    const handleFormSubmit  = async (formData) => {
        setBusy(true);
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        formData.accountId = accountData.id;
        formData.clientId = accountData.clientId;
        const result = await userService.createUser(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                if(data.error){
                    enqueueSnackbar(data.error.description, {variant:'error'});
                }
                else{
                    enqueueSnackbar("Successfully created account user", {variant:'success'});
                }
                back();
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setDisableSubmit(false);
        setBusy(false);
    };

    let content;
    if (isBusy || accountLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <Box sx={{ maxWidth: 1200 }}>
                <Typography variant="h5" noWrap align={"left"} sx={{mb: 2}}> 
                    User - Add User to {accountData.email}
                </Typography>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <CreateUserForm formState={formState} control={control} watch={watch} adminRole={USER_ROLES.ACCOUNT_ADMIN} standardRole={USER_ROLES.ACCOUNT_STANDARD} hasActiveAdminUsers={accountData.hasActiveAdminUsers}/>
                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            back();
                        }}>
                            Back
                        </Button>
                        <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                            Submit
                        </Button>
                    </Stack>
                </form>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}