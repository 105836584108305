import React, {useContext, useState} from 'react'
import {DataGridPro } from '@mui/x-data-grid-pro';
import {Pagination, Stack} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AuthContext from "../api-authorization/AuthContext";
import NavigationContext from "../layout/NavigationContext";
import importService from "./ImportService";
import Button from "@mui/material/Button";


export default function ListCsvImportHistory() {
    const { histNavigate, back } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser } = useContext(AuthContext);
    const [isLoading, setIsLoading] = React.useState(true);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [csvImports, setCsvImports] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    const navigate = useNavigate();

    const fetchData = async () => {
        setPaginationModel((prevModel) => ({...prevModel, page: 0}));
        const result = await importService.listCsvImports(antiForgeryToken);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setCsvImports(data.csvImports);
                setRowCountState(data.csvImports.length);
                setIsLoading(false);
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
    };

    const downloadCSV = async () => {
        setDisableSubmit(true);
        const result = await importService.downloadTemplate(antiForgeryToken);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                const linkSource = `data:application/csv;base64,${data.content}`;
                const downloadLink = document.createElement("a");
                const fileName = data.name;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setDisableSubmit(false);
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };

    const handleRowClick = (params) => {
        histNavigate(`/Import/ListCsvImportJobRows/${params.id}`,'Account import details');
    };

    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleString('en-GB');
    };

    return (
        <Box sx={{height: 400}}>
            <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}} flexGrow={1}>
                Account Import History
            </Typography>
            <Stack direction="row" spacing={2} marginTop={2}>
                <Button variant="contained" color="secondary" sx={{mb: 4}} onClick={(e) => {
                    histNavigate(`/Import/CsvImport`,'CSV Import');
                }}>
                    Run Import
                </Button>
                <Button variant="contained" color="secondary" sx={{mb: 4}} disabled={disableSubmit} onClick={() => downloadCSV()}>
                    Download Template
                </Button>
            </Stack>
            <br />
            <DataGridPro
                rows={csvImports.slice(
                    paginationModel.page * paginationModel.pageSize,
                    paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                )}
                columns={[
                    { field: 'createdAt', headerName: 'Date Created', flex: 1, valueFormatter: dateFormatter },
                    { field: 'fileName', headerName: 'File', flex: 1},
                    { field: 'clientName', headerName: 'Client', flex: 1},
                    { field: 'action', headerName: 'Action', flex: 1},
                    { field: 'rowsUploaded', headerName: 'Rows Uploaded', flex: 1 },
                    { field: 'rowsSuccessful', headerName: 'Success', flex: 1 },
                    { field: 'rowsFailed', headerName: 'Failure', flex: 1 }
                ]}
                rowCount={paginationModel.pageSize}
                pageSize={paginationModel.pageSize}
                hideFooter={true}
                page={paginationModel.page}
                onPageChange={handlePageChange}
                loading={isLoading}
                onRowClick={handleRowClick}
                getRowClassName={() => 'rowCursorPointer'}
                autoHeight
                sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                        WebkitTextStroke: '0.75px'
                    },
                }}
            />
            <Box display="flex" justifyContent="flex-end">
                <Pagination
                    count={Math.ceil(rowCountState / paginationModel.pageSize)}
                    page={paginationModel.page + 1}
                    onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                />
            </Box>
        </Box>
    );
}