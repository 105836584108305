import React, {useContext, useState} from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from "@mui/material/TextField";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid, MenuItem, Stack
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {useTheme} from "@mui/material/styles";
import companyService from "./CompanyService";
import {enqueueSnackbar} from "notistack";
import Box from "@mui/material/Box";
import {ROLES} from "../enums";
import NavigationContext from "../layout/NavigationContext";
import inputService from "../util/InputService";

export default function NonUkCompanyDetails({antiForgeryToken, user, companyData, refreshUser, fetchData}) {
    const { back,histNavigate } = useContext(NavigationContext);
    const theme = useTheme();
    const { handleSubmit, control, formState, setValue } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [removeDialogOpen, setRemoveDialogOpen] = React.useState(false);
    const [sendCompanyEmailDialogOpen, setSendCompanyEmailDialogOpen] = React.useState(false);
    const [selectedEmailType, setSelectedEmailType] = React.useState(0);
    
    const [isBusy, setIsBusy] = useState(false);
    const navigate = useNavigate();

    let readonly = true;
    if(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD || user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD){
        readonly = false;
    }
    
    const handleRemoveDialogOpen = () => {
        setRemoveDialogOpen(true);
    };

    const handleRemoveDialogClose = () => {
        setRemoveDialogOpen(false);
    };

    const handleSendCompanyEmailOpen = () => {
        setSendCompanyEmailDialogOpen(true);
    };

    const handleConfStatementDialogClose = () => {
        setSendCompanyEmailDialogOpen(false);
    };

    const handleFormSubmit  = async (formData) => {
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        formData.id = companyData.id;
        const result = await companyService.updateNonUkCompany(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                fetchData();
                enqueueSnackbar("Successfully updated Non-UK company", {variant: 'success'});
            } else {
                enqueueSnackbar(data.description, {variant: 'error'});
            }
        }
        setDisableSubmit(false);
    };
    
    const sendCompanyEmail = async () => {
        setIsBusy(true);
        let sendEmailRequest = {
            companyId: companyData.id,
            emailType: selectedEmailType
        };
        const result = await companyService.sendCompanyEmail(antiForgeryToken, sendEmailRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully triggered email", {variant:'success'});
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setSendCompanyEmailDialogOpen(false);
        setIsBusy(false);
    }
    
    const removeNonUkCompany  = async () => {
        setIsBusy(true);
        const result = await companyService.removeNonUkCompanyFromAccount(antiForgeryToken, companyData.id);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully removed company from account",{variant:'success'});
                back();
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setRemoveDialogOpen(false);
        setIsBusy(false);
    };

    
    
    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Controller
                                name="Client"
                                control={control}
                                defaultValue={companyData.clientName}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="Client"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: {...theme.inputLabelProps},
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue={companyData.name}
                                disabled={true}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Company Name"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Controller
                                name="tradingNames"
                                control={control}
                                defaultValue={companyData.tradingNames}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Trading Names"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Controller
                                name="thirdPartyId"
                                control={control}
                                defaultValue={companyData.thirdPartyId}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Third Party Id"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        </Grid>
                        {(companyData.clientAllowTransactionalEmails && (user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD)) && (
                            <>
                                <Grid item xs={12} sm={12} md={6}>
                                        <Controller
                                            name="emailType"
                                            control={control}
                                            defaultValue={selectedEmailType}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Email"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        style: { ...theme.inputLabelProps },
                                                    }}
                                                    size="small"
                                                    fullWidth
                                                    margin="normal"
                                                    onChange={(e) => {
                                                        setSelectedEmailType(e.target.value);
                                                        field.onChange(e); // keep this line
                                                    }}
                                                >
                                                    <MenuItem key={0} value={0}>&nbsp;</MenuItem>
                                                    <MenuItem key={7} value={7}>Confirmation Statement Overdue</MenuItem>
                                                </TextField>
                                            )}
                                        />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Button variant="contained" color="secondary" sx={{minWidth:140,maxWidth:140,mt:2}} onClick={handleSendCompanyEmailOpen}>
                                        Send Email
                                    </Button>
                                </Grid>
                            </>
                        )}
                        </Grid>
                        <Stack direction="row" spacing={2} marginTop={4}>
                            <Button variant="contained" color="secondary" onClick={(e) => {
                                back();
                            }}>
                                Back
                            </Button>
                            <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                                Update
                            </Button>
                            <Button variant="contained" color="secondary" onClick={handleRemoveDialogOpen}>
                                Remove Non-UK Company
                            </Button>
                        </Stack>
                </form>
                <Dialog
                    open={removeDialogOpen}
                    onClose={handleRemoveDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Warning"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you wish to remove {companyData.name}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleRemoveDialogClose}>No</Button>
                        <Button onClick={removeNonUkCompany} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={sendCompanyEmailDialogOpen}
                    onClose={handleConfStatementDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Warning"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you wish to trigger an email for this company?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfStatementDialogClose}>No</Button>
                        <Button onClick={sendCompanyEmail} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return (
        <>
            {content}
        </>
    );
}