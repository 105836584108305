import React, {useContext, useEffect, useRef, useState} from "react";
import AuthContext from "../api-authorization/AuthContext";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import clientService from "../clients/ClientService";
import Box from "@mui/material/Box";
import {CircularProgress, Grid, LinearProgress, MenuItem, Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import Papa from "papaparse";
import importService from "./ImportService";
import {Controller, useForm} from "react-hook-form";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import NavigationContext from "../layout/NavigationContext";
import PropTypes from "prop-types";
import Breadcrumb from "../layout/Breadcrumb";


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};


export default function CsvImport() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { antiForgeryToken, user, refreshUser} = useContext(AuthContext);
    const theme = useTheme();
    const { handleSubmit, control, formState } = useForm();
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState(null);
    const navigate = useNavigate();
    const [csvResult, setCsvResult] = useState(null);
    const [clientData, setClientData] = useState();
    const [isBusy, setBusy] = useState(true);
    const [jobRunning, setJobRunning] = useState(false);
    const [jobId, setJobId] = useState(null);
    const [rowsToUpload, setRowsToUpload] = useState(0);
    const [rowsUploaded, setRowsUploaded] = useState(0);
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [progress, setProgress] = React.useState(0);
    const [isWaitingForCompaniesHouse, setIsWaitingForCompaniesHouse] = useState(false);
    const [cancelled, setCancelled] = useState(false);
    const cancelledRef = useRef(false);
    
    const handleFile = (file) => {
        // parse CSV file into rows
        Papa.parse(file, {
            skipEmptyLines: true,
            complete: function(results) {
                setSelectedFile(results.data);
                setSelectedFileName(file.name);
            }
        });
    };

    useEffect(() => {
        cancelledRef.current = cancelled;
    }, [cancelled]);

    useEffect(() => {
        return () => { // Cleanup function 
            setCancelled(true);
        }
    }, []);


    useEffect(() => {
        async function fetchClientData() {
            const result = await clientService.listClients(antiForgeryToken, "");
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            } else {
                let data = await result.json();
                if (result.ok) {
                    setClientData(data.clients);

                } else {
                    enqueueSnackbar(data.description, {variant:'error'});
                }
            }
            setBusy(false);
        }
        fetchClientData();
    }, [])

    const handleFormSubmit  = async (formData) => {
        setBusy(true);
        setJobRunning(true);
        let jobCreationRequest = {
            fileName: selectedFileName.toString(),
            clientId: formData.clientId,
            action: formData.action
        };
        const jobCreationResult = await importService.createImportJob(antiForgeryToken, jobCreationRequest);

        if (jobCreationResult.status === 401) {
            refreshUser();
            navigate('/login');
        } else {
            let jobCreationId = await jobCreationResult.json();
            if (jobCreationResult.ok) {
                const headers = selectedFile[0];
                setRowsToUpload(selectedFile.length - 1);
                setBusy(false);
                for (let i = 1; i < selectedFile.length; i++) {
                    if (cancelledRef.current) {
                        break;
                    }
                    const row = selectedFile[i];
                    // create an object using headers and row values
                    let rowDataObj = {};
                    for (let j = 0; j < headers.length; j++) {
                        rowDataObj[headers[j]] = row[j];
                    }

                    let payload = {
                        rowData: rowDataObj,
                        rowNumber: i,
                        clientId: formData.clientId,
                        importJobId: jobCreationId,
                        action: formData.action
                    };

                    let result;
                    let retry = false;
                    do {
                        result = await importService.postCsvImportRow(antiForgeryToken, payload);
                        if (result.status === 401) {
                            refreshUser();
                            retry = false;
                            navigate('/login');
                        }
                        else if(result.status === 429) {
                            // After 5 minutes, retry the current iteration
                            setIsWaitingForCompaniesHouse(true);
                            await new Promise(resolve => setTimeout(resolve, 5 * 60 * 1000));
                            retry = true;
                        }
                        else {
                            setIsWaitingForCompaniesHouse(false);
                            if (!result.ok) {
                                let data = await result.json();
                                enqueueSnackbar(data.description, {variant:'error'});
                            }
                            retry = false;
                        }
                    } while(retry);
                    
                    setRowsUploaded(i);
                    setProgress(((i) / (selectedFile.length - 1)) * 100);
                }
                if (!cancelledRef.current) {
                    setProgress(100);
                    setJobId(jobCreationId);
                }
            } else {
                enqueueSnackbar(jobCreationResult.description, {variant:'error'});
            }
        }
    };
    

    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    }
    else if(jobRunning){
        content = (
            <>
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                    Importing Accounts
                </Typography>
                <Box sx={{ width: '100%' }}>
                    <LinearProgressWithLabel value={progress} />
                </Box>
                <Box sx={{ maxWidth: 800 }}>
                    
                    <Typography variant="body1" align={"left"} sx={{mb: 2}}>
                        {rowsUploaded}/{rowsToUpload} processed..
                    </Typography>
                    {isWaitingForCompaniesHouse &&
                            <Typography variant="body1" align={"left"} sx={{mb: 2}}>
                                Exceeded rate limits with Companies House, waiting 5 minutes before retrying
                            </Typography>
                    }
                    {jobId ?
                        <Stack direction="row" spacing={2} marginTop={2}>
                            <Button variant="contained" color="secondary" type="submit" onClick={(e) => {
                                histNavigate(`/Import/ListCsvImportJobRows/${jobId}`, 'Account import details');
                            }}>
                                View Job Results
                            </Button>
                        </Stack>
                        :
                        <>
                            {cancelled === true ?
                                <Button variant="contained" color="secondary" disabled>
                                    Cancelled
                                </Button>
                                :
                                <Button variant="contained" color="secondary" onClick={() => setCancelled(true)}>
                                    Cancel
                                </Button>
                            }
                        </>
                    }
                </Box>
            </>
        )
    }
    else {
        let breadcrumbItems = [
            {description: `Account Import History`, url: `/Import/CsvImportHistory`}
        ];
        content = (
            <>
                <Breadcrumb breadcrumbItems={breadcrumbItems}/>
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                    Import Accounts
                </Typography>
                <Box sx={{ maxWidth: 400 }}>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    name="clientId"
                                    control={control}
                                    rules={{ required: 'Client is required' }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            label="Client *"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: { ...theme.inputLabelProps },
                                            }}
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            error={!!formState.errors.clientId}
                                            helperText={formState.errors.clientId ? formState.errors.clientId.message : ''}
                                        >
                                            {clientData.map((client) => (
                                                <MenuItem key={client.id} value={client.id}>
                                                    {client.companyName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="action"
                                    control={control}
                                    rules={{ required: 'Action is required' }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            label="Action *"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: { ...theme.inputLabelProps },
                                            }}
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            error={!!formState.errors.action}
                                            helperText={formState.errors.action ? formState.errors.action.message : ''}
                                        >
                                            <MenuItem key={0} value={null}>&nbsp;</MenuItem>
                                            <MenuItem key={1} value={1}>Create</MenuItem>
                                            <MenuItem key={2} value={2}>Update</MenuItem>
                                            <MenuItem key={3} value={3}>Remove</MenuItem>
                                            <MenuItem key={4} value={4}>Add Persons</MenuItem>
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="file"
                                    control={control}
                                    rules={{ required: 'File upload is required' }}
                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                        <Button
                                            variant="outlined"
                                            component="label"
                                        >
                                            Upload File
                                            <input
                                                type="file"
                                                hidden
                                                name={name}
                                                onBlur={onBlur}
                                                ref={ref}
                                                onChange={e => {
                                                    onChange(e.target.files[0]);
                                                    handleFile(e.target.files[0]);
                                                }}
                                            />
                                            {value && value.name}
                                        </Button>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Stack direction="row" spacing={2} marginTop={6}>
                            <Button variant="contained" color="secondary" onClick={(e) => {
                                back();
                            }}>
                                Back
                            </Button>
                            <Button variant="contained" color="secondary" type="submit">
                                Upload
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </>
        )
    }

    return (
        <>
            {content}
        </>
    );
}