import React, {useContext, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Checkbox, FormControlLabel, Grid, MenuItem, Stack} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import inputService from "../util/InputService";
import clientService from "./ClientService";
import NavigationContext from "../layout/NavigationContext";
import {FormGroup} from "reactstrap";
import {ROLES} from "../enums";

export default function UpdateClient({clientData, setClientData, antiForgeryToken, refreshUser, user, readonly}) {
    const { histNavigate,back } = useContext(NavigationContext);
    const { handleSubmit, control, formState } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const navigate = useNavigate();
    
    const handleFormSubmit  = async (formData) => {
        setDisableSubmit(true);
        formData.id = clientData.id;
        formData = inputService.trimFields(formData);
        const result = await clientService.updateClient(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setClientData(data);
                enqueueSnackbar("Successfully updated client", {variant:'success'});
                setDisableSubmit(false);
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
                setDisableSubmit(false);
            }
        }
    };
    

    return (
        <Box sx={{ maxWidth: 1200 }}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} md={6} >
                        <Controller
                            name="title"
                            control={control}
                            defaultValue={clientData.title}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    select
                                    label="Title *"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    disabled={readonly}
                                >
                                    <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                                    <MenuItem key="1" value={1}>Mr</MenuItem>
                                    <MenuItem key="2" value={2}>Mrs</MenuItem>
                                    <MenuItem key="3" value={3}>Ms</MenuItem>
                                    <MenuItem key="4" value={4}>Miss</MenuItem>
                                    <MenuItem key="5" value={5}>Dr</MenuItem>
                                </TextField>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        <Controller
                            name="status"
                            control={control}
                            defaultValue={clientData.status}
                            rules={{ required: 'Status is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    select
                                    label="Status"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    disabled={readonly}
                                    error={!!formState.errors.status}
                                    helperText={formState.errors.status ? formState.errors.status.message : ''}
                                >
                                    <MenuItem key="1" value={1}>Enabled</MenuItem>
                                    <MenuItem key="2" value={2}>Disabled</MenuItem>
                                </TextField>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <Controller
                            name="firstName"
                            control={control}
                            defaultValue={clientData.firstName}
                            rules={{ required: 'First Name is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="First Name *"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    disabled={readonly}
                                    error={!!formState.errors.firstName}
                                    helperText={formState.errors.firstName ? formState.errors.firstName.message : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <Controller
                            name="companyName"
                            control={control}
                            defaultValue={clientData.companyName}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Company Name"
                                    disabled={true}
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <Controller
                            name="lastName"
                            control={control}
                            defaultValue={clientData.lastName}
                            rules={{ required: 'Last Name is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Last Name *"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    disabled={readonly}
                                    error={!!formState.errors.lastName}
                                    helperText={formState.errors.lastName ? formState.errors.lastName.message : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        <Controller
                            name="userLogin"
                            control={control}
                            defaultValue={clientData.userLogin}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="User Login"
                                    disabled={true}
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <Controller
                            name="phoneNumber"
                            control={control}
                            defaultValue={clientData.phoneNumber}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Phone Number *"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    disabled={readonly}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        <Controller
                            name="domain"
                            control={control}
                            defaultValue={clientData.domain}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Domain"
                                    disabled={true}
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue={clientData.email}
                            rules={{
                                required: 'Email is required',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Invalid email address',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Email *"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    disabled={readonly}
                                    error={!!formState.errors.email}
                                    helperText={formState.errors.email ? formState.errors.email.message : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        {(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD) && (
                            <Controller
                                name="allowTransactionalEmails"
                                control={control}
                                defaultValue={clientData.allowTransactionalEmails}
                                render={({ field }) => (
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox {...field} checked={field.value} />}
                                            label="Allow client account users to receive email notifications"
                                        />
                                    </FormGroup>
                                )}
                            />
                        )}
                    </Grid>

                </Grid>

                <Stack direction="row" spacing={2} marginTop={2}>
                    <Button variant="contained" color="secondary" onClick={(e) => {
                        back();
                    }}>
                        Back
                    </Button>
                    {readonly === false && (
                        <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                            Update
                        </Button>
                    )}
                </Stack>
            </form>
        </Box>
    );
    
}