import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import {
    CircularProgress,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Grid,
    InputAdornment, ListSubheader,
    MenuItem,
    Paper,
    Stack, ToggleButton
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {Controller, useForm} from "react-hook-form";
import Button from "@mui/material/Button";
import {enqueueSnackbar} from "notistack";
import {useContext, useEffect, useState} from "react";
import { Document, Page } from 'react-pdf';
import CompanySearchResultsSideBar from "./companySearchResultsSideBar";
import {useNavigate, useParams} from "react-router-dom";
import {
    ArrowBackIos,
    ArrowForwardIos, PrintSharp, QuestionMark,
    ZoomIn,
    ZoomOut
} from "@mui/icons-material";
import scannedMailService from "./ScannedMailService";
import Typography from "@mui/material/Typography";
import AuthContext from "../api-authorization/AuthContext";
import companyService from "../companies/CompanyService";
import Divider from "@mui/material/Divider";
import labelService from "../labels/LabelService";
import {generateMailInformationLabelXml} from "../labels/GenerateMailInformationLabelXml";
import {MAIL_PROCESSING_ACTIONS, MAIL_PROCESSING_PAGE, MAIL_PROCESS_STATUS, MAIL_TYPE} from "../enums";
import {useTheme} from "@mui/material/styles";
import {generateMailLabelXml} from "../labels/GenerateMailLabelXml";
import Drawer from "@mui/material/Drawer";

export default function ProcessScannedMail() {
    const { antiForgeryToken, refreshUser } = useContext(AuthContext);
    const theme = useTheme();
    
    const {  handleSubmit, control, formState,trigger,setValue } = useForm();
    const { scanBatchId} = useParams();
    
    const [isBusy, setBusy] = useState(true);
    const [companySearchLoading, setCompanySearchLoading] = React.useState(false);
    const [mailItemsFound, setMailItemsFound] = useState(false);
    const [companySearchTerm, setCompanySearchTerm] = useState("");
    const [personSearchTerm, setPersonSearchTerm] = useState("");
    const [selectedCompanyId, setSelectedCompanyId] = useState("");
    const [selectedPersonName, setSelectedPersonName] = useState("");
    const [selectedMailType, setSelectedMailType] = useState(0);
    const [canSelectedCompanyCheckInMail, setCanSelectedCompanyCheckInMail] = useState(false);
    
    const [displayNoCompanySelectedError, setDisplayNoCompanySelectedError] = useState(false);
    const [unprocessedMailData, setUnprocessedMailData] = useState([])
    const [selectedRowId, setSelectedRowId] = useState("");

    const [lockedByOtherUsersCount, setLockedByOtherUsersCount] = useState(0);
    const [disableActions, setDisableActions] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);
    const [availableActions, setAvailableActions] = useState({});

    const [enableLabelPrinting, setEnableLabelPrinting] = useState(() => {
        const storedValue = sessionStorage.getItem('enableLabelPrinting');
        return storedValue === null ? false : JSON.parse(storedValue);
    });

    useEffect(() => {
        sessionStorage.setItem('enableLabelPrinting', JSON.stringify(enableLabelPrinting));
    }, [enableLabelPrinting]);

    const handleInfoDrawerOpen = () => {
        setInfoDrawerOpen(true);
    };

    const handleInfoDrawerClose = () => {
        setInfoDrawerOpen(false);
    };


    let defaultScale = 1.6;
    const [scale, setScale] = useState(defaultScale);
    const navigate = useNavigate();

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const [rtsDialogOpen, setRtsDialogOpen] = React.useState(false);
    const [unknownDialogOpen, setUnknownDialogOpen] = React.useState(false);

    const handleRtsDialogOpen = () => {
        setRtsDialogOpen(true);
    };

    const handleRtsDialogClose = () => {
        setRtsDialogOpen(false);
    };

    const handleUnknownDialogOpen = () => {
        setUnknownDialogOpen(true);
    };

    const handleUnknownDialogClose = () => {
        setUnknownDialogOpen(false);
    };



    async function fetchData() {
        setDisableActions(true);
        setBusy(true);
        const result = await scannedMailService.getUnprocessedScannedMail(antiForgeryToken,scanBatchId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                if(data.messageFound === true) {
                    setDisplayNoCompanySelectedError(false);
                    setDisableActions(false);
                    setMailItemsFound(true);
                    setUnprocessedMailData(data);
                    setPageNumber(1);
                    setCompanySearchTerm("");
                    setPersonSearchTerm("");
                    setScale(defaultScale);
                    setValue("postageFee", "");
                    setValue("handlingFee", "");


                    if(data.possibleCompanies && data.possibleCompanies.length > 0 && data.possibleCompanies[0].levenshteinDistance === 0){
                        setSelectedCompanyId(data.possibleCompanies[0].companyId);
                        setSelectedRowId(data.possibleCompanies[0].id);
                        setSelectedPersonName(data.possibleCompanies[0].personName == null ? "" : data.possibleCompanies[0].personName);
                        setCanSelectedCompanyCheckInMail(data.possibleCompanies[0].canCheckInMail);
                        if (data.possibleCompanies[0].mailForwarding === true) {
                            setValue("postageFee", data.possibleCompanies[0].clientDefaultMailPostageFee);
                            setValue("handlingFee", data.possibleCompanies[0].clientDefaultMailHandlingFee);
                        }
                        else
                        {
                            setPostageFee("");
                            setHandlingFee("");
                        }
                        setAvailableActions(data.possibleCompanies[0].availableActions);
                    }
                    else{
                        setAvailableActions({});
                        setSelectedCompanyId("");
                        setSelectedRowId("");
                        setSelectedPersonName("");
                    }
                }
                else{
                    setDisableActions(false);
                    setMailItemsFound(false);
                    setLockedByOtherUsersCount(data.lockedByOtherUsersCount);
                }
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setBusy(false);
    }
    
    useEffect(() => {
        setBusy(true);
        fetchData();
    }, [])

    const printRtsLabel = async (mailId) => {
        const result = await labelService.getRtsLabel(antiForgeryToken, mailId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            const xml = generateMailInformationLabelXml(data);
            let printers = window.dymo.label.framework.getPrinters();
            let connectedPrinter = printers.find(printer => printer.isConnected === true);
            if(!connectedPrinter){
                enqueueSnackbar("An error occured: No printers found", {variant:'error'});
                console.log(xml);
            }
            else {
                let label = window.dymo.label.framework.openLabelXml(xml);
                label.print(connectedPrinter.name);
            }

        } else {
            enqueueSnackbar(data.description, {variant:'error'});
        }
    }
    
    const rtsMail = async () => {
        const mailTypeTrigger = await trigger('mailType');

        if (!mailTypeTrigger) {
            return;
        }
        setDisableActions(true);
        let rtsMailRequest = {
            messageId: unprocessedMailData.messageId,
            companyId: selectedCompanyId ? selectedCompanyId : null,
            personName: selectedPersonName,
            mailType: selectedMailType
        }
        const result = await scannedMailService.rtsScannedMail(antiForgeryToken, rtsMailRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                if(enableLabelPrinting === true) {
                    await printRtsLabel(data);
                }
                enqueueSnackbar("Successfully marked scan as RTS", {variant:'success'});
                fetchData();
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        handleRtsDialogClose();
        setDisableActions(false);
    };

    const createUnknownScannedMail = async () => {
        setDisableActions(true);
        const result = await scannedMailService.createUnknownScannedMail(antiForgeryToken, unprocessedMailData.messageId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully marked scan as unknown", {variant:'success'});
                fetchData();
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        handleUnknownDialogClose();
        setDisableActions(false);
    };

    const submitMail  = async () => {
        const mailTypeTrigger = await trigger('mailType');

        if (!mailTypeTrigger) {
            return;
        }
        if(selectedCompanyId === ""){
            setDisplayNoCompanySelectedError(true);
            return;
        }
        if(canSelectedCompanyCheckInMail === false){
            enqueueSnackbar("Cannot submit scanned mail for this company", {variant:'error'});
            return;
        }
        setDisableActions(true);
        let submitMailRequest = {
            messageId: unprocessedMailData.messageId,
            companyId: selectedCompanyId,
            personName: selectedPersonName,
            mailType: selectedMailType
        }
        const result = await scannedMailService.submitScannedMail(antiForgeryToken, submitMailRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar(`Successfully submitted a '${data.mailType}' mail for ${data.companyName}`, {variant:'success'});
                fetchData();
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setDisableActions(false);
    };

    const checkInMail = async (data) => {
        const mailTypeTrigger = await trigger('mailType');

        if (!mailTypeTrigger) {
            return;
        }
        if(selectedCompanyId === ""){
            setDisplayNoCompanySelectedError(true);
            return;
        }
        if(canSelectedCompanyCheckInMail === false){
            enqueueSnackbar("Cannot check in scanned mail for this company", {variant:'error'});
            return;
        }
        setDisableActions(true);
        let submitMailRequest = {
            messageId: unprocessedMailData.messageId,
            companyId: selectedCompanyId,
            personName: selectedPersonName,
            mailType: selectedMailType,
            mailProcessStatus: MAIL_PROCESS_STATUS.RECEIVED,
            postageFee: data.postageFee === "" ? null : data.postageFee,
            handlingFee: data.handlingFee === "" ? null : data.handlingFee
        }
        const result = await scannedMailService.submitScannedMail(antiForgeryToken, submitMailRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar(`Successfully checked in a '${data.mailType}' mail for ${data.companyName}`, {variant:'success'});
                if(data.printInformationLabel && enableLabelPrinting === true){
                    printMailInformationLabel(data.mailId);
                }
                fetchData();
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setDisableActions(false);
    };

    const checkInAndCheckoutMail = async (data) => {
        const mailTypeTrigger = await trigger('mailType');

        if (!mailTypeTrigger) {
            return;
        }
        if(selectedCompanyId === ""){
            setDisplayNoCompanySelectedError(true);
            return;
        }
        if(canSelectedCompanyCheckInMail === false){
            enqueueSnackbar("Cannot check in and checked out scanned mail for this company", {variant:'error'});
            return;
        }
        setDisableActions(true);
        let submitMailRequest = {
            messageId: unprocessedMailData.messageId,
            companyId: selectedCompanyId,
            personName: selectedPersonName,
            mailType: selectedMailType,
            mailProcessStatus: MAIL_PROCESS_STATUS.SENT,
            postageFee: data.postageFee === "" ? null : data.postageFee,
            handlingFee: data.handlingFee === "" ? null : data.handlingFee
        }
        const result = await scannedMailService.submitScannedMail(antiForgeryToken, submitMailRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar(`Successfully checked in and checked out a '${data.mailType}' mail for ${data.companyName}`, {variant:'success'});
                if(enableLabelPrinting === true){
                    try{
                        printLabel(data.mailId);
                    }
                    catch (e) {
                        enqueueSnackbar("An error occured: No printers found", {variant:'error'});
                    }
                }
                fetchData();
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setDisableActions(false);
    };

    const printLabel = async (mailId) => {
        const result = await labelService.getMailLabel(antiForgeryToken, mailId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            const xml = generateMailLabelXml(data);
            let printers = window.dymo.label.framework.getPrinters();
            let connectedPrinter = printers.find(printer => printer.isConnected === true);
            if(!connectedPrinter){
                enqueueSnackbar("An error occured: No printers found", {variant:'error'});
                console.log(xml);
            }
            else {
                let label = window.dymo.label.framework.openLabelXml(xml);
                label.print(connectedPrinter.name);
            }

        } else {
            enqueueSnackbar(data.description, {variant:'error'});
        }
    };

    const printMailInformationLabel = async (mailId) => {
        const result = await labelService.getMailInformationLabel(antiForgeryToken, mailId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            const xml = generateMailInformationLabelXml(data);
            let printers = window.dymo.label.framework.getPrinters();
            let connectedPrinter = printers.find(printer => printer.isConnected === true);
            if(!connectedPrinter){
                enqueueSnackbar("An error occured: No printers found", {variant:'error'});
                console.log(xml);
            }
            else {
                let label = window.dymo.label.framework.openLabelXml(xml);
                label.print(connectedPrinter.name);
            }

        } else {
            enqueueSnackbar(data.description, {variant:'error'});
        }
    };
    
    
    const handleCompanySearch = async(event) => {
        event.preventDefault();
        setCompanySearchLoading(true);
        let searchRequest = {
            companySearchTerm: companySearchTerm === "" ? null : companySearchTerm,
            personSearchTerm: personSearchTerm === "" ? null : personSearchTerm
        }
        const result = await companyService.findCompaniesMailSettings(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            unprocessedMailData.possibleCompanies = data.companies
        } else {
            enqueueSnackbar(data.description, {variant:'error'});
        }
        setAvailableActions({});
        setSelectedCompanyId("");
        setSelectedRowId("");
        setSelectedPersonName("");
        setCompanySearchLoading(false);
    };
    const handlePageForward = () =>{
        if(pageNumber < numPages){
            setPageNumber(pageNumber + 1);   
        }
    }
    const handlePageBack = () =>{
        if(pageNumber > 1){
            setPageNumber(pageNumber - 1);   
        }
    }

    async function setPostageFee(postageFee){
        setValue("postageFee", formatFee(postageFee));
    }

    async function setHandlingFee(handlingFee){
        setValue("handlingFee", formatFee(handlingFee));
    }

    function formatFee(valueToFormat) {
        let value = parseFloat(valueToFormat);
        if (!isNaN(value)) {
            if (value > 999.99) {
                value = 999.99;
            }
            value = value.toFixed(2);
        } else {
            value = '';
        }
        return value;
    }

    const isActionAllowed = (mailType,action) => {
        if(!mailType || mailType === 0){
            return false;
        }
        if(availableActions && availableActions[MAIL_PROCESSING_PAGE.SCANNED_MAIL] && availableActions[MAIL_PROCESSING_PAGE.SCANNED_MAIL][mailType]){
            return availableActions[MAIL_PROCESSING_PAGE.SCANNED_MAIL][mailType].includes(action);
        }
        return false;
    }

    
    let companyResultsContent = (
        <></>
    );
    

    if(companySearchLoading){
        companyResultsContent = (
            <Box display="flex" justifyContent="center" alignItems="center" height="20vh">
                <CircularProgress />
            </Box>
        );
    }
    else {
        companyResultsContent = (
            <CompanySearchResultsSideBar 
                companies={unprocessedMailData.possibleCompanies} 
                selectedCompanyId={selectedCompanyId}
                setSelectedCompanyId={setSelectedCompanyId} 
                setDisplayNoCompanySelectedError={setDisplayNoCompanySelectedError}
                selectedRowId={selectedRowId}
                setSelectedRowId={setSelectedRowId}
                setSelectedPersonName={setSelectedPersonName}
                setPostageFee={setPostageFee}
                setHandlingFee={setHandlingFee}
                setCanSelectedCompanyCheckInMail={setCanSelectedCompanyCheckInMail}
                selectedMailType={selectedMailType}
                setAvailableActions={setAvailableActions}
            />
        );

    }
    
    
    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else if (mailItemsFound === false) {
        content = (
            <Box>
                <Typography>Batch completed</Typography>
                <br/>
                <Stack direction="row" spacing={2} marginTop={2}>
                    <Button variant="contained" color="secondary"
                            disabled={disableActions || lockedByOtherUsersCount < 1} onClick={() => navigate(`/Mail/ScannedMailBatches`)}>
                        Batch List
                    </Button>
                </Stack>
            </Box>
        );
    } else {
        content = (
            <Box sx={{ maxHeight: 800 }}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}} flexGrow={1}>
                        Batch: {unprocessedMailData.scanBatchTitle}
                    </Typography>
                    <Box>
                        <ToggleButton value="bold" aria-label="bold"     sx={{
                            mr: 4,
                            '&.Mui-selected': {
                                backgroundColor: theme.palette.primary.main,
                                color: 'white', 
                            },
                        }}
                                      selected={enableLabelPrinting}
                                      onChange={() => setEnableLabelPrinting((prevSelected) => !prevSelected)}>
                            <PrintSharp />
                        </ToggleButton>
                        <IconButton onClick={handleInfoDrawerOpen}
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: "white",
                                        '&:hover': {
                                            color: theme.palette.primary.main
                                        }
                                    }}
                        >
                            <QuestionMark/>
                        </IconButton>
                        <Drawer
                            anchor="right"
                            open={infoDrawerOpen}
                            onClose={handleInfoDrawerClose}
                        >
                            <Box
                                sx={{
                                    width: 800,
                                    height: "100%",
                                    bgcolor: theme.palette.secondary.main,
                                    color: theme.palette.text.primary,
                                    padding: 4,
                                }} // Set the width of your Drawer
                                role="presentation"
                                onClick={handleInfoDrawerClose}
                                onKeyDown={handleInfoDrawerClose}
                            >
                                <h2>Scanned Mail</h2>
                                <br/>
                                Once a search result has been selected and is highlighted in green, there are multiple functions a user can select to process the mail item.
                                <br/>
                                <br/>
                                <strong>Check-In</strong> – records a mail item against the search result. The mail image is not retained and you are unable to return to this mail item.
                                <br/>
                                <br/>
                                <strong>Express Checkout</strong> – records a mail item against the search result selected. Mail is checked-in and out immediately and a label is printed.
                                <br/>
                                <br/>
                                <strong>Next</strong> – records a mail item, simply moves to the next mail item in the queue and deletes the prior mail item image.
                                <br/>
                                <br/>
                                <strong>Unknown</strong> – records the mail item as “Unknown” and adds the mail item to the unknown batch for the current day.
                                <br/>
                                <br/>
                                <strong>RTS</strong> – this button will print an RTS label for the mail item, deletes the current mail image and moves to the next one in the queue.
                                <br/>
                                <br/>
                                <strong>Postage & Handling</strong> – Prior to selecting “Express Checkout” values entered here will be recorded against the mail item.
                                <br/>
                                <br/>
                                <Button onClick={handleInfoDrawerClose} variant="contained"
                                        color="secondary">Close</Button>
                            </Box>
                        </Drawer>
                    </Box>
                </Stack>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} md={6}>
                        <form onSubmit={handleCompanySearch}>
                            <TextField
                                id="search-bar"
                                className="text"
                                value={companySearchTerm}
                                onChange={(e) => {
                                    setCompanySearchTerm(e.target.value.toUpperCase());
                                }}
                                sx={{width: '100%'}}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton type="submit">
                                                <SearchIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                label="Company & Post Box Search"
                                variant="outlined"
                                size="small"
                            />
                            <TextField
                                id="search-bar"
                                className="text"
                                value={personSearchTerm}
                                onChange={(e) => {
                                    setPersonSearchTerm(e.target.value.toUpperCase());
                                }}
                                sx={{mt: 2, mb: 2, width: '100%'}}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton type="submit">
                                                <SearchIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                label="People Search"
                                variant="outlined"
                                size="small"
                            />
                        </form>
                        <Paper style={{overflow: 'auto', padding: '20px', maxHeight: 750, minHeight: 750}}
                               variant="outlined">
                            <Stack
                                direction="row"
                                justifyContent="flex-end" // Here is the applied style
                                style={{position: 'sticky', top: 2, zIndex: 1}}
                                spacing={2}
                            >
                                <Button variant="contained" color="secondary" disabled={scale <= 0.5} onClick={() => setScale(oldScale => Math.max(oldScale - 0.3, 0.3))}>
                                    <ZoomOut sx={{fontSize: 22}}/>
                                </Button>
                                <Button variant="contained" color="secondary" disabled={scale >= 2} key="zoomIn" onClick={() => setScale(oldScale => oldScale + 0.3)}>
                                    <ZoomIn sx={{fontSize: 22}}/>
                                </Button>
                            </Stack>

                            <Document
                                file={`data:application/pdf;base64,${unprocessedMailData.content}`}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onLoadError={console.error}
                            >
                                <Page scale={scale} pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false}/>
                            </Document>
                            
                        </Paper>
                        <div style={{color: "gray"}}>
                            <center>
                                <IconButton onClick={handlePageBack}>
                                    <ArrowBackIos/>
                                </IconButton>
                                Page {pageNumber} of {numPages}
                                <IconButton onClick={handlePageForward}>
                                    <ArrowForwardIos/>
                                </IconButton>
                            </center>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>

                        <Grid container spacing={10}>
                            <Grid item xs={7} sm={7} md={7}>
                                <Stack direction="row" spacing={2}>
                                        <Controller
                                            name="mailType"
                                            control={control}
                                            defaultValue={selectedMailType}
                                            rules={{required: 'Mail type is required'}}
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Mail Type *"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth={true}
                                                    size="small"
                                                    error={!!formState.errors.mailType}
                                                    helperText={formState.errors.mailType ? formState.errors.mailType.message : ''}
                                                    onChange={(e) => {field.onChange(e); setSelectedMailType(e.target.value)}}
                                                >
                                                    <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                                                    <ListSubheader>Statutory Mail</ListSubheader>
                                                    <MenuItem key={MAIL_TYPE.COMPANIES_HOUSE.toString()} value={MAIL_TYPE.COMPANIES_HOUSE}>Companies House</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.HMRC.toString()} value={MAIL_TYPE.HMRC}>HMRC</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.THE_PENSION_REGULATOR.toString()} value={MAIL_TYPE.THE_PENSION_REGULATOR}>The Pension Regulator</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.INFORMATION_COMMISSIONERS_OFFICE.toString()} value={MAIL_TYPE.INFORMATION_COMMISSIONERS_OFFICE}>Information Commissioners Office</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.OFFICE_OF_NATIONAL_STATISTICS.toString()} value={MAIL_TYPE.OFFICE_OF_NATIONAL_STATISTICS}>Office of National Statistics</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.DVLA.toString()} value={MAIL_TYPE.DVLA}>DVLA</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.OTHER_STATUTORY_MAIL.toString()} value={MAIL_TYPE.OTHER_STATUTORY_MAIL}>Other - Statutory Mail</MenuItem>
                                                    <ListSubheader>Non-Statutory Mail</ListSubheader>
                                                    <MenuItem key={MAIL_TYPE.ROYAL_MAIL_LETTER.toString()} value={MAIL_TYPE.ROYAL_MAIL_LETTER}>Royal Mail - Letter</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.ROYAL_MAIL_LARGE_LETTER.toString()} value={MAIL_TYPE.ROYAL_MAIL_LARGE_LETTER}>Royal Mail - Large Letter</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.OTHER_SPECIAL_DELIVERY.toString()} value={MAIL_TYPE.OTHER_SPECIAL_DELIVERY}>Other - Special Delivery</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.PRINTED_MATTER.toString()} value={MAIL_TYPE.PRINTED_MATTER}>Printed Matter</MenuItem>
                                                </TextField>
                                            )}
                                        />
                                </Stack>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5}>
                                <Stack direction="row" spacing={2} justifyContent="flex-end">
                                    <Button style={{height: "80%"}} variant="contained" color="secondary"
                                            disabled={disableActions || !isActionAllowed(selectedMailType, MAIL_PROCESSING_ACTIONS.NEXT)} onClick={submitMail}>
                                        Next
                                    </Button>
                                    <Button style={{height: "80%"}} variant="contained" color="secondary"
                                            disabled={disableActions} onClick={handleUnknownDialogOpen}>
                                        Unknown
                                    </Button>
                                    <Button variant="contained" color="secondary"
                                            disabled={disableActions || !isActionAllowed(selectedMailType, MAIL_PROCESSING_ACTIONS.RTS)} onClick={handleRtsDialogOpen}
                                            sx={{ml:2}}>
                                        RTS
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Divider sx={{mt: 2.5, mb: 2.5, borderBottomWidth: 1}}/>
                        <Grid container spacing={10}>
                            <Grid item xs={12} sm={6} md={6}>
                                <Stack direction="row" spacing={2}>
                                    <Controller
                                        name="postageFee"
                                        control={control}
                                        defaultValue=""
                                        render={({field, fieldState: {error}}) => (
                                            <TextField
                                                {...field}
                                                onBlur={(e) => {
                                                    let value = parseFloat(e.target.value);
                                                    value = formatFee(value);
                                                    field.onBlur();
                                                    field.onChange(value);
                                                }}
                                                label="Postage"
                                                type="number"
                                                variant="outlined"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                }}
                                                fullWidth={true}
                                                InputLabelProps={{
                                                    style: {...theme.inputLabelProps},
                                                }}
                                                margin="normal"
                                                size="small"
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="handlingFee"
                                        control={control}
                                        defaultValue=""
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                onBlur={(e) => {
                                                    let value = parseFloat(e.target.value);
                                                    value = formatFee(value);
                                                    field.onBlur();
                                                    field.onChange(value);
                                                }}
                                                label="Handling"
                                                type="number"
                                                variant="outlined"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                }}
                                                fullWidth={true}
                                                InputLabelProps={{
                                                    style: {...theme.inputLabelProps},
                                                }}
                                                margin="normal"
                                                size="small"
                                            />
                                        )}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Stack direction="row" spacing={2} justifyContent="flex-end">
                                    <Button variant="contained"
                                            color="secondary"
                                            onClick={handleSubmit(checkInAndCheckoutMail)}
                                            disabled={disableActions || !isActionAllowed(selectedMailType, MAIL_PROCESSING_ACTIONS.EXPRESS_CHECKOUT)}
                                            sx={{ml:2}}>
                                        Express checkout
                                    </Button>
                                    <Button variant="contained" color="secondary"
                                            disabled={disableActions || !isActionAllowed(selectedMailType, MAIL_PROCESSING_ACTIONS.CHECK_IN)} onClick={handleSubmit(checkInMail)}>
                                        Check-In
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Typography sx={{
                            fontStyle: "italic",
                            pt: 1
                        }}>{unprocessedMailData.scannedMailCount === 1 ? `${unprocessedMailData.scannedMailCount} item remaining` : `${unprocessedMailData.scannedMailCount} items remaining`}</Typography>
                        <Divider sx={{mt: 2.5, mb: 2.5, borderBottomWidth: 1}}/>
                        <span id={"noCompanySelectedErrorMessage"} style={{
                            display: displayNoCompanySelectedError === true ? 'block' : 'none',
                            color: "#d32f2f",
                            fontWeight: 400,
                            fontSize: "0.75rem",
                            lineHeight: 1.66,
                            textAlign: "left",
                            marginTop: "4px",
                            marginRight: "14px",
                            marginBottom: 0,
                            marginLeft: "14px"
                        }}>Please select a company</span>
                        <Paper sx={{
                            maxHeight: 790,
                            minHeight: 790,
                            overflow: 'auto',
                            border: displayNoCompanySelectedError === true ? '1px solid #D32F2F' : ''
                        }} variant="outlined">
                            {companyResultsContent}
                        </Paper>
                    </Grid>
                </Grid>

                <Dialog
                    open={rtsDialogOpen}
                    onClose={handleRtsDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Warning"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you wish to RTS this mail item?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleRtsDialogClose}>No</Button>
                        <Button onClick={(e) => {
                            e.preventDefault();
                            rtsMail();
                        }} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={unknownDialogOpen}
                    onClose={handleUnknownDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Warning"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you wish to mark this mail item as unknown?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleUnknownDialogClose}>No</Button>
                        <Button onClick={(e) => {
                            e.preventDefault();
                            createUnknownScannedMail();
                        }} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );

}