import React, {useContext, useState} from "react";
import AuthContext from "../../../api-authorization/AuthContext";
import Box from "@mui/material/Box";
import {CircularProgress, Stack} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {
    ADD_NON_UK_COMPANY_SCREEN_MODE,
    RESOURCE_TYPE,
    ROLES
} from "../../../enums";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddCompanySelect from "../AddCompanySelect";
import {useForm} from "react-hook-form";
import inputService from "../../../util/InputService";
import companyService from "../../../companies/CompanyService";
import NavigationContext from "../../../layout/NavigationContext";
import AddNonUkCompanyDetails from "./AddNonUkCompanyDetails";
import AddCompanyOptionalPrimaryContact from "./AddCompanyOptionalPrimaryContact";

export default function AddNonUkCompanyParent({handleNext, handleExitDialogOpen,companyTypeToAdd, setCompanyTypeToAdd, accountData, setCompanyData,setResourceType}) {
    const { histNavigate } = useContext(NavigationContext);
    const {  handleSubmit, control, formState,setValue,trigger } = useForm();
    const {antiForgeryToken, user, refreshUser} = useContext(AuthContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const [screenMode, setScreenMode] = React.useState(ADD_NON_UK_COMPANY_SCREEN_MODE.DETAILS);
    const [isComplianceApproved, setIsComplianceApproved] = useState(true);
    const [isAddressApproved, setIsAddressApproved] = useState(false);
    const [isDisablePrimaryContactChecked, setIsDisablePrimaryContactChecked] = useState(false);
    const [isPrimaryContactOptedOut, setIsPrimaryContactOptedOut] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [companyName, setCompanyName] = useState("");

    const navigate = useNavigate();

    let content;


    const handleFormSubmit  = async (primaryContactData) => {
        setDisableSubmit(true);
        let request = {};
        request.accountId = accountData.id;
        primaryContactData = inputService.trimFields(primaryContactData);
        if(!isPrimaryContactOptedOut){
            request.primaryContact = primaryContactData;
        }
        request.name = primaryContactData.name;
        request.tradingNames = primaryContactData.tradingNames;
        request.thirdPartyId = primaryContactData.thirdPartyId;
        const result = await companyService.addNonUkCompanyToAccount(antiForgeryToken, request);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully added company to account");
                if(user.role == ROLES.ACCOUNT_ADMIN){
                    refreshUser();
                }
                let getCompanyRequest = {
                    id: data
                };
                const companyDataResult = await companyService.getCompany(antiForgeryToken, getCompanyRequest);
                let companyDataResultJson = await companyDataResult.json();
                if (companyDataResult.ok) {
                    await setResourceType(RESOURCE_TYPE.NON_UK_COMPANY);
                    await setCompanyData(companyDataResultJson);
                    handleNext();
                } else {
                    enqueueSnackbar(companyDataResultJson.description, {variant:'error'});
                }
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setDisableSubmit(false);
    };

    const handleCompanyNext = async () =>{
        const result = await trigger();  
        if (result) { 
            setScreenMode(ADD_NON_UK_COMPANY_SCREEN_MODE.ADD_PRIMARY_CONTACT);
        }
    }


    if (isLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <>
                {screenMode === ADD_NON_UK_COMPANY_SCREEN_MODE.DETAILS && (
                    <>
                        <AddCompanySelect companyTypeToAdd={companyTypeToAdd} setCompanyTypeToAdd={setCompanyTypeToAdd}/>
                        <AddNonUkCompanyDetails setCompanyName={setCompanyName} formState={formState} control={control} setCompanyName={setCompanyName}/>
                        <Stack direction="row" spacing={2} marginTop={2}>
                            <Button variant="contained" color="secondary" type="button" onClick={(e) => {
                                handleExitDialogOpen()
                            }}>
                                Exit
                            </Button>
                            <Button variant="contained" color="secondary" onClick={(e) => {
                                handleCompanyNext();
                            }}>
                                Next
                            </Button>
                        </Stack>
                    </>
                )}
                {screenMode === ADD_NON_UK_COMPANY_SCREEN_MODE.ADD_PRIMARY_CONTACT && (
                    <>
                        <form onSubmit={handleSubmit(handleFormSubmit)}>
                            <Typography variant="h5" noWrap component="div" align={"left"} sx={{mt: 2, mb: 4}}>
                                Add Primary Contact information for - {companyName}
                            </Typography>
                            <AddCompanyOptionalPrimaryContact setValue={setValue} formState={formState} control={control}
                                                              setIsComplianceApproved={setIsComplianceApproved}
                                                              setIsAddressApproved={setIsAddressApproved} accountData={accountData}
                                                              isDisablePrimaryContactChecked={isDisablePrimaryContactChecked}
                                                              setIsDisablePrimaryContactChecked={setIsDisablePrimaryContactChecked}
                                                              isPrimaryContactOptedOut={isPrimaryContactOptedOut}
                                                              setIsPrimaryContactOptedOut={setIsPrimaryContactOptedOut}
                                                      companyType={companyTypeToAdd}/>
                            <Stack direction="row" spacing={2} marginTop={2}>
                                <Button variant="contained" color="secondary" onClick={(e) => {
                                    setScreenMode(ADD_NON_UK_COMPANY_SCREEN_MODE.DETAILS);
                                }}>
                                    Back
                                </Button>
                                <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit || (isComplianceApproved === false || isAddressApproved === false && isPrimaryContactOptedOut === false)}>
                                    Next
                                </Button>
                            </Stack>
                        </form>
                    </>
                )}
            </>
        );
    }

    return content;
}