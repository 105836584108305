import React, {useContext} from 'react';
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {Pagination, Stack} from "@mui/material";
import billingService from "./BillingService";
import Button from "@mui/material/Button";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro} from "@mui/x-data-grid-pro";

export default function ListPaymentHistory({antiForgeryToken, resourceType, resourceId, refreshUser}) {
    const { histNavigate,back } = useContext(NavigationContext);
    const [isLoading, setIsLoading] = React.useState(true);
    const [paymentHistory, setPaymentHistory] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });

    const fetchData = async () => {
        setPaginationModel((prevModel) => ({...prevModel, page: 0}));
        const result = await billingService.listPaymentHistory(antiForgeryToken, resourceType, resourceId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            setPaymentHistory(data.invoices);
            setRowCountState(data.invoices.length);
            setIsLoading(false);
        } else {
            enqueueSnackbar(data.description, {variant:'error'});
        }
    };


    React.useEffect(() => {
        fetchData();
    }, []);

    const handleRowClick = (params) => {
        window.open(params.row.receiptPdf, '_blank');
    };

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };

    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleDateString('en-GB');
    };


    const navigate = useNavigate();

    return (
        <Box style={{ height: 400, width: '100%' }}>
            <DataGridPro
                rows={paymentHistory.slice(
                    paginationModel.page * paginationModel.pageSize,
                    paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                )}
                columns={[
                    { field: 'dateCreated', headerName: 'Date', flex: 1, valueFormatter: dateFormatter },
                    { field: 'description', headerName: 'Description', flex: 2},
                    { field: 'amountDue', headerName: 'Amount', flex: 1},
                    {field: 'cardUsed', headerName:'Payment Method', flex:1},
                    {field: 'status', headerName:'Status', flex:1}
                ]}
                rowCount={rowCountState}
                pagination
                pageSize={paginationModel.pageSize}
                page={paginationModel.page}
                onPageChange={handlePageChange}
                onRowClick={handleRowClick}
                getRowClassName={() => 'rowCursorPointer'}
                hideFooter={true}
                loading={isLoading}
                autoHeight
                sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                        WebkitTextStroke: "0.75px"
                    }
                }}
            />
            <Box display="flex" justifyContent="flex-end">
                <Pagination
                    count={Math.ceil(rowCountState / paginationModel.pageSize)}
                    page={paginationModel.page + 1}
                    onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                />
            </Box>
            <Stack direction="row" spacing={2} marginTop={2}>
                <Button variant="contained" color="secondary" onClick={(e) => {
                    back();
                }}>
                    Back
                </Button>
            </Stack>
        </Box>
    );
}