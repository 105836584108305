import React, {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Checkbox, CircularProgress, FormControlLabel, Grid, MenuItem, Stack} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import inputService from "../util/InputService";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import personService from "../companies/primaryContact/PersonService";
import {FormGroup} from "reactstrap";
import companyService from "../companies/CompanyService";

export default function CompanyDetailsConfirmation({accountData,companyId, antiForgeryToken, refreshUser, progressTab}) {
    const theme = useTheme();
    const { handleSubmit, control, formState } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [primaryContactData, setPrimaryContactData] = useState();
    const [primaryContactLoading, setPrimaryContactLoading] = useState(true);
    const [isBusy, setBusy] = useState(false);
    const navigate = useNavigate();
    const [isComplianceApproved, setIsComplianceApproved] = useState(false);
    const [isAddressApproved, setIsAddressApproved] = useState(false);

    useEffect( () => {
        getPrimaryContactData();
    }, [companyId])
    
    async function getPrimaryContactData() {
        const result = await personService.listForCompany(antiForgeryToken, companyId, "");
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                for (let person of data.persons) {
                    if(person.isPrimaryContact === true){
                        const pcResult = await personService.getPerson(antiForgeryToken, person.id);
                        let pcData = await pcResult.json();
                        if (pcResult.ok) {
                            setPrimaryContactData(pcData);
                            setPrimaryContactLoading(false);
                        } else {
                            enqueueSnackbar(pcData.description);
                        }
                    }
                }
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
    }

    const primaryContactDetailsChanged = (formData) => {
        if(formData.title !== primaryContactData.title ||
            formData.phoneNumber !== primaryContactData.phoneNumber ||
            formData.firstName !== primaryContactData.firstName ||
            formData.mobileNumber !== primaryContactData.mobileNumber ||
            formData.lastName !== primaryContactData.lastName ||
            formData.email !== primaryContactData.email ||
            formData.businessRole !== primaryContactData.businessRole ||
            formData.address.addressLine1 !== primaryContactData.address.addressLine1 ||
            formData.address.addressLine2 !== primaryContactData.address.addressLine2 ||
            formData.address.locality !== primaryContactData.address.locality ||
            formData.address.country !== primaryContactData.address.country ||
            formData.address.postalCode !== primaryContactData.address.postalCode ||
            formData.marketingOptOut !== primaryContactData.marketingOptOut){
            return true;
        }
        return false;
    }
    
    const addressChanged = (formData) => {
        if (formData.address.addressLine1 !== primaryContactData.address.addressLine1 ||
            formData.address.addressLine2 !== primaryContactData.address.addressLine2 ||
            formData.address.locality !== primaryContactData.address.locality ||
            formData.address.country !== primaryContactData.address.country ||
            formData.address.postalCode !== primaryContactData.address.postalCode) {
            return true;
        }
        return false;
    }
    
    
    
    
    const handleFormSubmit  = async (formData) => {
        setBusy(true);
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        formData.id = primaryContactData.id;
        formData.address.id = primaryContactData.address.id;
        formData.isPrimaryContact = true;
        if(primaryContactDetailsChanged(formData)){
            const updatePersonResult = await personService.updatePerson(antiForgeryToken, formData);
            if (updatePersonResult.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let updatePersonData = await updatePersonResult.json();
                if (updatePersonResult.ok) {
                    enqueueSnackbar("Successfully updated company contact", {variant:'success'});
                } else {
                    enqueueSnackbar(updatePersonData.description, {variant:'error'});
                }
                progressTab();
            }
        }
        else{
            progressTab();
        }
        
        setDisableSubmit(false);
        setBusy(false);
        
    };


    let content;
    if (isBusy || primaryContactLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <Box sx={{ maxWidth: 1200 }}>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Typography sx={{mt:1,mb:1,fontSize:"1.2rem",fontWeight:950}}>Primary contact</Typography>
                    <Typography sx={{mt:1,mb:2,fontSize:"1rem",fontWeight:750}}>Please make sure your contact information is correct.</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="title"
                                control={control}
                                defaultValue={primaryContactData.title}
                                rules={{ required: 'Title is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        select
                                        label="Title"
                                        variant="outlined"
                                        margin="normal"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.title}
                                        helperText={formState.errors.title ? formState.errors.title.message : ''}
                                    >
                                        <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                                        <MenuItem key="1" value={1}>Mr</MenuItem>
                                        <MenuItem key="2" value={2}>Mrs</MenuItem>
                                        <MenuItem key="3" value={3}>Ms</MenuItem>
                                        <MenuItem key="4" value={4}>Miss</MenuItem>
                                        <MenuItem key="5" value={5}>Dr</MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="phoneNumber"
                                control={control}
                                defaultValue={primaryContactData.phoneNumber}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Phone"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="firstName"
                                control={control}
                                defaultValue={primaryContactData.firstName}
                                rules={{ required: 'First name is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="First Name *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.firstName}
                                        helperText={formState.errors.firstName ? formState.errors.firstName.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="mobileNumber"
                                control={control}
                                defaultValue={primaryContactData.mobileNumber}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Mobile"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.mobileNumber}
                                        helperText={formState.errors.mobileNumber ? formState.errors.mobileNumber.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="lastName"
                                control={control}
                                defaultValue={primaryContactData.lastName}
                                rules={{ required: 'Last name is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Last Name *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.lastName}
                                        helperText={formState.errors.lastName ? formState.errors.lastName.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={primaryContactData.email}
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email address',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Email *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.email}
                                        helperText={formState.errors.email ? formState.errors.email.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="businessRole"
                                control={control}
                                defaultValue={primaryContactData.businessRole}
                                rules={{ required: 'Business Role is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Business Role *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.businessRole}
                                        helperText={formState.errors.businessRole ? formState.errors.businessRole.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Typography sx={{mt:3,mb:1,fontSize:"1.2rem",fontWeight:950}}>Mail Forwarding Address</Typography>
                    <Typography sx={{mt:1,mb:2,fontSize:"1rem",fontWeight:750}}>This address is not the registered office address and will be used to forward company mail to.</Typography>
                    
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="address.addressLine1"
                                control={control}
                                defaultValue={primaryContactData.address.addressLine1}
                                rules={{ required: 'Address Line 1 is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Address Line 1 *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.address?.addressLine1}
                                        helperText={formState.errors.address?.addressLine1 ? formState.errors.address.addressLine1.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="address.addressLine2"
                                control={control}
                                defaultValue={primaryContactData.address.addressLine2}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Address Line 2"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="address.locality"
                                control={control}
                                defaultValue={primaryContactData.address.locality}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Town / City"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.address?.locality}
                                        helperText={formState.errors.address?.locality ? formState.errors.address.locality.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="address.country"
                                control={control}
                                defaultValue={primaryContactData.address.country}
                                rules={{ required: 'Country is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Country *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.address?.country}
                                        helperText={formState.errors.address?.country ? formState.errors.address.country.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="address.postalCode"
                                control={control}
                                defaultValue={primaryContactData.address.postalCode}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Post Code"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Controller
                        name="addressApproved"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox {...field} checked={field.value}
                                                       onChange={(e) => {
                                                           // Update react-hook-form's form state
                                                           field.onChange(e.target.checked);

                                                           // Update local state - You might not need this anymore
                                                           setIsAddressApproved(e.target.checked);
                                                       }}
                                    />}
                                    label={`I agree to use this address as my mail forwarding address for the company and its directors.`}
                                />
                            </FormGroup>
                        )}
                    />
                    <Controller
                        name="complianceApproved"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox {...field} checked={field.value}
                                                       onChange={(e) => {
                                                           // Update react-hook-form's form state
                                                           field.onChange(e.target.checked);

                                                           // Update local state - You might not need this anymore
                                                           setIsComplianceApproved(e.target.checked);
                                                       }}
                                    />}
                                    label={`I, ${accountData.firstName} ${accountData.lastName}, confirm in both this Compliance Statement and the names and addresses in the above 
                                    Primary Stakeholder information, that I have the authority to act for and on behalf of this company and will comply with the Companies Act 2006.`}
                                />
                            </FormGroup>
                        )}
                    />

                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit || !isComplianceApproved || !isAddressApproved}>
                            Save & Continue
                        </Button>
                    </Stack>
                </form>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}