import React, {useContext, useState} from 'react';
import {useStripe, useElements, PaymentElement, AddressElement} from '@stripe/react-stripe-js';
import Button from "@mui/material/Button";
import {enqueueSnackbar} from "notistack";
import Typography from "@mui/material/Typography";
import {CircularProgress, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import NavigationContext from "../layout/NavigationContext";
import billingService from "./BillingService";
import AuthContext from "../api-authorization/AuthContext";
import {BILLING_TABS, RESOURCE_TYPE} from "../enums";

const SetupForm = ({resourceType, resourceId, refreshPaymentMethods}) => {
    const { histNavigate,history,back } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser} = useContext(AuthContext);
    const [isPaymentElementReady, setIsPaymentElementReady] = useState(false);
    const [isAddressElementReady, setIsAddressElementReady] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [disableSubmit, setDisableSubmit] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);
    

    const handlePaymentElementReady = () => {
        setIsPaymentElementReady(true);
    };

    const handleAddressElementReady = () => {
        setIsAddressElementReady(true);
    };
    
    const handleSubmit = async (event) => {
        setDisableSubmit(true);
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return null;
        }

        localStorage.setItem("navigationHistory", JSON.stringify(history));

        const { setupIntent, error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: `${window.location.protocol}//${window.location.host}/Billing/FinishCardSetup/${resourceType}/${resourceId}`, // For redirect-based flows
            },
            redirect: 'if_required',
        });

        if (error) {
            enqueueSnackbar("Error adding card " + error.message);
            setDisableSubmit(false);
        } else if (setupIntent && setupIntent.status === 'succeeded') {
            // Handle successful card setup
            enqueueSnackbar("Successfully added card", {variant:'success'});
            const result = await billingService.finishCardSetUp(antiForgeryToken, resourceType, resourceId, setupIntent.id);
            let data = await result.json();
            if (result.ok) {
                if(refreshPaymentMethods == null){
                    histNavigate(`/Billing/Update/${RESOURCE_TYPE.ACCOUNT}/${resourceId}/${BILLING_TABS.PAYMENT_METHOD}`,'Billing'); 
                }
                else{
                    refreshPaymentMethods();
                }
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        } else {
            // Handle cases where further action is required (e.g., redirection)
            enqueueSnackbar("Please complete card setup via the provided link.", { variant: 'info' });
        }
    };
    
    return (
            <form onSubmit={handleSubmit}>
                {(!isPaymentElementReady || !isAddressElementReady) &&             
                    <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                    <CircularProgress />
                </Box>}
                
                <Typography variant="h6" noWrap component="div" align={"left"} sx={{mb:2}}>
                    Payment card
                </Typography>
                <PaymentElement onReady={handlePaymentElementReady} />
                <Typography variant="h6" noWrap component="div" align={"left"} sx={{mb:1, mt:2}}>
                    Billing Address
                </Typography>
                <Typography noWrap component="div" align={"left"} sx={{mb:2, WebkitTextStroke: "0.4px", fontSize:"0.8rem",}}>
                    This is the billing address associated to the payment method
                </Typography>
                <AddressElement options={{mode: 'shipping', allowedCountries: ['GBR']}} onReady={handleAddressElementReady} />

                <br />
                <Stack direction="row" spacing={2} marginTop={2}>
                    <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                        Submit
                    </Button>
                </Stack>
            </form>
    );
}

export default SetupForm;