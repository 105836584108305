import {Box, Stack} from "@mui/material";
import * as React from "react";
import {useContext, useState} from "react";
import Button from "@mui/material/Button";
import {COMPANY_TYPE} from "../../enums";
import AddUKCompanyParent from "./ukCompany/AddUkCompanyParent";
import AddCompanySelect from "./AddCompanySelect";
import AddPostBoxParent from "./postBox/AddPostBoxParent";
import AddNonUkCompanyParent from "./nonUkCompany/AddNonUkCompanyParent";

export default function AddCompanyParent({handleNext,handleExitDialogOpen,accountData,setCompanyData, setResourceType, companyType}) {
    const [companyTypeToAdd, setCompanyTypeToAdd] = useState(companyType  ? companyType : 0);
    
    let content = (
            <Box sx={{ maxWidth: "100%" }}>
                {companyTypeToAdd === 0 && (
                    <AddCompanySelect companyTypeToAdd={companyTypeToAdd} setCompanyTypeToAdd={setCompanyTypeToAdd} accountData={accountData}/>
                )}
                {companyTypeToAdd === COMPANY_TYPE.COMPANY && (
                    <AddUKCompanyParent handleNext={handleNext} handleExitDialogOpen={handleExitDialogOpen} companyTypeToAdd={companyTypeToAdd} setCompanyTypeToAdd={setCompanyTypeToAdd} accountData={accountData} setCompanyData={setCompanyData} setResourceType={setResourceType}/>
                )}
                {companyTypeToAdd === COMPANY_TYPE.POST_BOX && (
                    <AddPostBoxParent handleNext={handleNext} handleExitDialogOpen={handleExitDialogOpen}  companyTypeToAdd={companyTypeToAdd} setCompanyTypeToAdd={setCompanyTypeToAdd} accountData={accountData} setCompanyData={setCompanyData} setResourceType={setResourceType}/>
                )}
                {companyTypeToAdd === COMPANY_TYPE.NON_UK_COMPANY && (
                    <AddNonUkCompanyParent handleNext={handleNext} handleExitDialogOpen={handleExitDialogOpen}  companyTypeToAdd={companyTypeToAdd} setCompanyTypeToAdd={setCompanyTypeToAdd} accountData={accountData} setCompanyData={setCompanyData} setResourceType={setResourceType}/>
                )}
                {companyTypeToAdd === 0 && (
                    <Stack direction="row" spacing={2} marginTop={10}>
                        <Button variant="contained" color="secondary" type="button" onClick={(e) => {
                            handleExitDialogOpen();
                        }}>
                            Exit
                        </Button>
                    </Stack>
                )}
            </Box>
        );
    
    
    return content;
}