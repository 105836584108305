import React, {useContext, useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import {
    Typography, Grid, CircularProgress, Stack, CardActionArea, Card, Paper
} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import billingService from "../../billing/BillingService";
import productService from "../../products/ProductService";
import {TAX_RATE} from "../../enums";
import NavigationContext from "../../layout/NavigationContext";
import AddAccountBillingParent from "./AddAccountBillingParent";

let CHECKOUT_SCREEN_MODE = {
    PURCHASE_NOW: 1,
    ADD_PAYMENT_METHOD: 2,
    LIST_PAYMENT_METHODS: 3,
};

export default function Checkout({ antiForgeryToken, setShowCheckout, resourceType, resourceData, products, cart, refreshUser, user, redirectUrl,postPurchaseAction }) {
    const { histNavigate, back } = useContext(NavigationContext);
    const [paymentMethods, setPaymentMethods] = useState();
    const [isBusy, setIsBusy] = useState(true);
    const navigate = useNavigate();
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = React.useState("");
    const [selectedScreenMode, setSelectedScreenMode] = React.useState(CHECKOUT_SCREEN_MODE.PURCHASE_NOW);
    const [cartItems, setCartItems] = useState([]);
    const [subTotal, setSubTotal] = useState(0);
    const [vatTotal, setVatTotal] = useState(0);
    const [total, setTotal] = useState(0);

    const handlePaymentMethodClick = (id) => {
        setSelectedPaymentMethodId(id);
    };

    let UKPound = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
    });
    
    async function initializeCheckout(){
        const paymentMethodResult = await billingService.listPaymentMethods(antiForgeryToken, resourceType, resourceData.id);
        if (paymentMethodResult.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else{
            let cardData = await paymentMethodResult.json();
            if(paymentMethodResult.ok){
                await setPaymentMethods(cardData.cards);
                const defaultCard = cardData.cards.find(card => card.default);
                if(defaultCard){
                    await setSelectedPaymentMethodId(defaultCard.id);
                }
                let subTotalTmp = 0;
                let vatTotalTmp = 0;
                let cartItems = products.map(product => {
                    if(cart.includes(product.id)) {
                        if(product.freeTrialDays == null) {
                            subTotalTmp += product.price;
                            vatTotalTmp += (product.price * TAX_RATE.UK);
                        }
                        return (
                            <Box key={product.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography sx={{mt: 2, mb: 2}} variant="basketItem">
                                    {product.name}
                                </Typography>
                                <Typography sx={{mt: 2, mb: 2}} variant="basketItem" style={{ textAlign: 'right' }}>
                                    {UKPound.format(product.freeTrialDays != null && product.freeTrialDays > 0 ? 0 : product.price)}
                                </Typography>
                            </Box>
                        )
                    } else {
                        return null;
                    }
                });
                await setSubTotal(subTotalTmp);
                await setVatTotal(vatTotalTmp);
                await setTotal(subTotalTmp + vatTotalTmp);
                await setCartItems(cartItems);
                if(!resourceData.clientBillingEnabled || subTotalTmp === 0) {
                    await setSelectedScreenMode(CHECKOUT_SCREEN_MODE.PURCHASE_NOW);
                }
                else if(cardData.cards.length > 0) {
                    await setSelectedScreenMode(CHECKOUT_SCREEN_MODE.LIST_PAYMENT_METHODS);
                }
                else {
                    await setSelectedScreenMode(CHECKOUT_SCREEN_MODE.ADD_PAYMENT_METHOD);
                }
                setIsBusy(false);
            }
            else{
                enqueueSnackbar(cardData.description);
            }
        }
    }

    useEffect(() => {
        initializeCheckout();
    }, []);
    
    
    
    
    const refreshPaymentMethods = async () => {
        setIsBusy(true);
        initializeCheckout();
    };

    const purchaseNow  = async () => {
        setIsBusy(true);
        const result = await productService.addProductsToCustomer(antiForgeryToken, resourceType, resourceData.id, cart, selectedPaymentMethodId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully added product", {variant:'success'});
                postPurchaseAction();
            } else {
                enqueueSnackbar(data.description, {variant:'error'});
            }
        }
        setIsBusy(false);
    };
    
    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress/>
            </Box>
        );
    } else {

        let billingContent;
        
        
        if(selectedScreenMode === CHECKOUT_SCREEN_MODE.PURCHASE_NOW) {
            billingContent = (
                <Box>
                    <Typography variant="basketSubHeader" sx={{mt: 2, mb: 2}} noWrap component="div" align={"left"}>
                        Payment Details
                    </Typography>
                    <Divider sx={{borderColor: "#b8c4c4", borderWidth:"1px"}} />
                    <Stack direction="row" spacing={2} sx={{mt: 4, mb: 4}}>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            setShowCheckout(false);
                        }}
                        >
                            Back
                        </Button>
                        <Button variant="contained" color="secondary" onClick={purchaseNow}>
                            Purchase now
                        </Button>
                    </Stack>
                </Box>
            );
        }
        else if(selectedScreenMode === CHECKOUT_SCREEN_MODE.LIST_PAYMENT_METHODS) {
            let cardsContent = paymentMethods.map((paymentMethod) => {
                return (
                    <Card
                        sx={{ backgroundColor: selectedPaymentMethodId === paymentMethod.id ? 'grey.200' : 'white', mb:2 }}>
                        <CardActionArea  sx={{p:2}}
                                         onClick={() => handlePaymentMethodClick(paymentMethod.id)}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">
                                        <strong>{paymentMethod.brand} ending in **** {paymentMethod.last4}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">
                                        <strong>Expiry {paymentMethod.expiryDate}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2">
                                        Billing address: <strong>{paymentMethod.address}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" sx={{textAlign:"right"}}>
                                        {paymentMethod.default ? <strong>Default</strong> : null}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    </Card>
                )
            });

            billingContent =  (
                <Box>
                    <Typography variant="basketSubHeader" sx={{mt: 2, mb: 2}} noWrap component="div" align={"left"}>
                        Payment Details
                    </Typography>
                    <Divider sx={{borderColor: "#b8c4c4", borderWidth:"1px"}} />
                    <Paper sx={{maxHeight: 400, overflow: 'auto',boxShadow:"none"}} sx={{mt: 4}}>
                        {cardsContent}
                    </Paper>
                    <Stack direction="row" spacing={2} marginTop={6}>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            setShowCheckout(false);
                        }}
                        >
                            Back
                        </Button>
                        <Button variant="contained" color="secondary" onClick={purchaseNow} disabled={selectedPaymentMethodId === ""}>
                            Purchase now
                        </Button>
                    </Stack>
                    <Typography variant="subtitle2" sx={{mt: 4, maxWidth: 400}}>
                        By clicking &quot;Purchase now&quot; you agree to our terms and conditions and privacy policy
                    </Typography>
                </Box>
            );
        }
        else if(selectedScreenMode === CHECKOUT_SCREEN_MODE.ADD_PAYMENT_METHOD) {
            billingContent = (
                <Box sx={{mt: 2, mb: 4}}>
                    <AddAccountBillingParent setShowCheckout={setShowCheckout} accountId={resourceData.accountId} redirectUrl={"/Product"} refreshPaymentMethods={refreshPaymentMethods} redirectUrl={redirectUrl}/>
                </Box>
            );
        }

        content = (
            <Box sx={{minHeight:500}}>
                <Grid container spacing={2} marginTop={2}>
                    <Grid item xs={12} sm={8} md={6}>
                        {billingContent}
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <Typography variant="basketSubHeader" sx={{mt: 2, mb: 2}} noWrap component="div" align={"left"}>
                            Basket
                        </Typography>
                        <Divider sx={{borderColor: "#b8c4c4", borderWidth:"1px"}} />
                        {cartItems}
                        <Divider sx={{borderColor: "#b8c4c4", borderWidth:"1px"}} />
                        <div  style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ textAlign: 'left' }} sx={{mt: 2}}  variant="basketItem">
                                Subtotal
                            </Typography>
                            <Typography style={{ textAlign: 'right' }} sx={{mt: 2}} variant="basketItem">
                                {UKPound.format(subTotal)}
                            </Typography>
                        </div>
                        <div  style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ textAlign: 'left' }} sx={{mb: 2}}  variant="basketItem">
                                VAT
                            </Typography>
                            <Typography style={{ textAlign: 'right' }} sx={{mb: 2}} variant="basketItem">
                                {UKPound.format(vatTotal)}
                            </Typography>
                        </div>
                        <div  style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ textAlign: 'left' }} variant="basketSubHeader">
                                Total
                            </Typography>
                            <Typography style={{ textAlign: 'right' }} variant="basketSubHeader">
                                {UKPound.format(total)}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}